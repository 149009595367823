import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  padding: 5px;
  border-radius: 2px;
  min-width: 60px;
  background-color: ${({ theme: { colors } }) => colors.ticketSequenceBackground};
  color: ${({ theme: { colors } }) => colors.ticketSequenceText};
  font-weight: ${({ theme: { font } }) => font.weight.normal};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
`;
