import styled from 'styled-components';
import { Collapse as CollapseAntd } from 'antd';

import { ChevronDown } from '@/components/Icon';

export const ChevronIcon = styled<{ rotatedeg: number } | any>(ChevronDown)`
  height: 8px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
  transition: all 0.3s ease-in-out;

  && {
    transform: translateY(-50%) rotate(${({ rotatedeg }) => rotatedeg}deg) !important;
  }
`;

export const CollapseContainer = styled<{ collapseBorder: string } | any>(CollapseAntd)`
  border: none;

  .ant-collapse-item > .ant-collapse-header {
    padding: 0 0 10px;
  }
`;

export const Panel = styled<{ headerColor: any; $bottomPanelBorder: any } | any>(
  CollapseAntd.Panel,
)`
  .ant-collapse-header {
    display: flex;
    align-items: center;
    color: ${({ theme: { colors } }) => colors.darkBlue};
    font-size: ${({ theme: { font } }) => font.size.mediumPlus};
    background-color: ${({ headerColor }) => headerColor};
    border-bottom: ${({ $bottomPanelBorder }) =>
      $bottomPanelBorder ? `1px solid ${$bottomPanelBorder}` : 'none'};

    .ant-collapse-extra {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%);
    }

    .ant-collapse-expand-icon {
      top: 50% !important;
      padding: 0 !important;
      position: absolute;
      right: 15px;
      height: auto !important;
    }

    .ant-collapse-header-text {
      flex: inherit !important;
    }
  }

  && .ant-collapse-content {
    background-color: ${({ theme: { colors } }) => colors.white};

    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;
