import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

import { ArrowWithShaft, Chevrons, Pencil } from '@/components/Icon';

export const ChartWrapper = styled.div`
  height: 200px;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  user-select: none;
  background-color: ${({ theme: { colors } }) => colors.backgroundGrey};

  & > div {
    position: absolute;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 10px;
  top: -42px;
  width: 100%;
  justify-content: end;

  button svg {
    width: 14px;
    fill: white;
  }

  button:disabled svg {
    fill: ${({ theme: { colors } }) => colors.darkGrey};
  }
`;

export const QuartersWrapper = styled.div<{ zoom: number }>`
  display: flex;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;

  .quarter {
    min-width: ${props => props.zoom * 75 + 120}px;
  }

  .quarterName {
    font-size: ${props => props.zoom * 5 + 10}px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e3e3e3;
  }

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
    background-color: #dadada;
  }
`;

export const Quarter = styled.div.attrs({ className: 'quarter' })`
  background-color: ${({ theme: { colors } }) => colors.backgroundGrey};
  border-left: 1px solid ${({ theme: { colors } }) => colors.quarterBorder};
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  position: relative;
  padding-bottom: 3px;

  &:last-of-type {
    border-right: 1px solid ${({ theme: { colors } }) => colors.darkBlue};
  }

  &:hover {
    .edit-quarter-button {
      display: flex;
    }
  }

  &.FUTURE .quarterName,
  &.TERMINATION_COMPLETION .quarterName {
    color: #edcb55;
  }
`;

export const QuarterBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(52, 174, 239, 0.66);
  }
`;

export const QuarterNumber = styled.div.attrs({ className: 'quarterName' })`
  pointer-events: none;
  text-align: center;
  background: ${({ theme: { colors } }) => colors.mainHeadingBackground};
  color: #fff;
  padding: 5px;
`;

export const QuarterDates = styled.div`
  /*position: absolute;*/
  color: ${({ theme: { colors } }) => colors.quarterColor};
  pointer-events: none;
  display: inline-block;
  padding: 4px 6px 0 6px;
`;

export const QuarterCharacters = styled.div`
  /*position: absolute;*/
  //color: #2e2f4d;
  color: ${({ theme: { colors } }) => colors.quarterColor};
  display: inline-block;
  padding: 4px 6px 0 6px;
  text-align: right;
  pointer-events: none;
`;

export const QuarterDivider = styled.div`
  display: block;
  border-top: 1px solid ${({ theme: { colors } }) => colors.quarterBorder};
  margin-top: 3px;
  content: '';
`;

export const QuarterWrapper = styled.div`
  font-size: 11px;
  line-height: 1.2;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
`;

export const QuarterSummary = styled.div`
  padding: 4px 6px 0 6px;
  font-size: 11px;
  line-height: 1.2;
  color: ${({ theme: { colors } }) => colors.quarterColor};
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  & p {
    margin-bottom: 0;
  }
`;

export const QuarterChangedText = styled.span`
  display: block;
  position: absolute;
  top: 11px;
  left: 5px;
  font-size: 10px;
  color: #edcb55;
`;

export const FutureQuarterText = styled.span`
  display: block;
  position: absolute;
  top: 11px;
  right: 5px;
  font-size: 10px;
  color: #edcb55;
`;

export const EditQuarterButton = styled(ButtonAntd).attrs({ className: 'edit-quarter-button' })<{
  isOverridden: boolean;
}>`
  display: none;
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  padding: 0;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const PencilIcon = styled(Pencil)`
  width: 16px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;

export const SliderHandle = styled.div`
  background: ${({ theme: { colors } }) => colors.lightGrey};
  border-radius: 2px;
  border: 1px solid ${({ theme: { colors } }) => colors.grey8};
  width: 12px;
  height: 24px;
  display: flex;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  transition: background-color 0.2s ease-in-out;
  z-index: 3;
  &:hover,
  &.active {
    background-color: ${({ theme: { colors } }) => colors.grey3};
  }

  svg {
    rotate: 90deg;
    scale: 1.25 1;
    fill: ${({ theme: { colors } }) => colors.grey8};
  }
`;

export const SliderCanvas = styled.div`
  background-color: rgba(52, 174, 239, 0.33);
  height: 100%;
  cursor: pointer;
  position: absolute;
  z-index: 2;
`;

export const ArrowIcon = styled(ArrowWithShaft)`
  ${props => props.rotate180 && `rotate: 180deg;`}
`;

export const ChevronsIcon = styled(Chevrons)`
  ${props => props.rotate180 && `rotate: 180deg;`}
`;
