import { ButtonProps } from 'antd';
import { CSSProperties, FC } from 'react';

import { ButtonPill } from './styled';

interface IPill extends ButtonProps {
  style?: CSSProperties;
}

export const Pill: FC<IPill> = props => {
  const { children, ...rest } = props;

  return (
    <ButtonPill type="default" className={'ant-btn-secondary'} {...rest}>
      {children}
    </ButtonPill>
  );
};
