import { ButtonCustomTypes } from '@/consts';

const { BACK, SAVE, REMOVE, DISABLED, SECONDARY } = ButtonCustomTypes;

export const buttonColors = (type: ButtonCustomTypes, theme: any) => {
  const {
    white,
    lightBlue,
    green,
    notifRed,
    textGrey,
    borderGrey,
    secondaryButtonBg,
    secondaryButtonText,
  } = theme.colors;

  switch (type) {
    case BACK:
      return {
        bg: white,
        border: lightBlue,
        text: lightBlue,
      };
    case SAVE:
      return {
        bg: green,
        border: green,
        text: white,
      };
    case REMOVE:
      return {
        bg: notifRed,
        border: notifRed,
        text: white,
      };
    case DISABLED:
      return {
        bg: borderGrey,
        border: textGrey,
        text: textGrey,
      };
    case SECONDARY:
      return {
        bg: secondaryButtonBg,
        border: secondaryButtonText,
        text: secondaryButtonText,
      };
    default:
      return {
        bg: lightBlue,
        border: lightBlue,
        text: white,
      };
  }
};
