import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';

import { Home } from '../Icon';

export const BreadcrumbLink = styled(NavLink)`
  display: flex;
  align-items: center;
`;

export const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.darkGrey};
  padding-left: 1vw;
`;

export const BreadcrumbStyled = styled(Breadcrumb)`
  ol {
    margin-bottom: 0;
  }

  li {
    display: flex;
  }

  .ant-breadcrumb-link {
    display: flex;
    align-items: center;

    .active {
      color: ${({ theme: { colors } }) => colors.mainText};
      cursor: default;
    }

    a {
      color: ${({ theme: { colors } }) => colors.mainText};
    }
  }
  .ant-breadcrumb-separator {
    color: ${({ theme: { colors } }) => colors.mainText};
  }
`;

export const HomeIcon = styled(Home)`
  height: ${({ theme: { font } }) => font.size.semiMedium};
  margin-right: 2px;
  fill: ${({ theme: { colors } }) => colors.textGrey};
`;
