import { useContext } from 'react';
import { ThemeProvider as Provider } from 'styled-components';

import { ThemeContext } from '@/context/ThemeContext';
import theme from '@/theme/theme';
import { darkTheme } from '@/theme';

interface IThemeProvider {
  children: React.ReactNode;
}

export const ThemeProvider = ({ children }: IThemeProvider) => {
  const { currentTheme, toggleTheme } = useContext(ThemeContext);
  return <Provider theme={currentTheme == 'dark' ? darkTheme : theme}>{children}</Provider>;
};
