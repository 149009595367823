import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5vw;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
`;

export const Header = styled.div`
  margin-bottom: 2vw;
  color: ${({ theme: { colors } }) => colors.mainText};
  font-size: ${({ theme: { font } }) => font.size.mediumBig};
`;

export const ButtonWrapper = styled.div`
  padding: max(1.5vw, 15px) 2vw;
`;
