import styled from 'styled-components';

export const Bar = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px max(15px, 1.5vw);
  border-radius: ${({ theme: { border } }) => border.radius.big};
  background-color: ${({ theme: { colors } }) => colors.secondHeadingBackground};
    color: ${({ theme: { colors } }) => colors.secondHeadingText};
  margin-bottom: min(2vw, 20px);

  h2 {
    margin: 0;
    letter-spacing: 0.33px;
    font-size: ${({ theme: { font } }) => font.size.semiBig};
    font-weight: bold;
  }
`;
