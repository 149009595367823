import { TableProps } from 'antd';
import { useTheme } from 'styled-components';

import { Loader } from '../../Loader';

import { TableComponent } from './styled';

interface ITable<T> extends TableProps<T> {
  scrollWidth?: number;
  disableScroll?: boolean;
}

export const Table = <T,>(props: ITable<T>) => {
  const { loading = false, disableScroll = false, scrollWidth, bordered = false, ...rest } = props;
  const theme = useTheme();
  const { azure, white, tableEvenRowColor } = theme.colors;
  const evenRowColor = bordered ? white : tableEvenRowColor;

  return (
    <TableComponent
      pagination={false}
      loading={loading ? { indicator: <Loader /> } : false}
      $evenrowcolor={evenRowColor}
      bordered={bordered}
      {...(disableScroll ? {} : { scroll: { x: scrollWidth ?? 1000 } })}
      {...rest}
    />
  );
};
