import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled<
  | {
      $darkMode: boolean;
    }
  | any
>(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0.7px solid ${({ theme: { colors } }) => colors.selectBackground};
    //background: ${({ theme: { colors } }) => colors.selectBackground};

    //color: ${({ theme: { colors }, $darkMode }) => ($darkMode ? colors.white : colors.shadyBlue)};
  }

  .ant-select-selection-search > .ant-select-selection-search-input {
    color: ${({ theme: { colors }, $darkMode }) => ($darkMode ? colors.white : colors.shadyBlue)};
  }

  .ant-select-item-option-content {
    color: ${({ theme: { colors } }) => colors.textGrey};
  }

  .ant-select-selection-placeholder {
    color: ${({ theme: { colors } }) => colors.textGrey};
  }

  .ant-select-clear > .anticon.anticon-close-circle {
    background-color: ${({ theme: { colors }, $darkMode }) =>
      $darkMode ? colors.shadyBlue : colors.white};
    color: ${({ theme: { colors } }) => colors.textGrey};
  }
`;
