import { RadioChangeEvent } from 'antd';
import { useTheme } from 'styled-components';

import { TodoStatusEnum } from '@/__generated__';
import { Cross } from '@/components/Icon';

import { CheckedIcon, Line, Switch, SwitchButton } from './styled';

interface ITaskStatusSwitch {
  onChange?: (e: RadioChangeEvent) => void;
  statusValue: TodoStatusEnum;
  disabled: boolean;
}

export const TaskStatusSwitch = (props: ITaskStatusSwitch) => {
  const { onChange, statusValue, disabled } = props;
  const { INIT, NOT_DONE, DONE } = TodoStatusEnum;
  const theme = useTheme();
  const { green, notifRed, cardBackground, initGreyButtonBg, initGreyButtonText } = theme.colors;

  const notDoneBgColor = statusValue === NOT_DONE ? notifRed : cardBackground;
  const notDoneIconColor = statusValue === NOT_DONE ? cardBackground : notifRed;

  const doneBgColor = statusValue === DONE ? green : cardBackground;
  const doneIconColor = statusValue === DONE ? cardBackground : green;

  const initBgColor = initGreyButtonBg;
  const initIconColor = initGreyButtonText;

  return (
    <Switch onChange={onChange} value={statusValue} buttonStyle="solid" disabled={disabled}>
      <SwitchButton
        style={{ backgroundColor: notDoneBgColor, borderColor: notDoneBgColor }}
        value={NOT_DONE}
        data-testid={`criterion-not-done`}
      >
        <Cross style={{ fill: notDoneIconColor }} />
      </SwitchButton>
      <SwitchButton
        style={{ backgroundColor: initBgColor, borderColor: initBgColor }}
        value={INIT}
        data-testid={`criterion-init`}
      >
        <Line color={initIconColor} />
      </SwitchButton>
      <SwitchButton
        style={{ backgroundColor: doneBgColor, borderColor: doneBgColor }}
        value={DONE}
        data-testid={`criterion-done`}
      >
        <CheckedIcon color={doneIconColor} />
      </SwitchButton>
    </Switch>
  );
};
