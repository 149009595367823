import styled from 'styled-components';
import { Input as InputAntd } from 'antd';

import { SearchGlass } from '../Icon';

export const Input = styled(InputAntd)`
  display: flex;
  height: 35px;
  color: ${({ theme: { colors } }) => colors.mainText};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  border-radius: 3px;
  border-color: ${({ theme: { colors } }) => colors.selectBorder};

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.mainText};
    filter: contrast(0.1);
  }
`;

export const SearchGlassIcon = styled(SearchGlass)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;
