import styled, { css } from 'styled-components';
import { Input as InputAntd, InputNumber as InputNumberAntd } from 'antd';

import { Cross, Delete } from '../Icon';

const standard = css`
  border: 0.7px solid ${({ theme: { colors } }) => colors.selectBorder};
  border-radius: ${({ theme: { border } }) => border.radius.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
  background-color: ${({ theme: { colors } }) => colors.selectLightBackground};

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.mainText};
    filter: contrast(0.1);
  }
`;

export const TextAreaInput = styled(InputAntd.TextArea)`
  ${standard}
`;

const inputStyles = css`
  ${standard}

  height: 35px;

  &.ant-input[disabled] {
    color: ${({ theme: { colors } }) => colors.darkBlue};

    :hover {
      border: 1px solid ${({ theme: { colors } }) => colors.grey5};
    }
  }
`;

export const TextInput = styled<{ $fontSize: number } | any>(InputAntd)`
  ${inputStyles}

  font-size: ${({ $fontSize }) => $fontSize}px;
`;

export const InputNumber = styled(InputNumberAntd)`
  ${inputStyles}

  width: 100%;

  &.ant-input-number-disabled {
    background-color: ${({ theme: { colors } }) => colors.grey};
    border-color: ${({ theme: { colors } }) => colors.borderGrey};
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const PasswordInput = styled(InputAntd.Password)`
  ${inputStyles}
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ThinFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Label = styled.label`
  padding-bottom: 10px;
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const ErrorWrapper = styled.div`
  color: ${({ theme: { colors } }) => colors.notifRed};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
`;

export const InlineTextInput = styled<{ fontSize?: string; height?: string } | any>(InputAntd)`
  display: flex;
  width: 240px;
  justify-content: center;
  height: ${({ height }) => height ?? '25px'};
  border: none;
  font-size: ${({ fontSize, theme: { font } }) => fontSize ?? font.size.semiMedium};
  border-radius: ${({ theme: { border } }) => border.radius.big};
  background-color: rgba(255, 255, 255, 0.7);

  :hover,
  :active,
  :focus {
    border: none;
  }
`;

export const TransparentTextInput = styled<{ $fontSize: number; $height: string } | any>(InputAntd)`
  height: ${({ $height }) => $height ?? '35px'};
  max-width: 300px;
  border: none;
  background-color: transparent;
  border: 1px solid ${({ theme: { colors } }) => colors.grey2};
  color: ${({ theme: { colors } }) => colors.mainText};
  border-radius: ${({ theme: { border } }) => border.radius.big};
  font-size: ${({ $fontSize }) => $fontSize}px;
`;

export const Color = styled.div<{ color: string; disabled: boolean }>`
  height: 15px;
  width: 15px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${({ color, disabled, theme: { colors } }) =>
    disabled ? colors.disabledGrey : color};
`;

export const DeleteIcon = styled(Cross)`
  fill: ${({ theme: { colors } }) => colors.notifRed};
  height: 16px;
`;

export const SelectWrapper = styled.div`
  height: 60px;
`;
