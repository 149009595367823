import styled, { keyframes } from 'styled-components';

import { Loader, Loader1, Loader2 } from '@/components/Icon';

const logoAnimation1 = keyframes`
  0% {
    opacity: 0;
    transform: rotate(-90deg) scale(.4) translateY(-75%);
  }
  50% {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
  100% {
    opacity: 0;
    transform: rotate(90deg) scale(.4) translateX(75%);
}`;

const logoAnimation2 = keyframes`
  0% {
    opacity: 1;
    transform: rotate(0);
  }
  50% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    opacity: 1;
    transform: rotate(180deg);
  }
`;

export const Container = styled.div<{ wrapperHeight: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ wrapperHeight }) => wrapperHeight};
  background: ${({ theme: { colors } }) => colors.layoutBackground};
`;

export const Wrapper = styled.div<{ height: number }>`
  position: relative;
  width: ${({ height }) => height}px;
  height: ${({ height }) => height}px;
`;

export const Figure = styled.figure`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: inherit;
  height: inherit;
  margin: 0;

  /*:first-child {
    animation: ${logoAnimation1} 1.5s infinite;
  }

  :last-child {
    animation: ${logoAnimation2} 1.5s infinite;
  }*/
`;

export const LoaderIcon1 = styled(Loader1)`
  width: inherit;
  height: inherit;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const LoaderIcon = styled(Loader)`
  width: inherit;
  height: inherit;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const LoaderIcon2 = styled(Loader2)`
  width: inherit;
  height: inherit;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;
