import { DefaultTheme } from 'styled-components';

import { sizes } from './consts';

const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    black: '#000',
    grey: '#f5f5f5',
    grey2: '#d2d2d2',
    grey1: '#e2e3e4',
    grey3: '#e5e5e5',
    grey4: '#e0e0e0',
    grey5: '#e2e2e4',
    grey6: '#e8e8e8',
    grey7: '#bfbfbf',
    grey8: '#656b80',
    grey9: '#f2f1f1',
    lightGrey: '#fafafa',
    lightGrey2: '#f0f0f0',
    lightGrey4: '#fafafa',
    lightGrey5: '#b7b7b7',
    lightGreyTableRowBody: '#ddd',
    darkGrey: '#808494',
    darkGrey2: '#455a64',
    darkGrey3: '#333',
    darkGrey4: '#3a3b3f',
    blue: '#03a9f4',
    blue1: '#3c87a8',
    blue2: '#2196f3',
    blue3: '#3c87a8',
    blue4: '#000623',
    red: '#f44336',
    redDangerDark: '#f55246',
    redDangerLight: '#fde1df',
    redDangerText: '#7f231c',
    greenSuccessDark: '#87d620',
    greenSuccessLight: '#e9f6d8',
    greenSuccessText: '#589b00',
    yellowWarningDark: '#f6c500',
    yellowWarningLight: '#fff9e2',
    yellowWarningText: '#b18e00',
    blueInfoDark: '#25c3f3',
    blueInfoLight: '#f2fcff',
    blueInfoText: '#017ca1',
    teal: '#3c87a8',
    darkBlue: '#000623',
    lightBlue: '#5768FF',
    warningRed: '#EE2058',
    textGrey: '#808495',
    notifRed: '#D90000',
    lightRed: '#f0202047',
    lightRed2: '#f0202014',
    borderGrey: '#F1F1F3',
    navyBlue: '#000510',
    backgroundGrey: '#F6F6F6',
    readyGreen: '#4AD991',
    disabledGrey: '#D4D4D4',
    deadBlue: '#40415E',
    green: '#21B307',
    lightGreen: '#2aff3052',
    lightGreen2: '#2aff301c',
    greenHover: '#28B262',
    purple: '#C67FDB',
    yellow: '#FEBE3E',
    yellow2: '#f5d405',
    azure: '#F5FDFF',
    greyHover: '#E8E8EA',
    shadyBlue: '#444655',
    lightBlueHover: '#2D98D1',
    whiteHover: '#F4F4F4',
    redHover: '#DF5858',
    iconGrey: '#83919a',
    iconYellow: '#eed220',
    paleRed: '#fff7f5',
    paleBlue: '#f7f5ff',
    darkOrange: '#e67e22',
    scaleGreen: '#7bc49f',
    scaleLightGreen: '#8cbe21',
    scaleGreenYellow: '#e2de09',
    scaleYellow: '#f9d716',
    scaleOrange: '#f8ad13',
    scaleDarkOrange: '#f07b07',
    scaleRed: '#e84c19',
    midnightBlue: '#1a237e',
    fadedBlue: '#3498db',
    rejectionRed: '#ff0000',
    madisonBlue: '#2c3e50',
    pink: '#ffaed7',
    menuBackground: '#000623',
    mainContrastColor: '#5768FF',
    mainHeadingBackground: '#090F43',
    mainHeadingText: '#FFFFFF',
    secondHeadingBackground: '#f0f0f0',
    secondHeadingText: '#000623',
    menuActiveBackground: '#090F43',
    submenuHoverBackground: '#232D82',
    menuHoverBackground: '#000A39',
    subMenuBackground: '#1A236D',
    subMenuActiveBackground: '#232E90',
    headingLightBackground: '#6B6F8E',
    ticketSequenceBackground: '#EDEDF0',
    ticketSequenceText: '#000623',
    selectBackground: '#282E5C',
    darkSelectTextColor: '#ffffff',
    selectBorder: '#e2e2e4',
    selectSelectedBackground: '#EEF0FF',
    selectHoverBackground: '#F6F6F6',
    layoutBackground: '#F6F6F6',
    topBarBackground: '#FFFFFF',
    mainText: '#000623',
    pwlHeading: '#666A7B',
    pwlBackground: '#E6E6E9',
    textEditorBackground: 'transparent',
    cardBackground: '#FFFFFF',
    mainShadow: 'rgba(215, 215, 215, 0.5)',
    tableEvenRowColor: 'rgba(238,240,255,0.3)',
    selectLightBackground: '#ffffff',
    terminationBackground: '#ffdede',
    tooltipBackground: '#f9f9f9',
    descriptionBorderColor: '#d4d4d4',
    descriptionBgColor: '#f9f9f9',
    quarterColor: '#676b7e',
    quarterBorder: '#676b7e',
    antTagBackground: '#F9F9F9',
    disabledSelectBackground: '#f5f5f5',
    activeTagBackground: '#090F43',
    inactiveTagBackground: '#656b80',
    tableTopBarBackground: '#F9F9F9',
    scrollbarColor: '#929292',
    initGreyButtonBg: '#E9E9E9',
    initGreyButtonText: '#808496',
    disabledButtonBg: '#F6F6F6',
    disabledButtonText: '#D0CFCE',
    secondaryButtonBg: '#EEF0FF',
    secondaryButtonText: '#3B3F55',
    loginBackground: '#ffffff',
    linkColor: '#0063F7',
    linkHoverColor: '#003D99',
  },
  shadows: {
    lightLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.15)',
    lightRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.15)',
    darkLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.7)',
    darkRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.7)',
  },
  border: {
    radius: {
      small: '2px',
      semiMedium: '3px',
      medium: '4px',
      big: '5px',
      large: '10px',
    },
  },
  font: {
    size: {
      xsmall: '10px',
      small: '11px',
      semiSmall: '12px',
      regular: '13px',
      semiMedium: '14px',
      medium: '15px',
      mediumPlus: '16px',
      mediumBig: '17px',
      semiBig: '18px',
      titleBig: '20px',
      numberBig: '23px',
      big: '25px',
      statusBig: '26px',
      semiLarge: '32px',
      large: '38px',
      xlarge: '56px',
    },
    weight: {
      light: 300,
      normal: 'normal',
      semiBold: 500,
      bold: 600,
      black: 800,
    },
  },
  devices: {
    mobileS: `(max-width: ${sizes.xs})`,
    mobileM: `(max-width: ${sizes.sm})`,
    mobileL: `(max-width: ${sizes.md})`,
    tablet: `(max-width: ${sizes.lg})`,
    laptopS: `(max-width: ${sizes.xl})`,
    laptopM: `(max-width: 1400px)`,
    laptopL: `(max-width: ${sizes.xxl})`,
  },
};

export default theme;
