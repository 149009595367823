import styled from 'styled-components';
import { Card as CardAntd } from 'antd';

export const Card = styled<{ height: string; cardpadding: string } | any>(CardAntd)`
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height ?? 'auto'};
  padding: 1.5vw;
  border: none;
  background: ${({ theme: { colors } }) => colors.cardBackground};
  color: ${({ theme: { colors } }) => colors.mainText};

  .ant-collapse {
    background-color: unset !important;

    .ant-collapse-header {
      border-bottom: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
    }

    .ant-collapse-header-text {
      font-size: ${({ theme: { font } }) => font.size.medium};
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-content {
      color: ${({ theme: { colors } }) => colors.mainText};
    }

    .ant-collapse-item > .ant-collapse-header {
      color: ${({ theme: { colors } }) => colors.mainText};
    }

    .ant-collapse-expand-icon {
      align-items: end !important;
      margin-right: 4px;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: ${({ height }) => height ?? 'auto'};
    padding: ${({ cardpadding }) => cardpadding ?? '.75vw'};
  }
`;
