import { Alert, Checkbox, Modal } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonCustomTypes } from '@/consts';
import { useCopywritingContext } from '@/containers/TextOrderList/hooks';
import { useNotify, useImportTextOrderFromGoogleSheets } from '@/hooks';
import { DomainsSearch } from '@/components';

import { Button } from '../Buttons';
import { TextInputField } from '../Form';

import { FooterButtons } from './styled';

export const ImportGoogleSheetModal = ({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) => {
  const { grid, domainId } = useCopywritingContext();
  const [importTextOrderFromGoogleSheets] = useImportTextOrderFromGoogleSheets();
  const { notifySuccess } = useNotify();
  const [domain, setDomain] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [sheetId, setSheetId] = useState('');
  const [range, setRange] = useState('');
  const [errors, setErrors] = useState<any>(null);
  const [newCount, setNewCount] = useState(0);
  const intl = useIntl();

  const handleChangeSheetId = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setSheetId(evt.target.value);

  const handleChangeRange = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setRange(evt.target.value);
  const handleSubmit = async () => {
    if (!sheetId || (!domain && !domainId) || !range) return;
    setDisableSubmit(true);
    importTextOrderFromGoogleSheets({
      variables: {
        sheetId: sheetId,
        range: range,
        domainId: domain ?? domainId,
      },
      textOrdersVariables: { grid },
    }).then(data => {
      if (data.data?.importTextOrderFromGoogleSheets?.textOrders) {
        setNewCount(data.data.importTextOrderFromGoogleSheets.textOrders.length);
      }
      if (
        data.data?.importTextOrderFromGoogleSheets?.errors &&
        data.data?.importTextOrderFromGoogleSheets?.errors.length > 0
      ) {
        setErrors(data.data.importTextOrderFromGoogleSheets.errors);
      } else {
        notifySuccess(intl.formatMessage({ defaultMessage: 'Dodano zlecenia' }));
        closeModal();
      }
    });
  };

  const footer = (
    <FooterButtons>
      <Button key="cancel" onClick={closeModal} colorType={ButtonCustomTypes.BACK}>
        {intl.formatMessage({ defaultMessage: 'Anuluj' })}
      </Button>
      <Button
        key="submit"
        type="primary"
        onClick={handleSubmit}
        colorType={ButtonCustomTypes.NEXT}
        disabled={!sheetId || !(domainId || domain) || disableSubmit}
      >
        {intl.formatMessage({ defaultMessage: 'Dodaj' })}
      </Button>
    </FooterButtons>
  );

  const body = (
    <>
      <Alert
        message={intl.formatMessage(
          {
            defaultMessage: 'Szablon: {link}',
          },
          {
            link: (
              <a href="https://docs.google.com/spreadsheets/d/1QgjrkuWqSPKbQG-QIJEVmqFfbW32wNFKZdyaryxfKRQ/edit#gid=0">
                https://docs.google.com/spreadsheets/d/1QgjrkuWqSPKbQG-QIJEVmqFfbW32wNFKZdyaryxfKRQ/edit#gid=0
              </a>
            ),
          },
        )}
        type="warning"
        style={{ marginBottom: '1rem' }}
        showIcon
      />
      <TextInputField
        style={{ height: 30 }}
        type="string"
        name="sheetId"
        value={sheetId}
        onChange={handleChangeSheetId}
        placeholder={intl.formatMessage({
          defaultMessage: 'ID Arkusza',
        })}
      />
      <TextInputField
        style={{ height: 30 }}
        type="string"
        name="range"
        value={range}
        onChange={handleChangeRange}
        placeholder={intl.formatMessage({
          defaultMessage: 'Zakres wierszy (w formacie X:Y, min X = 4)',
        })}
      />
      {!domainId && (
        <DomainsSearch
          value={domain}
          onChange={setDomain}
          style={{ marginTop: '1rem' }}
          placeholder={intl.formatMessage({ defaultMessage: 'Domena' })}
        />
      )}
    </>
  );
  const bodyWithErrors = (
    <>
      <Alert
        message={
          newCount > 0
            ? intl.formatMessage({
                defaultMessage: 'Zaimportowano z błędami',
              })
            : intl.formatMessage({
                defaultMessage: 'Błąd',
              })
        }
        description={errors}
        type="error"
        style={{ marginBottom: '1rem', whiteSpace: 'pre-line' }}
        showIcon
      />
    </>
  );

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Import z arkusza Google' })}
      open={open}
      onCancel={closeModal}
      footer={errors ? null : footer}
      okButtonProps={{ type: 'secondary' }}
    >
      {errors ? bodyWithErrors : body}
    </Modal>
  );
};
