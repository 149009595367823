import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Event = styled.div`
  display: flex;
  padding-right: 10px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 130px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};

  :last-child {
    border-bottom: none;
  }
`;

export const TextLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.mainText};
`;
