import styled from 'styled-components';

export const EditorScroller = styled.div<{ isEditing: boolean; isDisabled: boolean }>`
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: ${({ isEditing }) => (isEditing ? 'vertical' : 'none')};
  border: 0.7px solid transparent;
  border-radius: 5px;

  &:hover {
    ${({ isDisabled, theme }) => !isDisabled && `border-color: ${theme.colors.selectBorder};`}
  }

  ${({ isEditing, theme }) =>
    isEditing
      ? `border-color: ${theme.colors.selectBorder}; min-height: 200px; max-height: 600px;`
      : 'height: unset !important;'}
`;

export const EditorContainer = styled.div.attrs({
  className: 'editor',
})<{ isEditing: boolean; isDisabled: boolean }>`
  margin: 0px;
  //box-shadow: rgb(250, 250, 250) 0px 1px 8px -3px inset;
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
  ${({ isDisabled, isEditing }) => !isDisabled && !isEditing && 'cursor: pointer;'}

  &:hover {
    ${({ isDisabled, isEditing, theme }) =>
      !isEditing && !isDisabled && ` background-color: ${theme.colors.lightGrey};`}
  }
`;

export const Wrapper = styled.div.attrs({
  className: 'lexical',
})`
  color: ${({ theme }) => theme.colors.mainText};
  .icon,
  .format,
  .toolbar .chevron-down {
    background-color: ${({ theme }) => theme.colors.mainText};
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
  }
  .toolbar .toolbar-item .text {
    color: ${({ theme }) => theme.colors.mainText};
  }
  .toolbar .toolbar-item:hover:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.pwlBackground};
  }
  .editor-container {
    .ltr {
      text-align: left;
    }
    .rtl {
      text-align: right;
    }
    .paragraph {
      margin: 0;
      position: relative;
    }
    .quote {
      margin: 0 0 0 5px;
      color: rgb(101, 103, 107);
      border-left-color: rgb(206, 208, 212);
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 16px;
    }
    .h1 {
      font-size: 24px;
      color: rgb(5, 5, 5);
      font-weight: 400;
      margin: 0;
    }
    .h2 {
      font-size: 15px;
      color: rgb(101, 103, 107);
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
    }
    .h3 {
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
    }
    .indent {
      --lexical-indent-base-value: 40px;
    }
    .textBold {
      font-weight: bold;
    }
    .textItalic {
      font-style: italic;
    }
    .textUnderline {
      text-decoration: underline;
    }
    .textStrikethrough {
      text-decoration: line-through;
    }
    .textUnderlineStrikethrough {
      text-decoration: underline line-through;
    }
    .textSubscript {
      font-size: 0.8em;
      vertical-align: sub !important;
    }
    .textSuperscript {
      font-size: 0.8em;
      vertical-align: super;
    }
    .textCode {
      background-color: ${({ theme }) => theme.colors.tooltipBackground};
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;
    }
    .hashtag {
      background-color: rgba(88, 144, 255, 0.15);
      border-bottom: 1px solid rgba(88, 144, 255, 0.3);
    }
    .link {
      color: rgb(33, 111, 219);
      text-decoration: none;
    }
    .link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    .code {
      background-color: ${({ theme }) => theme.colors.tooltipBackground};
      font-family: Menlo, Consolas, Monaco, monospace;
      display: block;
      padding: 8px 8px 8px 52px;
      line-height: 1.53;
      font-size: 13px;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 8px;
      overflow-x: auto;
      position: relative;
      tab-size: 2;
    }
    .Collapsible__container {
      background-color: ${({ theme }) => theme.colors.tooltipBackground};
    }
    .code:before {
      content: attr(data-gutter);
      position: absolute;
      background-color: #eee;
      left: 0;
      top: 0;
      border-right: 1px solid #ccc;
      padding: 8px;
      color: #777;
      white-space: pre-wrap;
      text-align: right;
      min-width: 25px;
    }
    .table {
      border-collapse: collapse;
      border-spacing: 0;
      overflow-y: scroll;
      overflow-x: scroll;
      table-layout: fixed;
      width: max-content;
      margin: 30px 0;
    }
    .tableSelection *::selection {
      background-color: transparent;
    }
    .tableSelected {
      outline: 2px solid rgb(60, 132, 244);
    }
    .tableCell {
      border: 1px solid #bbb;
      width: 75px;
      min-width: 75px;
      vertical-align: top;
      text-align: start;
      padding: 6px 8px;
      position: relative;
      outline: none;
    }
    .tableCellSortedIndicator {
      display: block;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #999;
    }
    .tableCellResizer {
      position: absolute;
      right: -4px;
      height: 100%;
      width: 8px;
      cursor: ew-resize;
      z-index: 10;
      top: 0;
    }
    .tableCellHeader {
      background-color: ${({ theme }) => theme.colors.tooltipBackground} !important;
      text-align: start;
    }
    .tableCellSelected {
      background-color: #c9dbf0;
    }
    .tableCellPrimarySelected {
      border: 2px solid rgb(60, 132, 244);
      display: block;
      height: calc(100% - 2px);
      position: absolute;
      width: calc(100% - 2px);
      left: -1px;
      top: -1px;
      z-index: 2;
    }
    .tableCellEditing {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      border-radius: 3px;
    }
    .tableAddColumns {
      position: absolute;
      top: 0;
      width: 20px;
      background-color: #eee;
      height: 100%;
      right: 0;
      animation: table-controls 0.2s ease;
      border: 0;
      cursor: pointer;
    }
    .tableAddColumns:after {
      background-image: url(../images/icons/plus.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    .tableAddColumns:hover {
      background-color: #c9dbf0;
    }
    .tableAddRows {
      position: absolute;
      bottom: -25px;
      width: calc(100% - 25px);
      background-color: #eee;
      height: 20px;
      left: 0;
      animation: table-controls 0.2s ease;
      border: 0;
      cursor: pointer;
    }
    .tableAddRows:after {
      background-image: url(../images/icons/plus.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    .tableAddRows:hover {
      background-color: #c9dbf0;
    }
    @keyframes table-controls {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .tableCellResizeRuler {
      display: block;
      position: absolute;
      width: 1px;
      background-color: rgb(60, 132, 244);
      height: 100%;
      top: 0;
    }
    .tableCellActionButtonContainer {
      display: block;
      right: 5px;
      top: 6px;
      position: absolute;
      z-index: 4;
      width: 20px;
      height: 20px;
    }
    .tableCellActionButton {
      background-color: #eee;
      display: block;
      border: 0;
      border-radius: 20px;
      width: 20px;
      height: 20px;
      color: #222;
      cursor: pointer;
    }
    .tableCellActionButton:hover {
      background-color: #ddd;
    }
    .characterLimit {
      display: inline;
      background-color: #ffbbbb !important;
    }
    .ol1 {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }
    .ol2 {
      padding: 0;
      margin: 0;
      list-style-type: upper-alpha;
      list-style-position: inside;
    }
    .ol3 {
      padding: 0;
      margin: 0;
      list-style-type: lower-alpha;
      list-style-position: inside;
    }
    .ol4 {
      padding: 0;
      margin: 0;
      list-style-type: upper-roman;
      list-style-position: inside;
    }
    .ol5 {
      padding: 0;
      margin: 0;
      list-style-type: lower-roman;
      list-style-position: inside;
    }
    .ul {
      padding: 0;
      margin: 0;
      list-style-position: inside;
    }
    .listItem {
      margin: 0 32px;
    }
    .listItemChecked,
    .listItemUnchecked {
      position: relative;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 24px;
      padding-right: 24px;
      list-style-type: none;
      outline: none;
    }
    .listItemChecked {
      text-decoration: line-through;
    }
    .listItemUnchecked:before,
    .listItemChecked:before {
      content: '';
      width: 16px;
      height: 16px;
      top: 2px;
      left: 0;
      cursor: pointer;
      display: block;
      background-size: cover;
      position: absolute;
    }
    .listItemUnchecked[dir='rtl']:before,
    .listItemChecked[dir='rtl']:before {
      left: auto;
      right: 0;
    }
    .listItemUnchecked:focus:before,
    .listItemChecked:focus:before {
      box-shadow: 0 0 0 2px #a6cdfe;
      border-radius: 2px;
    }
    .listItemUnchecked:before {
      border: 1px solid #999;
      border-radius: 2px;
    }
    .listItemChecked:before {
      border: 1px solid rgb(61, 135, 245);
      border-radius: 2px;
      background-color: #3d87f5;
      background-repeat: no-repeat;
    }
    .listItemChecked:after {
      content: '';
      cursor: pointer;
      border-color: #fff;
      border-style: solid;
      position: absolute;
      display: block;
      top: 6px;
      width: 3px;
      left: 7px;
      right: 7px;
      height: 6px;
      transform: rotate(45deg);
      border-width: 0 2px 2px 0;
    }
    .nestedListItem {
      list-style-type: none;
    }
    .nestedListItem:before,
    .nestedListItem:after {
      display: none;
    }
    .tokenComment {
      color: slategray;
    }
    .tokenPunctuation {
      color: #999;
    }
    .tokenProperty {
      color: #905;
    }
    .tokenSelector {
      color: #690;
    }
    .tokenOperator {
      color: #9a6e3a;
    }
    .tokenAttr {
      color: #07a;
    }
    .tokenVariable {
      color: #e90;
    }
    .tokenFunction {
      color: #dd4a68;
    }
    .mark {
      background: rgba(255, 212, 0, 0.14);
      border-bottom: 2px solid rgba(255, 212, 0, 0.3);
      padding-bottom: 2px;
    }
    .markOverlap {
      background: rgba(255, 212, 0, 0.3);
      border-bottom: 2px solid rgba(255, 212, 0, 0.7);
    }
    .mark.selected {
      background: rgba(255, 212, 0, 0.5);
      border-bottom: 2px solid rgba(255, 212, 0, 1);
    }
    .markOverlap.selected {
      background: rgba(255, 212, 0, 0.7);
      border-bottom: 2px solid rgba(255, 212, 0, 0.7);
    }
    .embedBlock {
      user-select: none;
    }
    .embedBlockFocus {
      outline: 2px solid rgb(60, 132, 244);
    }
  }
`;
