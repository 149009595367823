import styled from 'styled-components';
import { Card as CardAntd } from 'antd';

export const Card = styled<{ cardheight: string; bgcolor: any } | any>(CardAntd)`
  display: flex;
  align-items: center;
  height: ${({ cardheight }) => cardheight};
  padding: 10px;
  border: none;
  border-radius: ${({ theme: { border } }) => border.radius.large};
  background-color: ${({ theme: { colors } }) => colors.cardBackground};
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme: { colors }, bgcolor }) => bgcolor ?? colors.tooltipBackground};
  }

  :active {
    background-color: ${({ theme: { colors }, bgcolor }) => bgcolor ?? colors.borderGrey};
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`;

export const ContentWrapper = styled.div`
  padding-left: 10px;

  button {
    margin-top: 8px;
  }
`;

export const TicketTitle = styled.div<{ smallCards: boolean }>`
  font-size: ${({ theme: { font }, smallCards }) =>
    smallCards ? font.size.regular : font.size.medium};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const TicketNumber = styled.div<{ smallCards: boolean }>`
  font-size: ${({ smallCards }) => (smallCards ? '18px' : '23px')};
  font-weight: ${({ theme: { font } }) => font.weight.black};
  color: ${({ theme: { colors } }) => colors.mainText};
`;
