import { Alert, Modal } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonCustomTypes } from '@/consts';
import { useImportPhrasesFromGoogleSheets, useNotify } from '@/hooks';
import { useDomainContext } from '@/scenes/Domains/scenes/DomainView/hooks';
import { useMonitoringContext } from '@/scenes/Domains/scenes/DomainView/scenes/Monitoring/hooks';

import { Button } from '../Buttons';
import { TextInputField } from '../Form';

import { FooterButtons } from './styled';

export const ImportPhrasesGoogleSheetModal = ({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) => {
  const { domain } = useDomainContext();
  const [importPhrasesFromGoogleSheets] = useImportPhrasesFromGoogleSheets();
  const { refetchGroupedPhrases } = useMonitoringContext();
  const { notifySuccess } = useNotify();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [sheetId, setSheetId] = useState('');
  const [errors, setErrors] = useState<any>(null);
  const [newCount, setNewCount] = useState(0);
  const intl = useIntl();

  const handleChangeSheetId = (evt: React.ChangeEvent<HTMLInputElement>) =>
    setSheetId(evt.target.value);
  const handleSubmit = async () => {
    if (!sheetId || !domain) return;
    setDisableSubmit(true);
    importPhrasesFromGoogleSheets({
      variables: {
        sheetId: sheetId,
        domainId: domain.id,
      },
    }).then(data => {
      if (data.data?.importPhrasesFromGoogleSheets?.phrases) {
        setNewCount(data.data.importPhrasesFromGoogleSheets.phrases.length);
      }
      refetchGroupedPhrases();
      if (
        data.data?.importPhrasesFromGoogleSheets?.errors &&
        data.data?.importPhrasesFromGoogleSheets?.errors.length > 0
      ) {
        setErrors(data.data.importPhrasesFromGoogleSheets.errors);
      } else {
        notifySuccess(intl.formatMessage({ defaultMessage: 'Dodano frazy' }));
        closeModal();
      }
    });
  };

  const footer = (
    <FooterButtons>
      <Button key="cancel" onClick={closeModal} colorType={ButtonCustomTypes.BACK}>
        {intl.formatMessage({ defaultMessage: 'Anuluj' })}
      </Button>
      <Button
        key="submit"
        type="primary"
        onClick={handleSubmit}
        colorType={ButtonCustomTypes.NEXT}
        disabled={!sheetId || !domain || disableSubmit}
      >
        {intl.formatMessage({ defaultMessage: 'Dodaj' })}
      </Button>
    </FooterButtons>
  );

  const body = (
    <>
      <Alert
        message={intl.formatMessage(
          {
            defaultMessage: 'Szablon: {link}',
          },
          {
            link: (
              <a
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/1Y7FSAVZHvlQJ0qOSm9cqm_1hFknyCT0OGb_4LrsNNgE/edit"
                rel="noreferrer"
              >
                https://docs.google.com/spreadsheets/d/1Y7FSAVZHvlQJ0qOSm9cqm_1hFknyCT0OGb_4LrsNNgE/edit
              </a>
            ),
          },
        )}
        type="warning"
        style={{ marginBottom: '1rem' }}
        showIcon
      />
      <TextInputField
        style={{ height: 30 }}
        type="text"
        name="sheetId"
        value={sheetId}
        onChange={handleChangeSheetId}
        placeholder={intl.formatMessage({
          defaultMessage: 'ID Arkusza',
        })}
      />
    </>
  );
  const bodyWithErrors = (
    <>
      <Alert
        message={
          newCount > 0
            ? intl.formatMessage({
                defaultMessage: 'Zaimportowano z błędami',
              })
            : intl.formatMessage({
                defaultMessage: 'Błąd',
              })
        }
        description={errors}
        type="error"
        style={{ marginBottom: '1rem', whiteSpace: 'pre-line' }}
        showIcon
      />
    </>
  );

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Import z arkusza Google' })}
      open={open}
      onCancel={closeModal}
      footer={errors ? null : footer}
      okButtonProps={{ type: 'secondary' }}
    >
      {errors ? bodyWithErrors : body}
    </Modal>
  );
};
