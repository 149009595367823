import { ComponentProps, FC, PropsWithChildren } from 'react';

interface ILink extends ComponentProps<'a'> {
  href: string;
}

const https = 'https://';
const http = 'http://';

export const Link: FC<PropsWithChildren<ILink>> = props => {
  const { children, href, ...rest } = props;

  const normalizedHref =
    href?.startsWith(https) || href?.startsWith(http) ? href : `https://${href}`;

  return (
    <a
      target="_blank"
      rel="noreferrer noopener"
      style={{ overflow: 'auto' }}
      href={normalizedHref}
      {...rest}
    >
      {children ?? href}
    </a>
  );
};
