export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
}

export interface AbsentUserId {
  __typename?: 'AbsentUserId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface AbsentUserIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface ActiveField {
  __typename?: 'ActiveField';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface ActiveFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface ActivitiesCreatedAt {
  __typename?: 'ActivitiesCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface ActivitiesCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface ActivitiesDomainIds {
  __typename?: 'ActivitiesDomainIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface ActivitiesUserId {
  __typename?: 'ActivitiesUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface ActivitiesUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Activity {
  __typename?: 'Activity';
  actor_id: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  event_type: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  object_id: Scalars['String']['output'];
  object_type: Scalars['String']['output'];
  payload: Scalars['String']['output'];
  summary: Scalars['String']['output'];
  summary_template?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  user: User;
}

export interface ActivityResponse {
  __typename?: 'ActivityResponse';
  date: Scalars['String']['output'];
  items: Array<Activity>;
  total: Scalars['Int']['output'];
}

export interface ActorId {
  __typename?: 'ActorId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface AdminUserIds {
  __typename?: 'AdminUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface AdminUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface AgreementStartDateRange {
  __typename?: 'AgreementStartDateRange';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface AgreementStartDateRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface AssignedUserId {
  __typename?: 'AssignedUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface AssignedUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export enum BasketEnum {
  EXP = 'EXP',
  INT = 'INT',
  PL = 'PL',
}

export interface Budget {
  __typename?: 'Budget';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface BudgetInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Categories {
  __typename?: 'Categories';
  value: Array<Scalars['String']['output']>;
}

export interface CategoriesInput {
  value: Array<Scalars['String']['input']>;
}

export enum CategoryEnum {
  ADULT_EROTICA_GAMBLING = 'ADULT_EROTICA_GAMBLING',
  ADVERTISING_PRINTING = 'ADVERTISING_PRINTING',
  ANIMALS = 'ANIMALS',
  ART_ENTERTAINMENT = 'ART_ENTERTAINMENT',
  AUTOMOTIVE = 'AUTOMOTIVE',
  BUSINESS = 'BUSINESS',
  CAREER = 'CAREER',
  EDUCATION = 'EDUCATION',
  FAMILY_PARENTING = 'FAMILY_PARENTING',
  FOOD_DRINK = 'FOOD_DRINK',
  HEALTH_FITNESS = 'HEALTH_FITNESS',
  HOBBIES_INTERESTS = 'HOBBIES_INTERESTS',
  HOME_GARDEN = 'HOME_GARDEN',
  INDUSTRY_AGRICULTURE = 'INDUSTRY_AGRICULTURE',
  LAW_GOV_POLITICS = 'LAW_GOV_POLITICS',
  NEWS_WEATHER_INFO = 'NEWS_WEATHER_INFO',
  PERSONAL_FINANCE = 'PERSONAL_FINANCE',
  REAL_ESTATE = 'REAL_ESTATE',
  RELIGION_SPIRITUALITY = 'RELIGION_SPIRITUALITY',
  SOCIETY = 'SOCIETY',
  SPORTS = 'SPORTS',
  STYLE_FASHION = 'STYLE_FASHION',
  TECHNOLOGY_COMPUTERS = 'TECHNOLOGY_COMPUTERS',
  TRAVEL = 'TRAVEL',
}

export interface ClippedDomainDTO {
  __typename?: 'ClippedDomainDTO';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface Closed {
  __typename?: 'Closed';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface ClosedInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Cms {
  __typename?: 'Cms';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
}

export interface CmsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export enum ColorPaletteEnum {
  AQUAMARINE = 'AQUAMARINE',
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  DARKGREY = 'DARKGREY',
  DARKORANGE = 'DARKORANGE',
  GREEN = 'GREEN',
  GREY = 'GREY',
  LIGHTBROWN = 'LIGHTBROWN',
  LIGHTGREEN = 'LIGHTGREEN',
  LIGHTGREY = 'LIGHTGREY',
  LIGHTRED = 'LIGHTRED',
  LIGHTYELLOW = 'LIGHTYELLOW',
  MIDNIGHTBLUE = 'MIDNIGHTBLUE',
  NAVYBLUE = 'NAVYBLUE',
  ORANGE = 'ORANGE',
  RASPBERRY = 'RASPBERRY',
  RED = 'RED',
  TURQUOISE = 'TURQUOISE',
  VIOLET = 'VIOLET',
  YELLOW = 'YELLOW',
}

export interface CommentsCreatedAt {
  __typename?: 'CommentsCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface CommentsCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface CommentsResponse {
  __typename?: 'CommentsResponse';
  rows: Array<TicketComment>;
  total: Scalars['Int']['output'];
}

export interface CommentsTicketId {
  __typename?: 'CommentsTicketId';
  value: Array<Scalars['ID']['output']>;
}

export interface CommentsTicketIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface CommentsUserId {
  __typename?: 'CommentsUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface CommentsUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export enum ContactTypeEnum {
  EMAIL_TYPE_AGREEMENT = 'EMAIL_TYPE_AGREEMENT',
  EMAIL_TYPE_CONTACT = 'EMAIL_TYPE_CONTACT',
  PHONE_TYPE_AGREEMENT = 'PHONE_TYPE_AGREEMENT',
  PHONE_TYPE_CONTACT = 'PHONE_TYPE_CONTACT',
}

export enum ContentImportanceEnum {
  HIGH_IMPORTANCE = 'HIGH_IMPORTANCE',
  NORMAL_IMPORTANCE = 'NORMAL_IMPORTANCE',
}

export interface ContentQuarterly {
  __typename?: 'ContentQuarterly';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface ContentQuarterlyInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface ControlDomainDTO {
  __typename?: 'ControlDomainDTO';
  aoCommentsCount: Scalars['Int']['output'];
  contentMarketingLinksCount: Scalars['Int']['output'];
  diversificationLinksCount: Scalars['Int']['output'];
  domain: Domain;
  fulfillmentUsers: Array<User>;
  quarter?: Maybe<Quarter>;
  textSum: Scalars['Int']['output'];
}

export enum CountryEnum {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  GZ = 'GZ',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  ST = 'ST',
  SV = 'SV',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export interface CountryInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface CreateSubpageInput {
  characterCount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainId: Scalars['String']['input'];
  heading?: InputMaybe<Scalars['String']['input']>;
  keywords: Array<Scalars['String']['input']>;
  priority: SubpagePriorityEnum;
  status: SubpageStatusEnum;
  targetCharacterCount?: InputMaybe<Scalars['Int']['input']>;
  targetDescription?: InputMaybe<Scalars['String']['input']>;
  targetHeading?: InputMaybe<Scalars['String']['input']>;
  targetTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SubpageTypeEnum>;
  url: Scalars['String']['input'];
}

export interface CreateTextOrderGuidelineInput {
  domainId: Scalars['String']['input'];
  guidelines?: InputMaybe<Scalars['String']['input']>;
  textType: TextOrderTextTypeEnum;
}

export interface CreateTextOrderInput {
  comments?: InputMaybe<Scalars['String']['input']>;
  domainId: Scalars['String']['input'];
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  finalTextCount?: InputMaybe<Scalars['Int']['input']>;
  implementationTicketId?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  orderType?: InputMaybe<OrderTypeEnum>;
  plannedPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  priority?: InputMaybe<TextOrderPriorityEnum>;
  schema?: InputMaybe<TextOrderSchemaDTOInput>;
  sendingToClientTicketId?: InputMaybe<Scalars['String']['input']>;
  status: TextOrderStatusEnum;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  textScore?: InputMaybe<Scalars['Int']['input']>;
  textToBeWrittenBy?: InputMaybe<TextWriterEnum>;
  textType?: InputMaybe<TextOrderTextTypeEnum>;
  textUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
  verificationDate?: InputMaybe<Scalars['DateTime']['input']>;
  verificationTime?: InputMaybe<Scalars['Int']['input']>;
  verifierId?: InputMaybe<Scalars['String']['input']>;
}

export interface CreateTextOrdersDTO {
  __typename?: 'CreateTextOrdersDTO';
  input: TextOrderInput;
}

export interface CreateTextOrdersDTOInput {
  input: CreateTextOrderInput;
}

export interface CreateUserInput {
  appLocale?: InputMaybe<LocaleEnum>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  crmImportId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  feSettings?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  importId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relogin?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface CreateUserSubstitutionInput {
  absentEmployeeUserId: Scalars['String']['input'];
  beginsDate: Scalars['String']['input'];
  endsDate: Scalars['String']['input'];
  replacementEmployeeUserId: Scalars['String']['input'];
}

export interface CreatedAt {
  __typename?: 'CreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface CreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface CreatedByUserId {
  __typename?: 'CreatedByUserId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface CreatedByUserIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Credential {
  __typename?: 'Credential';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  domain?: Maybe<Domain>;
  files?: Maybe<Array<CredentialFile>>;
  host?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  login?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<User>;
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface CredentialFile {
  __typename?: 'CredentialFile';
  id: Scalars['ID']['output'];
  /** @deprecated Use "id" and /api/credential/file/{id} route */
  path: Scalars['String']['output'];
  sizeInKb: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  user: User;
}

export enum CurrencyEnum {
  AED = 'AED',
  AUD = 'AUD',
  CAD = 'CAD',
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP',
  PLN = 'PLN',
  USD = 'USD',
}

export interface Date {
  __typename?: 'Date';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface DateInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface DateRange {
  __typename?: 'DateRange';
  from: Scalars['DateTime']['output'];
  to: Scalars['DateTime']['output'];
}

export interface DateRangeInput {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
}

export interface Deadline {
  __typename?: 'Deadline';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface DeadlineInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface Deal {
  __typename?: 'Deal';
  actions_quarterly?: Maybe<Scalars['Int']['output']>;
  activation_amount?: Maybe<Scalars['Float']['output']>;
  active: Scalars['Boolean']['output'];
  amount?: Maybe<Scalars['Float']['output']>;
  analytics_month_end_date?: Maybe<Scalars['String']['output']>;
  basket?: Maybe<BasketEnum>;
  communication_language?: Maybe<DomainLanguageEnum>;
  communication_language_name?: Maybe<Scalars['String']['output']>;
  content_creation: Scalars['Boolean']['output'];
  content_guidelines: Scalars['Boolean']['output'];
  content_implementation: Scalars['Boolean']['output'];
  content_quarterly: Scalars['Int']['output'];
  created_at: Scalars['String']['output'];
  currency: CurrencyEnum;
  discounts?: Maybe<Array<DealDiscount>>;
  diversification_links_count: Scalars['Int']['output'];
  domain?: Maybe<Domain>;
  end_date?: Maybe<Scalars['String']['output']>;
  extra_data?: Maybe<DealExtraDataDTO>;
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['Int']['output']>;
  import_page_id?: Maybe<Scalars['Int']['output']>;
  indefinite: Scalars['Boolean']['output'];
  ipress_monthly?: Maybe<Scalars['Int']['output']>;
  is_latest: Scalars['Boolean']['output'];
  is_whitelabel?: Maybe<Scalars['Boolean']['output']>;
  link_building_budget?: Maybe<Scalars['Float']['output']>;
  lspType?: Maybe<LspType>;
  lsp_type_id?: Maybe<Scalars['String']['output']>;
  max_budget?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  nextDeal?: Maybe<Deal>;
  orig_real_start_date?: Maybe<Scalars['String']['output']>;
  orig_start_date?: Maybe<Scalars['String']['output']>;
  original_created_at?: Maybe<Scalars['String']['output']>;
  previousDeal?: Maybe<Deal>;
  product?: Maybe<DealCrmProductEnum>;
  real_amount?: Maybe<Scalars['Float']['output']>;
  real_start_date?: Maybe<Scalars['String']['output']>;
  sponsored_articles_count: Scalars['Int']['output'];
  start_date: Scalars['String']['output'];
  status?: Maybe<DealStatusEnum>;
  status_name?: Maybe<Scalars['String']['output']>;
  suspension_date?: Maybe<Scalars['String']['output']>;
  termination_should_be_saved?: Maybe<Scalars['Boolean']['output']>;
  terminations?: Maybe<Array<DealTermination>>;
  text_reduction_percent?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<DealTypeEnum>;
  type_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface DealBasketInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export enum DealCrmProductEnum {
  ALLEGRO_ADS = 'ALLEGRO_ADS',
  AMAZON_ADS = 'AMAZON_ADS',
  ANALYTICS = 'ANALYTICS',
  BANNERY = 'BANNERY',
  BING_ADS = 'BING_ADS',
  BLOG = 'BLOG',
  CONTENT = 'CONTENT',
  FB_ADS = 'FB_ADS',
  GOOGLE_ADS = 'GOOGLE_ADS',
  LINKBUILDING = 'LINKBUILDING',
  LINKEDIN_ADS = 'LINKEDIN_ADS',
  MOBILE = 'MOBILE',
  NOTIFY = 'NOTIFY',
  NOTIFYSEO = 'NOTIFYSEO',
  RWD = 'RWD',
  SEO = 'SEO',
  SEOADS = 'SEOADS',
  SXO = 'SXO',
  TIKTOK_ADS = 'TIKTOK_ADS',
  TRUSTMATE = 'TRUSTMATE',
  UX = 'UX',
  WEBDEV_INT = 'WEBDEV_INT',
}

export interface DealDiscount {
  __typename?: 'DealDiscount';
  created_at?: Maybe<Scalars['String']['output']>;
  deal: Deal;
  end_date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['String']['output']>;
  original_created_at?: Maybe<Scalars['String']['output']>;
  start_date: Scalars['String']['output'];
  unit: DealDiscountCrmUnitEnum;
  updated_at?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
}

export enum DealDiscountCrmUnitEnum {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
}

export interface DealDomain {
  __typename?: 'DealDomain';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DealDomainInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DealExtraDataDTO {
  __typename?: 'DealExtraDataDTO';
  ads_client_pays_budget?: Maybe<Scalars['Boolean']['output']>;
  ads_max_budget?: Maybe<Scalars['Float']['output']>;
  ads_min_budget?: Maybe<Scalars['Float']['output']>;
  ads_percent_profit?: Maybe<Scalars['Float']['output']>;
  ads_profit_amount?: Maybe<Scalars['Float']['output']>;
}

export interface DealExtraDataDTOInput {
  adsClientPaysBudget?: InputMaybe<Scalars['Boolean']['input']>;
  adsMaxBudget?: InputMaybe<Scalars['Float']['input']>;
  adsMinBudget?: InputMaybe<Scalars['Float']['input']>;
  adsPercentProfit?: InputMaybe<Scalars['Float']['input']>;
  adsProfitAmount?: InputMaybe<Scalars['Float']['input']>;
}

export interface DealFulltext {
  __typename?: 'DealFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DealGridFilterInput {
  domain?: InputMaybe<DealDomainInput>;
  name?: InputMaybe<NameInput>;
}

export interface DealGridInput {
  filter?: InputMaybe<DealGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DealIds {
  __typename?: 'DealIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DealLspType {
  __typename?: 'DealLspType';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DealLspTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DealProducts {
  __typename?: 'DealProducts';
  operator: OperatorEnum;
  value: Array<DealCrmProductEnum>;
}

export interface DealProductsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DealStatus {
  __typename?: 'DealStatus';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export enum DealStatusEnum {
  ACTIVE = 'ACTIVE',
  COMPLAINT = 'COMPLAINT',
  INACTIVE = 'INACTIVE',
  PREVENTION = 'PREVENTION',
  SUSPENDED = 'SUSPENDED',
  TERMINATION = 'TERMINATION',
}

export interface DealStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DealTermination {
  __typename?: 'DealTermination';
  action?: Maybe<Scalars['String']['output']>;
  amount_delta?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['String']['output']>;
  deal: Deal;
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['String']['output']>;
  original_created_at?: Maybe<Scalars['String']['output']>;
  penalty_amount?: Maybe<Scalars['Float']['output']>;
  penalty_payment_date?: Maybe<Scalars['String']['output']>;
  request_date: Scalars['String']['output'];
  saved: Scalars['Boolean']['output'];
  termination_date?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['String']['output']>;
}

export interface DealType {
  __typename?: 'DealType';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export enum DealTypeEnum {
  ASKWIDE = 'ASKWIDE',
  EFFECT = 'EFFECT',
  FLAT_RATE_STANDARD = 'FLAT_RATE_STANDARD',
  HYBRID = 'HYBRID',
  MODULAR = 'MODULAR',
  MODULAR_KIWIKICK = 'MODULAR_KIWIKICK',
  MODULAR_LAST_AGENCY = 'MODULAR_LAST_AGENCY',
  PRIME = 'PRIME',
  PRIME_HOME = 'PRIME_HOME',
  PRIME_PLUS = 'PRIME_PLUS',
  PRODUCT_ONETIME = 'PRODUCT_ONETIME',
  PRODUCT_ONETIME_KIWIKICK = 'PRODUCT_ONETIME_KIWIKICK',
  PRODUCT_ONETIME_LAST_AGENCY = 'PRODUCT_ONETIME_LAST_AGENCY',
  PRODUCT_STANDARD = 'PRODUCT_STANDARD',
  PRODUCT_STANDARD_KIWIKICK = 'PRODUCT_STANDARD_KIWIKICK',
  PRODUCT_STANDARD_LAST_AGENCY = 'PRODUCT_STANDARD_LAST_AGENCY',
  VENDASTA = 'VENDASTA',
  VENDASTA_WEB = 'VENDASTA_WEB',
}

export interface DealTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DealsResponse {
  __typename?: 'DealsResponse';
  rows: Array<Deal>;
  total: Scalars['Int']['output'];
}

export interface DefaultType extends DefaultTypeInterface {
  __typename?: 'DefaultType';
  color?: Maybe<ColorPaletteEnum>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
}

export interface DefaultTypeInterface {
  color?: Maybe<ColorPaletteEnum>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
}

export interface Details {
  __typename?: 'Details';
  budget: Scalars['Int']['output'];
  closed: Scalars['Int']['output'];
  domains: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  open: Scalars['Int']['output'];
  overdue: Scalars['Int']['output'];
  thisWeek: Scalars['Int']['output'];
  today: Scalars['Int']['output'];
}

export interface DiversificationLinksCount {
  __typename?: 'DiversificationLinksCount';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DiversificationLinksCountInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Domain {
  __typename?: 'Domain';
  GAMGAServices?: Maybe<Array<GAMGAService>>;
  GAMSearchConsoleSites?: Maybe<Array<GAMSearchConsoleSite>>;
  active_products: Array<Scalars['String']['output']>;
  adminUsers?: Maybe<Array<User>>;
  ads_comment?: Maybe<Scalars['String']['output']>;
  brief_copy?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<CategoryEnum>>;
  categories_names: Array<Scalars['String']['output']>;
  cms?: Maybe<Cms>;
  comment_copy?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<DomainContacts>>;
  content_importance?: Maybe<ContentImportanceEnum>;
  content_importance_name?: Maybe<Scalars['String']['output']>;
  content_marketing_spreadsheet?: Maybe<Scalars['String']['output']>;
  countable_texts?: Maybe<Array<LinkGroupEnum>>;
  countable_texts_names: Array<Scalars['String']['output']>;
  country?: Maybe<CountryEnum>;
  country_name?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  cs_comment?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  days_to_end_quarter?: Maybe<Scalars['Int']['output']>;
  deals?: Maybe<Array<Deal>>;
  diversification_linkbuilding_strategy?: Maybe<LinkbuildingStrategyEnum>;
  diversification_linkbuilding_strategy_name?: Maybe<Scalars['String']['output']>;
  first_deal_start_date?: Maybe<Scalars['String']['output']>;
  fulfillmentUsers?: Maybe<Array<User>>;
  full_url?: Maybe<Scalars['String']['output']>;
  full_url_locked: Scalars['Boolean']['output'];
  google_ads_currency?: Maybe<Scalars['String']['output']>;
  google_ads_last_fetch_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  import_id: Scalars['String']['output'];
  is_whitelabel?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<LanguageCodeEnum>;
  language_name?: Maybe<Scalars['String']['output']>;
  lastQuarter?: Maybe<Quarter>;
  linkbuilding_diversification_spreadsheet?: Maybe<Scalars['String']['output']>;
  linkbuilding_strategy?: Maybe<LinkbuildingStrategyEnum>;
  linkbuilding_strategy_name?: Maybe<Scalars['String']['output']>;
  /** @deprecated locale will be removed in next update, use language now */
  locale?: Maybe<DomainLanguageEnum>;
  /** @deprecated locale will be removed in next update, use language now */
  locale_name?: Maybe<Scalars['String']['output']>;
  max_phrases?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated use field `newestDeals` instead */
  newestAdsDeal?: Maybe<Deal>;
  newestDeals?: Maybe<Array<GroupedDealDTO>>;
  /** @deprecated use field `newestDeals` instead */
  newestFbAdsDeal?: Maybe<Deal>;
  newestGridDeal?: Maybe<Deal>;
  /** @deprecated use field `newestDeals` instead */
  newestSeoDeal?: Maybe<Deal>;
  /** @deprecated use field `newestDeals` instead */
  newestSxoDeal?: Maybe<Deal>;
  /** @deprecated use field `newestDeals` instead */
  newestUxDeal?: Maybe<Deal>;
  percentage_of_positioning: Scalars['Int']['output'];
  phraseCount?: Maybe<Scalars['Int']['output']>;
  positioned_domain?: Maybe<Scalars['String']['output']>;
  quarterOverrides?: Maybe<Array<QuarterOverride>>;
  quarters?: Maybe<Array<Quarter>>;
  related?: Maybe<Array<Domain>>;
  relatedTo?: Maybe<Array<Domain>>;
  reportSettings?: Maybe<DomainExternalReportSettings>;
  s4sInstance?: Maybe<S4sInstance>;
  salesUsers?: Maybe<Array<User>>;
  score?: Maybe<Scalars['Int']['output']>;
  score_spreadsheet?: Maybe<Scalars['String']['output']>;
  semContactUsers?: Maybe<Array<User>>;
  semFulfillmentUsers?: Maybe<Array<User>>;
  seoContactUsers?: Maybe<Array<User>>;
  seo_comment?: Maybe<Scalars['String']['output']>;
  service_type?: Maybe<ServiceTypeEnum>;
  service_type_name?: Maybe<Scalars['String']['output']>;
  strategy_link?: Maybe<Scalars['String']['output']>;
  textOrderGuidelines?: Maybe<Array<TextOrderGuideline>>;
  text_to_be_written_by?: Maybe<TextWriterEnum>;
  updated_at?: Maybe<Scalars['String']['output']>;
  uxUsers?: Maybe<Array<User>>;
}

export interface DomainActivitiesGridFilterInput {
  createdAt?: InputMaybe<ActivitiesCreatedAtInput>;
  domainId?: InputMaybe<DomainIdInput>;
  userId?: InputMaybe<ActivitiesUserIdInput>;
}

export interface DomainActivitiesGridInput {
  filter?: InputMaybe<DomainActivitiesGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainActivitiesResponse {
  __typename?: 'DomainActivitiesResponse';
  rows: Array<Activity>;
  total: Scalars['Int']['output'];
}

export interface DomainAdsBudget {
  __typename?: 'DomainAdsBudget';
  created_at: Scalars['String']['output'];
  date: Scalars['String']['output'];
  domain: Domain;
  id: Scalars['ID']['output'];
  planned_budget: Scalars['Float']['output'];
  spent_budget: Scalars['Float']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface DomainAdsBudgetInput {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  plannedBudget?: InputMaybe<Scalars['Float']['input']>;
  spentBudget?: InputMaybe<Scalars['Float']['input']>;
}

export interface DomainAdsDeposit {
  __typename?: 'DomainAdsDeposit';
  created_at: Scalars['String']['output'];
  date: Scalars['String']['output'];
  deposited_budget?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain: Domain;
  id: Scalars['ID']['output'];
  is_infinite: Scalars['Boolean']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface DomainContactInfo {
  __typename?: 'DomainContactInfo';
  contact_type?: Maybe<ContactTypeEnum>;
  contact_type_name?: Maybe<Scalars['String']['output']>;
  contact_value: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface DomainContacts {
  __typename?: 'DomainContacts';
  contact_info: Array<DomainContactInfo>;
  contact_person: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  domain: Domain;
  id: Scalars['ID']['output'];
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface DomainContactsFulltext {
  __typename?: 'DomainContactsFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainContactsFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainContactsGridFilterInput {
  fulltext?: InputMaybe<DomainContactsFulltextInput>;
}

export interface DomainContactsGridInput {
  filter?: InputMaybe<DomainContactsGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainContactsResponse {
  __typename?: 'DomainContactsResponse';
  rows: Array<DomainContacts>;
  total: Scalars['Int']['output'];
}

export interface DomainControlDate {
  __typename?: 'DomainControlDate';
  value: Array<Scalars['String']['output']>;
}

export interface DomainControlDateInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainControlGridFilterInput {
  adminUserIds?: InputMaybe<AdminUserIdsInput>;
  agreementStartDateRange?: InputMaybe<AgreementStartDateRangeInput>;
  basket?: InputMaybe<DealBasketInput>;
  budget?: InputMaybe<BudgetInput>;
  categories?: InputMaybe<CategoriesInput>;
  cms?: InputMaybe<CmsInput>;
  contentQuarterly?: InputMaybe<ContentQuarterlyInput>;
  country?: InputMaybe<CountryInput>;
  date?: InputMaybe<DomainControlDateInput>;
  dealProducts?: InputMaybe<DealProductsInput>;
  dealStatus?: InputMaybe<DealStatusInput>;
  diversificationLinksCount?: InputMaybe<DiversificationLinksCountInput>;
  domainId?: InputMaybe<DomainDomainIdInput>;
  domainName?: InputMaybe<DomainNameInput>;
  fulfillmentUserIds?: InputMaybe<FulfillmentUserIdsInput>;
  fulltext?: InputMaybe<DomainFullTextInput>;
  gaServiceId?: InputMaybe<GAServiceIdInput>;
  gscWebsiteUrl?: InputMaybe<GSCWebsiteUrlInput>;
  language?: InputMaybe<LanguageInput>;
  linkBuildingBudget?: InputMaybe<LinkBuildingBudgetInput>;
  linkbuildingStrategy?: InputMaybe<LinkbuildingStrategyInput>;
  lspType?: InputMaybe<DealLspTypeInput>;
  relatedDomainIds?: InputMaybe<RelatedDomainIdsInput>;
  saleUserIds?: InputMaybe<SaleUserIdsInput>;
  semContactUserIds?: InputMaybe<SemContactUserIdsInput>;
  semFulfillmentUserIds?: InputMaybe<SemFulfillmentUserIdsInput>;
  seoContactUserIds?: InputMaybe<SeoContactUserIdsInput>;
  sponsoredArticlesCount?: InputMaybe<SponsoredArticlesCountInput>;
  status?: InputMaybe<DomainStatusInput>;
  terminationDateRange?: InputMaybe<TerminationDateRangeInput>;
  type?: InputMaybe<DealTypeInput>;
  userIds?: InputMaybe<UserIdsInput>;
  uxUserIds?: InputMaybe<UxUserIdsInput>;
}

export interface DomainControlGridInput {
  filter?: InputMaybe<DomainControlGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainDomainId {
  __typename?: 'DomainDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainExternalReportSettings {
  __typename?: 'DomainExternalReportSettings';
  GAMGAService?: Maybe<GAMGAService>;
  GAMGAServiceAudience?: Maybe<GAMGAServiceAudience>;
  GAMSearchConsoleSite?: Maybe<GAMSearchConsoleSite>;
  branded_keywords?: Maybe<Array<Scalars['String']['output']>>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryEnum>;
  created_at: Scalars['String']['output'];
  domain: Domain;
  domain_id: Scalars['ID']['output'];
  ecommerce: Scalars['Boolean']['output'];
  excluded_keywords?: Maybe<Array<Scalars['String']['output']>>;
  google_ads_account_id?: Maybe<Scalars['String']['output']>;
  google_ads_report_emails?: Maybe<Array<Scalars['String']['output']>>;
  google_ads_report_languages?: Maybe<Array<Scalars['String']['output']>>;
  google_ads_send_email?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<LanguageCodeEnum>;
  report_emails?: Maybe<Array<Scalars['String']['output']>>;
  report_languages?: Maybe<Array<Scalars['String']['output']>>;
  send_email?: Maybe<Scalars['Boolean']['output']>;
  start_date?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface DomainExternalReportSettingsInput {
  brandedKeywords?: InputMaybe<Array<Scalars['String']['input']>>;
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<CountryEnum>;
  ecommerce?: InputMaybe<Scalars['Boolean']['input']>;
  excludedKeywords?: InputMaybe<Array<Scalars['String']['input']>>;
  gamGaServiceAudienceId?: InputMaybe<Scalars['String']['input']>;
  gamGaServiceId?: InputMaybe<Scalars['String']['input']>;
  gamSearchConsoleSiteId?: InputMaybe<Scalars['String']['input']>;
  googleAdsAccountId?: InputMaybe<Scalars['String']['input']>;
  googleAdsReportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  googleAdsReportLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  googleAdsSendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<LanguageCodeEnum>;
  reportEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  reportLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
}

export interface DomainFullText {
  __typename?: 'DomainFullText';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainFullTextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainGridFilterInput {
  adminUserIds?: InputMaybe<AdminUserIdsInput>;
  agreementStartDateRange?: InputMaybe<AgreementStartDateRangeInput>;
  basket?: InputMaybe<DealBasketInput>;
  budget?: InputMaybe<BudgetInput>;
  categories?: InputMaybe<CategoriesInput>;
  cms?: InputMaybe<CmsInput>;
  contentQuarterly?: InputMaybe<ContentQuarterlyInput>;
  country?: InputMaybe<CountryInput>;
  dealProducts?: InputMaybe<DealProductsInput>;
  dealStatus?: InputMaybe<DealStatusInput>;
  diversificationLinksCount?: InputMaybe<DiversificationLinksCountInput>;
  domainId?: InputMaybe<DomainDomainIdInput>;
  domainName?: InputMaybe<DomainNameInput>;
  fulfillmentUserIds?: InputMaybe<FulfillmentUserIdsInput>;
  fulltext?: InputMaybe<DomainFullTextInput>;
  gaServiceId?: InputMaybe<GAServiceIdInput>;
  gscWebsiteUrl?: InputMaybe<GSCWebsiteUrlInput>;
  language?: InputMaybe<LanguageInput>;
  linkBuildingBudget?: InputMaybe<LinkBuildingBudgetInput>;
  linkbuildingStrategy?: InputMaybe<LinkbuildingStrategyInput>;
  lspType?: InputMaybe<DealLspTypeInput>;
  relatedDomainIds?: InputMaybe<RelatedDomainIdsInput>;
  saleUserIds?: InputMaybe<SaleUserIdsInput>;
  semContactUserIds?: InputMaybe<SemContactUserIdsInput>;
  semFulfillmentUserIds?: InputMaybe<SemFulfillmentUserIdsInput>;
  seoContactUserIds?: InputMaybe<SeoContactUserIdsInput>;
  sponsoredArticlesCount?: InputMaybe<SponsoredArticlesCountInput>;
  status?: InputMaybe<DomainStatusInput>;
  terminationDateRange?: InputMaybe<TerminationDateRangeInput>;
  type?: InputMaybe<DealTypeInput>;
  userIds?: InputMaybe<UserIdsInput>;
  uxUserIds?: InputMaybe<UxUserIdsInput>;
}

export interface DomainGridInput {
  dealsOrder?: Array<Scalars['String']['input']>;
  filter?: InputMaybe<DomainGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainId {
  __typename?: 'DomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainIds {
  __typename?: 'DomainIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export enum DomainLanguageEnum {
  cz_CZ = 'cz_CZ',
  en_GB = 'en_GB',
  pl_PL = 'pl_PL',
}

export interface DomainName {
  __typename?: 'DomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainNamesGridFilterInput {
  adminUserIds?: InputMaybe<AdminUserIdsInput>;
  agreementStartDateRange?: InputMaybe<AgreementStartDateRangeInput>;
  budget?: InputMaybe<BudgetInput>;
  categories?: InputMaybe<CategoriesInput>;
  cms?: InputMaybe<CmsInput>;
  contentQuarterly?: InputMaybe<ContentQuarterlyInput>;
  country?: InputMaybe<CountryInput>;
  domainId?: InputMaybe<DomainDomainIdInput>;
  domainName?: InputMaybe<DomainNameInput>;
  fulfillmentUserIds?: InputMaybe<FulfillmentUserIdsInput>;
  fulltext?: InputMaybe<DomainFullTextInput>;
  linkBuildingBudget?: InputMaybe<LinkBuildingBudgetInput>;
  locale?: InputMaybe<LanguageInput>;
  lspType?: InputMaybe<DealLspTypeInput>;
  relatedDomainIds?: InputMaybe<RelatedDomainIdsInput>;
  saleUserIds?: InputMaybe<SaleUserIdsInput>;
  semContactUserIds?: InputMaybe<SemContactUserIdsInput>;
  semFulfillmentUserIds?: InputMaybe<SemFulfillmentUserIdsInput>;
  seoContactUserIds?: InputMaybe<SeoContactUserIdsInput>;
  status?: InputMaybe<DomainStatusInput>;
  type?: InputMaybe<DealTypeInput>;
  userIds?: InputMaybe<UserIdsInput>;
  uxUserIds?: InputMaybe<UxUserIdsInput>;
}

export interface DomainNamesGridInput {
  filter?: InputMaybe<DomainNamesGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface DomainNamesResponse {
  __typename?: 'DomainNamesResponse';
  rows: Array<ClippedDomainDTO>;
  total: Scalars['Int']['output'];
}

export interface DomainReport {
  __typename?: 'DomainReport';
  created_at: Scalars['String']['output'];
  data: Array<DomainReportsData>;
  domain: Domain;
  id: Scalars['ID']['output'];
  language?: Maybe<LocaleEnum>;
  report_path?: Maybe<Scalars['String']['output']>;
  report_type?: Maybe<DomainReportTypeEnum>;
  report_type_name?: Maybe<Scalars['String']['output']>;
  user: User;
}

export interface DomainReportFieldInput {
  id: Scalars['String']['input'];
  value: Scalars['String']['input'];
}

export interface DomainReportGridFilterInput {
  domainId?: InputMaybe<DomainIdInput>;
  onlyCreated?: InputMaybe<OnlyCreatedInput>;
}

export interface DomainReportGridInput {
  filter?: InputMaybe<DomainReportGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export enum DomainReportTypeEnum {
  DEFAULT_REPORT = 'DEFAULT_REPORT',
  PRIME = 'PRIME',
  PRIME_PLUS = 'PRIME_PLUS',
}

export interface DomainReportsData {
  __typename?: 'DomainReportsData';
  field: DomainReportsFields;
  value: Scalars['String']['output'];
}

export interface DomainReportsFields {
  __typename?: 'DomainReportsFields';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface DomainReportsResponse {
  __typename?: 'DomainReportsResponse';
  rows: Array<DomainReport>;
  total: Scalars['Int']['output'];
}

export interface DomainStatus {
  __typename?: 'DomainStatus';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface DomainStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface DomainsControlResponse {
  __typename?: 'DomainsControlResponse';
  controls: Array<ControlDomainDTO>;
  rows: Array<Domain>;
  total: Scalars['Int']['output'];
}

export interface DomainsResponse {
  __typename?: 'DomainsResponse';
  rows: Array<Domain>;
  total: Scalars['Int']['output'];
}

export interface EmailField {
  __typename?: 'EmailField';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface EmailFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface EnumArrayResponse {
  __typename?: 'EnumArrayResponse';
  id: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
}

export interface EnumResponse {
  __typename?: 'EnumResponse';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
}

export interface EnumTranslationResponse {
  __typename?: 'EnumTranslationResponse';
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface EnumTranslationWithDescriptionResponse {
  __typename?: 'EnumTranslationWithDescriptionResponse';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface ExternalReportDTO {
  __typename?: 'ExternalReportDTO';
  domainId?: Maybe<Scalars['String']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
  emailSent: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  link: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  reportDate: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
}

export interface FulfillmentUserIds {
  __typename?: 'FulfillmentUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface FulfillmentUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface GAMGAAccount {
  __typename?: 'GAMGAAccount';
  GAMGAServices?: Maybe<Array<GAMGAService>>;
  GAMInternalAccount?: Maybe<GAMInternalAccount>;
  account_id: Scalars['Int']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface GAMGAAccountCreatedAt {
  __typename?: 'GAMGAAccountCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface GAMGAAccountCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface GAMGAAccountGridFilterInput {
  createdAt?: InputMaybe<GAMGAAccountCreatedAtInput>;
  name?: InputMaybe<GAMGAAccountNameInput>;
}

export interface GAMGAAccountGridInput {
  filter?: InputMaybe<GAMGAAccountGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface GAMGAAccountName {
  __typename?: 'GAMGAAccountName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface GAMGAAccountNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface GAMGAAccountResponse {
  __typename?: 'GAMGAAccountResponse';
  rows: Array<GAMGAAccount>;
  total: Scalars['Int']['output'];
}

export interface GAMGAService {
  __typename?: 'GAMGAService';
  GAMAccount?: Maybe<GAMGAAccount>;
  GAMGAServiceAudiences: Array<GAMGAServiceAudience>;
  created_at: Scalars['String']['output'];
  domains: Array<Domain>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permission_ok: Scalars['Boolean']['output'];
  service_id: Scalars['String']['output'];
  website_url: Scalars['String']['output'];
}

export interface GAMGAServiceAudience {
  __typename?: 'GAMGAServiceAudience';
  audience_id: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  gam_service_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
}

export interface GAMInternalAccount {
  __typename?: 'GAMInternalAccount';
  count_ga: Scalars['Int']['output'];
  count_gsc: Scalars['Int']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface GAMInternalAccountCreatedAt {
  __typename?: 'GAMInternalAccountCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface GAMInternalAccountCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface GAMInternalAccountGridFilterInput {
  createdAt?: InputMaybe<GAMInternalAccountCreatedAtInput>;
  name?: InputMaybe<GAMInternalAccountNameInput>;
}

export interface GAMInternalAccountGridInput {
  filter?: InputMaybe<GAMInternalAccountGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface GAMInternalAccountName {
  __typename?: 'GAMInternalAccountName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface GAMInternalAccountNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface GAMInternalAccountResponse {
  __typename?: 'GAMInternalAccountResponse';
  rows: Array<GAMInternalAccount>;
  total: Scalars['Int']['output'];
}

export interface GAMSearchConsoleSite {
  __typename?: 'GAMSearchConsoleSite';
  created_at: Scalars['String']['output'];
  domain: Domain;
  id: Scalars['ID']['output'];
  website_url: Scalars['String']['output'];
}

export interface GAMServiceUserInGA {
  __typename?: 'GAMServiceUserInGA';
  GAMGAService: GAMGAService;
  created_at: Scalars['String']['output'];
  domain: Domain;
  gam_service_id: Scalars['String']['output'];
  user: User;
  user_id: Scalars['String']['output'];
}

export interface GAServiceId {
  __typename?: 'GAServiceId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface GAServiceIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface GSCWebsiteUrl {
  __typename?: 'GSCWebsiteUrl';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface GSCWebsiteUrlInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface GroupedDateRange {
  __typename?: 'GroupedDateRange';
  from: Scalars['DateTime']['output'];
  to: Scalars['DateTime']['output'];
}

export interface GroupedDateRangeInput {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
}

export interface GroupedDealDTO {
  __typename?: 'GroupedDealDTO';
  deal: Deal;
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
}

export interface GroupedPermissionsResponse {
  __typename?: 'GroupedPermissionsResponse';
  id: Scalars['String']['output'];
  options: Array<PermissionsResponse>;
  value: Scalars['String']['output'];
}

export interface GroupedPhraseGridFilterInput {
  dateRange?: InputMaybe<GroupedDateRangeInput>;
  domainId?: InputMaybe<PhraseDomainIdInput>;
  domainName?: InputMaybe<PhraseDomainNameInput>;
}

export interface GroupedPhraseGridInput {
  filter?: InputMaybe<GroupedPhraseGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface GroupedPhrases {
  __typename?: 'GroupedPhrases';
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phrases?: Maybe<Array<Phrase>>;
}

export interface GroupedPhrasesResponse {
  __typename?: 'GroupedPhrasesResponse';
  rows: Array<GroupedPhrases>;
  total: Scalars['Int']['output'];
}

export interface GroupedTranslatables {
  __typename?: 'GroupedTranslatables';
  rows?: Maybe<Array<TranslatableObject>>;
  type: Scalars['String']['output'];
}

export interface GroupedTranslatablesInput {
  rows: Array<TranslatableObjectInput>;
  type: Scalars['String']['input'];
}

export interface Image {
  __typename?: 'Image';
  id: Scalars['ID']['output'];
  model?: Maybe<Scalars['String']['output']>;
  related_id?: Maybe<Scalars['ID']['output']>;
  sizeInKb: Scalars['String']['output'];
  thumbnail_url: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  user?: Maybe<User>;
}

export interface ImportPhrasesSheetsResponse {
  __typename?: 'ImportPhrasesSheetsResponse';
  errors: Array<Scalars['String']['output']>;
  phrases: Array<Phrase>;
}

export interface ImportTextOrdersSheetsResponse {
  __typename?: 'ImportTextOrdersSheetsResponse';
  errors: Array<Scalars['String']['output']>;
  textOrders: Array<TextOrder>;
}

export enum LanguageCodeEnum {
  AR = 'AR',
  BG = 'BG',
  BN = 'BN',
  CA = 'CA',
  CS = 'CS',
  DA = 'DA',
  DE = 'DE',
  EL = 'EL',
  EN = 'EN',
  ES = 'ES',
  ET = 'ET',
  FA = 'FA',
  FI = 'FI',
  FR = 'FR',
  GU = 'GU',
  HI = 'HI',
  HR = 'HR',
  HU = 'HU',
  ID = 'ID',
  IS = 'IS',
  IT = 'IT',
  IW = 'IW',
  JA = 'JA',
  KN = 'KN',
  KO = 'KO',
  LT = 'LT',
  LV = 'LV',
  ML = 'ML',
  MR = 'MR',
  MS = 'MS',
  NL = 'NL',
  NO = 'NO',
  PA = 'PA',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SK = 'SK',
  SL = 'SL',
  SR = 'SR',
  SV = 'SV',
  TA = 'TA',
  TE = 'TE',
  TH = 'TH',
  TL = 'TL',
  TR = 'TR',
  UK = 'UK',
  UR = 'UR',
  VI = 'VI',
  ZH_CN = 'ZH_CN',
  ZH_TW = 'ZH_TW',
}

export interface LanguageInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<LanguageCodeEnum>;
}

export interface Link {
  __typename?: 'Link';
  anchor?: Maybe<Array<LinkAnchor>>;
  article_url?: Maybe<Scalars['String']['output']>;
  character_count?: Maybe<Scalars['Int']['output']>;
  copywriter?: Maybe<User>;
  created_at: Scalars['String']['output'];
  domain?: Maybe<Domain>;
  group: LinkGroupEnum;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order_date?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  planned_publication_date?: Maybe<Scalars['String']['output']>;
  portal?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  real_publication_date?: Maybe<Scalars['String']['output']>;
  status: LinkStatusEnum;
  text_delivery_date?: Maybe<Scalars['String']['output']>;
  text_url?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
}

export interface LinkAclIds {
  __typename?: 'LinkAclIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface LinkAnchor {
  __typename?: 'LinkAnchor';
  anchor: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: Link;
  link_id: Scalars['ID']['output'];
  updated_at: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface LinkAnchorDTO {
  __typename?: 'LinkAnchorDTO';
  anchor: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  link_id?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
}

export interface LinkAnchorDTOInput {
  anchor: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  linkId?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
}

export interface LinkBuildingBudget {
  __typename?: 'LinkBuildingBudget';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface LinkBuildingBudgetInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface LinkCreatedAt {
  __typename?: 'LinkCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface LinkCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface LinkDomainId {
  __typename?: 'LinkDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface LinkDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface LinkDomainName {
  __typename?: 'LinkDomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface LinkDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface LinkGridFilterInput {
  createdAt?: InputMaybe<LinkCreatedAtInput>;
  domainId?: InputMaybe<LinkDomainIdInput>;
  domainName?: InputMaybe<LinkDomainNameInput>;
  linkId?: InputMaybe<LinkIdInput>;
  ownerUserId?: InputMaybe<LinkOwnerUserIdInput>;
}

export interface LinkGridInput {
  filter?: InputMaybe<LinkGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export enum LinkGroupEnum {
  LINK_BWP_MULTI = 'LINK_BWP_MULTI',
  LINK_BWP_SINGLE = 'LINK_BWP_SINGLE',
  LINK_CONTENT_MARKETING_MULTI = 'LINK_CONTENT_MARKETING_MULTI',
  LINK_CONTENT_MARKETING_SINGLE = 'LINK_CONTENT_MARKETING_SINGLE',
  LINK_DIVERSIFICATION = 'LINK_DIVERSIFICATION',
  LINK_ICEAPRESS = 'LINK_ICEAPRESS',
}

export interface LinkIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface LinkOwnerUserId {
  __typename?: 'LinkOwnerUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface LinkOwnerUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export enum LinkStatusEnum {
  LINK_DONE = 'LINK_DONE',
  LINK_INVALID = 'LINK_INVALID',
  LINK_IN_PROGRESS = 'LINK_IN_PROGRESS',
  LINK_NEW = 'LINK_NEW',
}

export enum LinkbuildingStrategyEnum {
  A_AROUND_PHRASES_50_50_MAIN_SUBPAGES = 'A_AROUND_PHRASES_50_50_MAIN_SUBPAGES',
  B_AROUND_PHRASES_MOSTLY_SUBPAGES = 'B_AROUND_PHRASES_MOSTLY_SUBPAGES',
  C_AGGRESIVELY_IN_PHRASES_WHEN_FEW_SUBPAGES = 'C_AGGRESIVELY_IN_PHRASES_WHEN_FEW_SUBPAGES',
  D_AGGRESIVELY_IN_PHRASES_WHEN_A_LOT_OF_SUBPAGES = 'D_AGGRESIVELY_IN_PHRASES_WHEN_A_LOT_OF_SUBPAGES',
  E_MINIMUM_PHRASES_MOSTLY_BRAND_URL_HALF_ON_MAIN = 'E_MINIMUM_PHRASES_MOSTLY_BRAND_URL_HALF_ON_MAIN',
  F_MINIMUM_PHRASES_BRAND_URL_ON_SUBPAGES = 'F_MINIMUM_PHRASES_BRAND_URL_ON_SUBPAGES',
  G_MINIMUM_PHRASES_BRAND_URL_HALF_MAIN_30_NOFOLLOW = 'G_MINIMUM_PHRASES_BRAND_URL_HALF_MAIN_30_NOFOLLOW',
  H_100_PHRASES_HOME_PAGE_ONLY_BRAND = 'H_100_PHRASES_HOME_PAGE_ONLY_BRAND',
  I_100_PHRASES_HOME_PAGE_50_BRAND_50_URL = 'I_100_PHRASES_HOME_PAGE_50_BRAND_50_URL',
  NO_STRATEGY_LINKING_SUSPENDED = 'NO_STRATEGY_LINKING_SUSPENDED',
}

export interface LinkbuildingStrategyInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface LinksResponse {
  __typename?: 'LinksResponse';
  rows: Array<Link>;
  total: Scalars['Int']['output'];
}

export enum LocaleEnum {
  en_GB = 'en_GB',
  pl_PL = 'pl_PL',
}

export enum LocationEnum {
  AT = 'AT',
  BE = 'BE',
  BENL = 'BENL',
  CA = 'CA',
  CH = 'CH',
  CN = 'CN',
  COIN = 'COIN',
  COJP = 'COJP',
  COM = 'COM',
  COMAU = 'COMAU',
  COMUA = 'COMUA',
  COUK = 'COUK',
  COUKUK = 'COUKUK',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GR = 'GR',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  LT = 'LT',
  LV = 'LV',
  NL = 'NL',
  NO = 'NO',
  POL = 'POL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SE = 'SE',
  SK = 'SK',
  TR = 'TR',
}

export interface LspType {
  __typename?: 'LspType';
  id: Scalars['ID']['output'];
  value?: Maybe<Scalars['String']['output']>;
}

export interface Monitor {
  __typename?: 'Monitor';
  daily_uptime_ratio?: Maybe<Scalars['Float']['output']>;
  domain: Domain;
  friendly_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  monitor_id: Scalars['Int']['output'];
  monthly_uptime_ratio?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  weekly_uptime_ratio?: Maybe<Scalars['Float']['output']>;
}

export interface MonitorDomainName {
  __typename?: 'MonitorDomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface MonitorDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface MonitorFullText {
  __typename?: 'MonitorFullText';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface MonitorFullTextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface MonitorGridFilterInput {
  domainName?: InputMaybe<MonitorDomainNameInput>;
  friendlyName?: InputMaybe<MonitorNameInput>;
  fulltext?: InputMaybe<MonitorFullTextInput>;
  status?: InputMaybe<MonitorStatusInput>;
}

export interface MonitorGridInput {
  filter?: InputMaybe<MonitorGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface MonitorIds {
  __typename?: 'MonitorIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface MonitorLimitsDTO {
  __typename?: 'MonitorLimitsDTO';
  downMonitors: Scalars['Int']['output'];
  monitors: Scalars['Int']['output'];
  monitorsLimit: Scalars['Int']['output'];
  pausedMonitors: Scalars['Int']['output'];
  upMonitors: Scalars['Int']['output'];
}

export interface MonitorLimitsResponse {
  __typename?: 'MonitorLimitsResponse';
  downMonitors: Scalars['Int']['output'];
  monitors: Scalars['Int']['output'];
  monitorsLimit: Scalars['Int']['output'];
  pausedMonitors: Scalars['Int']['output'];
  upMonitors: Scalars['Int']['output'];
}

export interface MonitorLogDTO {
  __typename?: 'MonitorLogDTO';
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  logDate: Scalars['DateTime']['output'];
  reason: Scalars['String']['output'];
  type: Scalars['String']['output'];
}

export interface MonitorName {
  __typename?: 'MonitorName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface MonitorNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface MonitorStatus {
  __typename?: 'MonitorStatus';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface MonitorStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface MonitorsResponse {
  __typename?: 'MonitorsResponse';
  rows: Array<Monitor>;
  total: Scalars['Int']['output'];
}

export interface Mutation {
  __typename?: 'Mutation';
  addProjectUser: Project;
  addRelatedTicket: Ticket;
  addTicketPriority: Priority;
  addTicketStatus: Status;
  addTicketSubtask: Ticket;
  addTicketType: Type;
  addTodo: TicketTodo;
  addTodos: Array<TicketTodo>;
  archiveProject: Project;
  blockUser: User;
  changePassword: User;
  changeTicketAssignee: Ticket;
  createCMSItem: Cms;
  createCredentials: Credential;
  createDeal: Deal;
  createDealDiscount: DealDiscount;
  createDealTermination: DealTermination;
  createDomainAdsBudget: DomainAdsBudget;
  createDomainReport: DomainReport;
  createGAMInternalAccount: GAMInternalAccount;
  createLink: Link;
  createLinkAnchor: LinkAnchor;
  createLspType: LspType;
  createMonitor: Monitor;
  createMonthlyTickets: Scalars['Boolean']['output'];
  createPhrase: Phrase;
  createPhraseGroup: PhraseGroup;
  createPhrases: ImportPhrasesSheetsResponse;
  createProject: Project;
  createQuarterOverride: QuarterOverride;
  createRole: Role;
  createS4sInstance: S4sInstance;
  createSubpage: Subpage;
  createTeam: Team;
  createTextOrder: TextOrder;
  createTextOrderGuideline: TextOrderGuideline;
  createTextOrders: Array<TextOrder>;
  createTicket: Ticket;
  createTicketComment: TicketComment;
  createTicketDraft: Ticket;
  createUser: User;
  createUserSubstitution: UserSubstitution;
  deleteCMSItem: Cms;
  deleteCredentialAttachment: CredentialFile;
  deleteCredentials: Credential;
  deleteDefaultTicketType: DefaultTypeInterface;
  deleteGAMInternalAccount: GAMInternalAccount;
  deleteImage: Image;
  deleteLink: Link;
  deleteLinkAnchor: LinkAnchor;
  deleteLspType: LspType;
  deleteMonitor: Scalars['Boolean']['output'];
  deletePhrase: Phrase;
  deletePhraseGroup: PhraseGroup;
  deletePhrases: Array<Phrase>;
  deleteProject: Project;
  deleteProjectUser: Project;
  deleteQuarterOverride: QuarterOverride;
  deleteRelatedTicket: Ticket;
  deleteRole: Role;
  deleteS4sInstance: S4sInstance;
  deleteSubpage: Subpage;
  deleteSubpages: Array<Subpage>;
  deleteTeam: Team;
  deleteTextOrders: Array<TextOrder>;
  deleteTicket: Ticket;
  deleteTicketAttachment: TicketFile;
  deleteTicketComment?: Maybe<TicketComment>;
  deleteTicketCommentAttachment: TicketCommentFile;
  deleteTicketPriority: Priority;
  deleteTicketStatus: Status;
  deleteTicketSubtask: Ticket;
  deleteTicketTime: Scalars['String']['output'];
  deleteTicketType: Type;
  deleteTodo: TicketTodo;
  deleteUserAvatar: User;
  deleteUserSubstitution: UserSubstitution;
  groupPhrases: PhraseGroup;
  impersonate: User;
  importPhrasesFromGoogleSheets: ImportPhrasesSheetsResponse;
  importSubpageCsv: SubpageCsv;
  importTextOrderCsv: TextOrderCsv;
  importTextOrderFromGoogleSheets: ImportTextOrdersSheetsResponse;
  leaveImpersonation?: Maybe<User>;
  logTicketTime: TimeLog;
  loginUser?: Maybe<User>;
  logoutUser: Scalars['Boolean']['output'];
  markAllNotificationRead: Scalars['Boolean']['output'];
  markReadNotification: Scalars['Boolean']['output'];
  markUnreadNotification: Scalars['Boolean']['output'];
  refreshGA: Scalars['Boolean']['output'];
  refreshGAMAccessToken: GAMInternalAccount;
  refreshGSC: Scalars['Boolean']['output'];
  resetPassword: Scalars['String']['output'];
  saveDefaultTicketType?: Maybe<DefaultTypeInterface>;
  saveSeoNote: SeoNoteDTO;
  sendResetPassword: Scalars['Boolean']['output'];
  setPhrasesUserAssignedUrl: Array<Phrase>;
  setTemporaryGAAccess?: Maybe<GAMServiceUserInGA>;
  setTicketPriority: Ticket;
  setTranslations: Scalars['Boolean']['output'];
  syncProjectPriorities: Project;
  syncProjectStatuses: Project;
  syncRolePermissions: Role;
  syncUserPermissions: SyncPermissionsResponse;
  unarchiveProject: Project;
  unblockUser: User;
  ungroupPhrases?: Maybe<Array<Phrase>>;
  unwatchTicket: Ticket;
  updateCMSItem: Cms;
  updateCredentials: Credential;
  updateDeal: Deal;
  updateDomain: Domain;
  updateDomainAdsBudget: DomainAdsBudget;
  updateExternalReportSettings: DomainExternalReportSettings;
  updateGAMInternalAccount: GAMInternalAccount;
  updateImage: Image;
  updateImages: Array<Image>;
  updateLink: Link;
  updateLinkAnchor: LinkAnchor;
  updateLspType: LspType;
  updateMonitor: Monitor;
  updateNotificationSetting: Scalars['Boolean']['output'];
  updatePhrase: Phrase;
  updatePhraseGroup: PhraseGroup;
  updateProject: Project;
  updateQuarterOverride: QuarterOverride;
  updateRole: Role;
  updateS4sInstance: S4sInstance;
  updateSubpage: Subpage;
  updateTeam: Team;
  updateTextOrder: TextOrder;
  updateTextOrderGuideline: TextOrderGuideline;
  updateTextOrders: Array<TextOrder>;
  updateTicket: Ticket;
  updateTicketComment: TicketComment;
  updateTicketPriority: Priority;
  updateTicketStatus: Status;
  updateTicketTime: TimeLog;
  updateTicketType: Type;
  updateTodo: TicketTodo;
  updateUser: User;
  updateUserSubstitution: UserSubstitution;
  watchTicket: Ticket;
}

export interface MutationaddProjectUserArgs {
  projectId: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
}

export interface MutationaddRelatedTicketArgs {
  relatedTicketId: Scalars['String']['input'];
  relationType: RelationTypeEnum;
  ticketId: Scalars['ID']['input'];
}

export interface MutationaddTicketPriorityArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']['input']>;
  value: Scalars['String']['input'];
}

export interface MutationaddTicketStatusArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']['input']>;
  treatAsClosed?: InputMaybe<Scalars['Boolean']['input']>;
  value: Scalars['String']['input'];
  visibleInKanban?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationaddTicketSubtaskArgs {
  priority: Scalars['String']['input'];
  project: Scalars['String']['input'];
  status: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
}

export interface MutationaddTicketTypeArgs {
  color: ColorPaletteEnum;
  priority?: InputMaybe<Scalars['Float']['input']>;
  projectId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
}

export interface MutationaddTodoArgs {
  priority?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
  ticketId: Scalars['ID']['input'];
}

export interface MutationaddTodosArgs {
  ticketId: Scalars['ID']['input'];
  todos: Array<TodoInput>;
}

export interface MutationarchiveProjectArgs {
  id: Scalars['ID']['input'];
}

export interface MutationblockUserArgs {
  id: Scalars['ID']['input'];
}

export interface MutationchangePasswordArgs {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
}

export interface MutationchangeTicketAssigneeArgs {
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
}

export interface MutationcreateCMSItemArgs {
  value: Scalars['String']['input'];
}

export interface MutationcreateCredentialsArgs {
  comment?: InputMaybe<Scalars['String']['input']>;
  domainId: Scalars['String']['input'];
  host: Scalars['String']['input'];
  login: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface MutationcreateDealArgs {
  activationAmount: Scalars['Float']['input'];
  active: Scalars['Boolean']['input'];
  amount: Scalars['Float']['input'];
  analyticsMonthEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  communicationLanguage?: InputMaybe<DomainLanguageEnum>;
  currency: CurrencyEnum;
  diversificationLinksCount?: InputMaybe<Scalars['Int']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  extraData?: InputMaybe<DealExtraDataDTOInput>;
  indefinite: Scalars['Boolean']['input'];
  ipressMonthly?: InputMaybe<Scalars['Int']['input']>;
  isLatest: Scalars['Boolean']['input'];
  linkBuildingBudget?: InputMaybe<Scalars['Float']['input']>;
  lspTypeId?: InputMaybe<Scalars['String']['input']>;
  maxBudget: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  nofCreatedText: Scalars['Int']['input'];
  origRealStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  origStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  previousDealId?: InputMaybe<Scalars['ID']['input']>;
  product?: InputMaybe<DealCrmProductEnum>;
  realStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  sponsoredArticlesCount?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
  status?: InputMaybe<DealStatusEnum>;
  suspensionDate?: InputMaybe<Scalars['DateTime']['input']>;
  terminationShouldBeSaved?: InputMaybe<Scalars['Boolean']['input']>;
  textReductionPercent?: InputMaybe<Scalars['Int']['input']>;
  type: DealTypeEnum;
  url: Scalars['String']['input'];
}

export interface MutationcreateDealDiscountArgs {
  dealId: Scalars['ID']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  unit: DealDiscountCrmUnitEnum;
  value: Scalars['Int']['input'];
}

export interface MutationcreateDealTerminationArgs {
  action?: InputMaybe<Scalars['String']['input']>;
  amountDelta?: InputMaybe<Scalars['Float']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  dealId: Scalars['ID']['input'];
  penaltyAmount?: InputMaybe<Scalars['Float']['input']>;
  penaltyPaymentDate?: InputMaybe<Scalars['DateTime']['input']>;
  requestDate: Scalars['DateTime']['input'];
  saved: Scalars['Boolean']['input'];
  terminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  valid: Scalars['String']['input'];
}

export interface MutationcreateDomainAdsBudgetArgs {
  input: DomainAdsBudgetInput;
}

export interface MutationcreateDomainReportArgs {
  domainId: Scalars['ID']['input'];
  fields: Array<DomainReportFieldInput>;
  language?: InputMaybe<LocaleEnum>;
  reportType: DomainReportTypeEnum;
}

export interface MutationcreateGAMInternalAccountArgs {
  name: Scalars['String']['input'];
}

export interface MutationcreateLinkArgs {
  anchors?: InputMaybe<Array<LinkAnchorDTOInput>>;
  articleUrl?: InputMaybe<Scalars['String']['input']>;
  characterCount?: InputMaybe<Scalars['Int']['input']>;
  copywriterId?: InputMaybe<Scalars['ID']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  group: LinkGroupEnum;
  note?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['DateTime']['input']>;
  ownerId: Scalars['ID']['input'];
  plannedPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  portal?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  realPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  status: LinkStatusEnum;
  textDeliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  textUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationcreateLinkAnchorArgs {
  anchor: Scalars['String']['input'];
  linkId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
}

export interface MutationcreateLspTypeArgs {
  value: Scalars['String']['input'];
}

export interface MutationcreateMonitorArgs {
  domainId: Scalars['String']['input'];
  url: Scalars['String']['input'];
}

export interface MutationcreateMonthlyTicketsArgs {
  date: Scalars['String']['input'];
  domainId: Scalars['ID']['input'];
  product: Scalars['String']['input'];
}

export interface MutationcreatePhraseArgs {
  checkPeriod: Scalars['Int']['input'];
  domainId: Scalars['String']['input'];
  location?: InputMaybe<LocationEnum>;
  userAssignedUrl?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
}

export interface MutationcreatePhraseGroupArgs {
  domainId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phraseIds?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface MutationcreatePhrasesArgs {
  domainId: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
  phrases: Array<PhraseDTOInput>;
}

export interface MutationcreateProjectArgs {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
}

export interface MutationcreateQuarterOverrideArgs {
  charactersToCreate: Scalars['Int']['input'];
  domainId: Scalars['ID']['input'];
  endDate: Scalars['DateTime']['input'];
  number: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  type: QuarterTypeEnum;
}

export interface MutationcreateRoleArgs {
  permissions?: InputMaybe<Array<PermissionEnum>>;
  title: Scalars['String']['input'];
}

export interface MutationcreateS4sInstanceArgs {
  db: Scalars['String']['input'];
  host: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  prefix: Scalars['String']['input'];
  user: Scalars['String']['input'];
}

export interface MutationcreateSubpageArgs {
  input: CreateSubpageInput;
}

export interface MutationcreateTeamArgs {
  leaderId: Scalars['ID']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
}

export interface MutationcreateTextOrderArgs {
  input: CreateTextOrderInput;
}

export interface MutationcreateTextOrderGuidelineArgs {
  input: CreateTextOrderGuidelineInput;
}

export interface MutationcreateTextOrdersArgs {
  inputs: Array<CreateTextOrdersDTOInput>;
}

export interface MutationcreateTicketArgs {
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  implementationTextOrders?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownerTicketId?: InputMaybe<Scalars['ID']['input']>;
  priorityId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  relatedTickets?: InputMaybe<Array<RelatedTicketInput>>;
  sendingToClientTextOrders?: InputMaybe<Array<Scalars['ID']['input']>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  statusId: Scalars['ID']['input'];
  subtasks?: InputMaybe<Array<SubtaskInput>>;
  title: Scalars['String']['input'];
  todos?: InputMaybe<Array<TodoInput>>;
  typeId: Scalars['ID']['input'];
  watchers?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface MutationcreateTicketCommentArgs {
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  ticketId: Scalars['ID']['input'];
}

export interface MutationcreateUserArgs {
  userInput: CreateUserInput;
}

export interface MutationcreateUserSubstitutionArgs {
  input: CreateUserSubstitutionInput;
}

export interface MutationdeleteCMSItemArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteCredentialAttachmentArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteCredentialsArgs {
  id: Scalars['String']['input'];
}

export interface MutationdeleteDefaultTicketTypeArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteGAMInternalAccountArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteImageArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteLinkArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteLinkAnchorArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteLspTypeArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteMonitorArgs {
  id: Scalars['String']['input'];
}

export interface MutationdeletePhraseArgs {
  id: Scalars['String']['input'];
}

export interface MutationdeletePhraseGroupArgs {
  groupId: Scalars['String']['input'];
}

export interface MutationdeletePhrasesArgs {
  ids: Array<Scalars['String']['input']>;
}

export interface MutationdeleteProjectArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteProjectUserArgs {
  projectId: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
}

export interface MutationdeleteQuarterOverrideArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteRelatedTicketArgs {
  relatedTicketId: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
}

export interface MutationdeleteRoleArgs {
  roleId: Scalars['String']['input'];
}

export interface MutationdeleteS4sInstanceArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteSubpageArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteSubpagesArgs {
  ids: Array<Scalars['String']['input']>;
}

export interface MutationdeleteTeamArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTextOrdersArgs {
  ids: Array<Scalars['String']['input']>;
}

export interface MutationdeleteTicketArgs {
  ticketId: Scalars['ID']['input'];
}

export interface MutationdeleteTicketAttachmentArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketCommentArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketCommentAttachmentArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketPriorityArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketStatusArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketSubtaskArgs {
  subtaskId: Scalars['String']['input'];
  ticketId: Scalars['String']['input'];
}

export interface MutationdeleteTicketTimeArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTicketTypeArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteTodoArgs {
  id: Scalars['ID']['input'];
}

export interface MutationdeleteUserAvatarArgs {
  id: Scalars['String']['input'];
}

export interface MutationdeleteUserSubstitutionArgs {
  id: Scalars['ID']['input'];
}

export interface MutationgroupPhrasesArgs {
  groupId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
}

export interface MutationimpersonateArgs {
  userId: Scalars['ID']['input'];
}

export interface MutationimportPhrasesFromGoogleSheetsArgs {
  domainId: Scalars['String']['input'];
  sheetId: Scalars['String']['input'];
}

export interface MutationimportSubpageCsvArgs {
  headerRow: Scalars['Boolean']['input'];
  headers: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface MutationimportTextOrderCsvArgs {
  headerRow: Scalars['Boolean']['input'];
  headers: Scalars['String']['input'];
  id: Scalars['ID']['input'];
}

export interface MutationimportTextOrderFromGoogleSheetsArgs {
  domainId: Scalars['String']['input'];
  range: Scalars['String']['input'];
  sheetId: Scalars['String']['input'];
}

export interface MutationlogTicketTimeArgs {
  date?: InputMaybe<Scalars['String']['input']>;
  minutes: Scalars['Int']['input'];
  ticketId: Scalars['ID']['input'];
}

export interface MutationloginUserArgs {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface MutationmarkReadNotificationArgs {
  id: Scalars['ID']['input'];
}

export interface MutationmarkUnreadNotificationArgs {
  id: Scalars['ID']['input'];
}

export interface MutationrefreshGAArgs {
  gamInternalAccountId?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationrefreshGAMAccessTokenArgs {
  id: Scalars['ID']['input'];
}

export interface MutationresetPasswordArgs {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}

export interface MutationsaveDefaultTicketTypeArgs {
  color?: InputMaybe<ColorPaletteEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  priority?: InputMaybe<Scalars['Float']['input']>;
  projectIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  value: Scalars['String']['input'];
}

export interface MutationsaveSeoNoteArgs {
  domainId: Scalars['String']['input'];
  language: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationsendResetPasswordArgs {
  email: Scalars['String']['input'];
}

export interface MutationsetPhrasesUserAssignedUrlArgs {
  ids: Array<Scalars['String']['input']>;
}

export interface MutationsetTemporaryGAAccessArgs {
  domainId: Scalars['String']['input'];
}

export interface MutationsetTicketPriorityArgs {
  id: Scalars['ID']['input'];
  priorityValue: Scalars['Float']['input'];
}

export interface MutationsetTranslationsArgs {
  translations: Array<GroupedTranslatablesInput>;
}

export interface MutationsyncProjectPrioritiesArgs {
  priorityIds: Array<Scalars['ID']['input']>;
  projectId: Scalars['ID']['input'];
}

export interface MutationsyncProjectStatusesArgs {
  projectId: Scalars['ID']['input'];
  statusIds: Array<Scalars['ID']['input']>;
}

export interface MutationsyncRolePermissionsArgs {
  permissions: Array<PermissionEnum>;
  roleId: Scalars['String']['input'];
}

export interface MutationsyncUserPermissionsArgs {
  directPermissions: Array<PermissionEnum>;
  role: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}

export interface MutationunarchiveProjectArgs {
  id: Scalars['ID']['input'];
}

export interface MutationunblockUserArgs {
  id: Scalars['ID']['input'];
}

export interface MutationungroupPhrasesArgs {
  ids: Array<Scalars['String']['input']>;
}

export interface MutationunwatchTicketArgs {
  ticketId: Scalars['ID']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface MutationupdateCMSItemArgs {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
}

export interface MutationupdateCredentialsArgs {
  comment?: InputMaybe<Scalars['String']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  login?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateDealArgs {
  activationAmount?: InputMaybe<Scalars['Float']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  analyticsMonthEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  communicationLanguage?: InputMaybe<DomainLanguageEnum>;
  contentQuarterly?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  diversificationLinksCount?: InputMaybe<Scalars['Int']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  extraData?: InputMaybe<DealExtraDataDTOInput>;
  id: Scalars['ID']['input'];
  indefinite?: InputMaybe<Scalars['Boolean']['input']>;
  ipressMonthly?: InputMaybe<Scalars['Int']['input']>;
  isLatest?: InputMaybe<Scalars['Boolean']['input']>;
  linkBuildingBudget?: InputMaybe<Scalars['Float']['input']>;
  lspTypeId?: InputMaybe<Scalars['String']['input']>;
  maxBudget?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  origRealStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  origStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  previousDealId?: InputMaybe<Scalars['ID']['input']>;
  product?: InputMaybe<DealCrmProductEnum>;
  realStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  sponsoredArticlesCount?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<DealStatusEnum>;
  suspensionDate?: InputMaybe<Scalars['String']['input']>;
  terminationShouldBeSaved?: InputMaybe<Scalars['Boolean']['input']>;
  textReductionPercent?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<DealTypeEnum>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateDomainArgs {
  adminUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  adsComment?: InputMaybe<Scalars['String']['input']>;
  briefCopy?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<CategoryEnum>>;
  cms?: InputMaybe<Scalars['String']['input']>;
  commentCopy?: InputMaybe<Scalars['String']['input']>;
  contentImportance?: InputMaybe<ContentImportanceEnum>;
  contentMarketingSpreadsheet?: InputMaybe<Scalars['String']['input']>;
  countableTexts?: InputMaybe<Array<LinkGroupEnum>>;
  country?: InputMaybe<CountryEnum>;
  csComment?: InputMaybe<Scalars['String']['input']>;
  diversificationLinkbuildingStrategy?: InputMaybe<LinkbuildingStrategyEnum>;
  fulfillmentUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fullUrl?: InputMaybe<Scalars['String']['input']>;
  fullUrlLocked?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  language?: InputMaybe<LanguageCodeEnum>;
  linkBuildingDiversificationSpreadsheet?: InputMaybe<Scalars['String']['input']>;
  linkbuildingStrategy?: InputMaybe<LinkbuildingStrategyEnum>;
  maxPhrases?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  positionedDomain?: InputMaybe<Scalars['String']['input']>;
  relatedDomainsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  s4sInstanceId?: InputMaybe<Scalars['String']['input']>;
  salesUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  score?: InputMaybe<Scalars['Int']['input']>;
  scoreSpreadsheet?: InputMaybe<Scalars['String']['input']>;
  semContactUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  semFulfillmentUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  seoComment?: InputMaybe<Scalars['String']['input']>;
  seoContactUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  serviceType?: InputMaybe<ServiceTypeEnum>;
  strategyLink?: InputMaybe<Scalars['String']['input']>;
  textToBeWrittenBy?: InputMaybe<TextWriterEnum>;
  uxUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface MutationupdateDomainAdsBudgetArgs {
  id: Scalars['String']['input'];
  input: DomainAdsBudgetInput;
}

export interface MutationupdateExternalReportSettingsArgs {
  domainExternalReportSettingsInput: DomainExternalReportSettingsInput;
  domainId: Scalars['ID']['input'];
}

export interface MutationupdateGAMInternalAccountArgs {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}

export interface MutationupdateImageArgs {
  id: Scalars['ID']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  relatedId?: InputMaybe<Scalars['ID']['input']>;
}

export interface MutationupdateImagesArgs {
  images: Array<UpdateImageDTOInput>;
}

export interface MutationupdateLinkArgs {
  anchors?: InputMaybe<Array<LinkAnchorDTOInput>>;
  articleUrl?: InputMaybe<Scalars['String']['input']>;
  characterCount?: InputMaybe<Scalars['Int']['input']>;
  copywriterId?: InputMaybe<Scalars['ID']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  group?: InputMaybe<LinkGroupEnum>;
  id: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['DateTime']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  plannedPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  portal?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  realPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<LinkStatusEnum>;
  textDeliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  textUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateLinkAnchorArgs {
  anchor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  linkId?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateLspTypeArgs {
  id: Scalars['ID']['input'];
  value: Scalars['String']['input'];
}

export interface MutationupdateMonitorArgs {
  id: Scalars['String']['input'];
  newUrl: Scalars['String']['input'];
}

export interface MutationupdateNotificationSettingArgs {
  channel: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}

export interface MutationupdatePhraseArgs {
  checkPeriod?: InputMaybe<Scalars['Int']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  excludeFromLinking?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  location?: InputMaybe<LocationEnum>;
  userAssignedUrl?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdatePhraseGroupArgs {
  groupId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phraseIds?: InputMaybe<Array<Scalars['String']['input']>>;
}

export interface MutationupdateProjectArgs {
  content?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Float']['input']>;
}

export interface MutationupdateQuarterOverrideArgs {
  charactersToCreate?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<QuarterTypeEnum>;
}

export interface MutationupdateRoleArgs {
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateS4sInstanceArgs {
  db?: InputMaybe<Scalars['String']['input']>;
  host?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  port?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateSubpageArgs {
  id: Scalars['ID']['input'];
  input: UpdateSubpageInput;
}

export interface MutationupdateTeamArgs {
  id: Scalars['ID']['input'];
  leaderId?: InputMaybe<Scalars['ID']['input']>;
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateTextOrderArgs {
  input: UpdateTextOrderInput;
}

export interface MutationupdateTextOrderGuidelineArgs {
  input: UpdateTextOrderGuidelineInput;
}

export interface MutationupdateTextOrdersArgs {
  inputs: Array<UpdateTextOrdersDTOInput>;
}

export interface MutationupdateTicketArgs {
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  deadline?: InputMaybe<Scalars['DateTime']['input']>;
  domainId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  priorityId?: InputMaybe<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  statusId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
}

export interface MutationupdateTicketCommentArgs {
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
}

export interface MutationupdateTicketPriorityArgs {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateTicketStatusArgs {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  treatAsClosed?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  visibleInKanban?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationupdateTicketTimeArgs {
  id: Scalars['ID']['input'];
  minutes: Scalars['Int']['input'];
}

export interface MutationupdateTicketTypeArgs {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  color?: InputMaybe<ColorPaletteEnum>;
  id: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateTodoArgs {
  id: Scalars['ID']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TodoStatusEnum>;
  text?: InputMaybe<Scalars['String']['input']>;
}

export interface MutationupdateUserArgs {
  id: Scalars['ID']['input'];
  userInput: UpdateUserInput;
}

export interface MutationupdateUserSubstitutionArgs {
  id: Scalars['ID']['input'];
  input: UpdateUserSubstitutionInput;
}

export interface MutationwatchTicketArgs {
  ticketId: Scalars['ID']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface Name {
  __typename?: 'Name';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface NameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface NoBlockedFilter {
  __typename?: 'NoBlockedFilter';
  value: Scalars['Boolean']['output'];
}

export interface NoBlockedFilterInput {
  value: Scalars['Boolean']['input'];
}

export interface Notification {
  __typename?: 'Notification';
  created_at: Scalars['String']['output'];
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  owner: User;
  read_at?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
}

export interface NotificationGridFilterInput {
  date?: InputMaybe<DateInput>;
  read?: InputMaybe<ReadInput>;
  type?: InputMaybe<NotificationTypeInput>;
}

export interface NotificationGridInput {
  filter?: InputMaybe<NotificationGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface NotificationType {
  __typename?: 'NotificationType';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface NotificationTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface NotificationsResponse {
  __typename?: 'NotificationsResponse';
  rows: Array<Notification>;
  total: Scalars['Int']['output'];
  total_unread: Scalars['Int']['output'];
}

export interface ObjectsResponse {
  __typename?: 'ObjectsResponse';
  id: Scalars['String']['output'];
  priority: Array<Priority>;
  project: Project;
  status: Array<Status>;
  type: Array<Type>;
}

export interface OnlyCreated {
  __typename?: 'OnlyCreated';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface OnlyCreatedInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export enum OperatorEnum {
  EQ = 'EQ',
  GT = 'GT',
  GTE = 'GTE',
  IN = 'IN',
  LIKE = 'LIKE',
  LT = 'LT',
  LTE = 'LTE',
  NEQ = 'NEQ',
  RANGE = 'RANGE',
}

export interface OrderType {
  __typename?: 'OrderType';
  operator: OperatorEnum;
  value: Array<OrderTypeEnum>;
}

export enum OrderTypeEnum {
  NEW_TEXT = 'NEW_TEXT',
  TEXT_EXTENSION = 'TEXT_EXTENSION',
  TEXT_REDRAFT = 'TEXT_REDRAFT',
}

export interface OrderTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<OrderTypeEnum>;
}

export interface OrdererIds {
  __typename?: 'OrdererIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface OrdererIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface OwnerUserId {
  __typename?: 'OwnerUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface OwnerUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Permission {
  __typename?: 'Permission';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export enum PermissionEnum {
  ARCHIVE_PROJECTS = 'ARCHIVE_PROJECTS',
  ASSIGN_ROLES = 'ASSIGN_ROLES',
  BLOCK_USERS = 'BLOCK_USERS',
  CREATE_CMS = 'CREATE_CMS',
  CREATE_COMPANIES = 'CREATE_COMPANIES',
  CREATE_CONTACTS = 'CREATE_CONTACTS',
  CREATE_CREDENTIALS = 'CREATE_CREDENTIALS',
  CREATE_DEALS = 'CREATE_DEALS',
  CREATE_ENUMS = 'CREATE_ENUMS',
  CREATE_GA_INTERNAL_ACCOUNTS = 'CREATE_GA_INTERNAL_ACCOUNTS',
  CREATE_LINKS = 'CREATE_LINKS',
  CREATE_MONITORS = 'CREATE_MONITORS',
  CREATE_PHRASES = 'CREATE_PHRASES',
  CREATE_S4S_INSTANCES = 'CREATE_S4S_INSTANCES',
  CREATE_SERVICES = 'CREATE_SERVICES',
  CREATE_SUBPAGES = 'CREATE_SUBPAGES',
  CREATE_TEAMS = 'CREATE_TEAMS',
  CREATE_TEXT_ORDERS = 'CREATE_TEXT_ORDERS',
  CREATE_TICKETS = 'CREATE_TICKETS',
  CREATE_USERS = 'CREATE_USERS',
  CREATE_USER_SUBSTITUTION = 'CREATE_USER_SUBSTITUTION',
  DELETE_CMS = 'DELETE_CMS',
  DELETE_COMPANIES = 'DELETE_COMPANIES',
  DELETE_CONTACTS = 'DELETE_CONTACTS',
  DELETE_CREDENTIALS = 'DELETE_CREDENTIALS',
  DELETE_ENUMS = 'DELETE_ENUMS',
  DELETE_GA_INTERNAL_ACCOUNTS = 'DELETE_GA_INTERNAL_ACCOUNTS',
  DELETE_LINKS = 'DELETE_LINKS',
  DELETE_MONITORS = 'DELETE_MONITORS',
  DELETE_PHRASES = 'DELETE_PHRASES',
  DELETE_S4S_INSTANCES = 'DELETE_S4S_INSTANCES',
  DELETE_SERVICES = 'DELETE_SERVICES',
  DELETE_SUBPAGES = 'DELETE_SUBPAGES',
  DELETE_TEAMS = 'DELETE_TEAMS',
  DELETE_TEXT_ORDERS = 'DELETE_TEXT_ORDERS',
  DELETE_TICKETS = 'DELETE_TICKETS',
  DELETE_TICKET_COMMENTS = 'DELETE_TICKET_COMMENTS',
  DELETE_TICKET_TIME = 'DELETE_TICKET_TIME',
  DELETE_USER_SUBSTITUTION = 'DELETE_USER_SUBSTITUTION',
  EDIT_DEALS = 'EDIT_DEALS',
  EDIT_DOMAINS = 'EDIT_DOMAINS',
  EDIT_TICKET_COMMENTS = 'EDIT_TICKET_COMMENTS',
  EDIT_TICKET_TIME = 'EDIT_TICKET_TIME',
  FEATURE_PREVIEW = 'FEATURE_PREVIEW',
  GROUP_PHRASES = 'GROUP_PHRASES',
  IMPERSONATE_USERS = 'IMPERSONATE_USERS',
  LIST_BLOCKED_USERS = 'LIST_BLOCKED_USERS',
  LIST_COMPANIES = 'LIST_COMPANIES',
  LIST_CONTACTS = 'LIST_CONTACTS',
  LIST_DEALS = 'LIST_DEALS',
  LIST_DOMAINS = 'LIST_DOMAINS',
  LIST_DOMAIN_NAMES = 'LIST_DOMAIN_NAMES',
  LIST_GA_ACCOUNTS = 'LIST_GA_ACCOUNTS',
  LIST_GA_INTERNAL_ACCOUNTS = 'LIST_GA_INTERNAL_ACCOUNTS',
  LIST_LINKS = 'LIST_LINKS',
  LIST_MONITORS = 'LIST_MONITORS',
  LIST_PHRASES = 'LIST_PHRASES',
  LIST_S4S_INSTANCES = 'LIST_S4S_INSTANCES',
  LIST_SERVICES = 'LIST_SERVICES',
  LIST_SUBPAGES = 'LIST_SUBPAGES',
  LIST_TEAMS = 'LIST_TEAMS',
  LIST_TEXT_ORDERS = 'LIST_TEXT_ORDERS',
  LIST_TICKETS = 'LIST_TICKETS',
  LIST_TICKETS_WITH_DOMAIN = 'LIST_TICKETS_WITH_DOMAIN',
  LIST_USER_ACTIVITIES = 'LIST_USER_ACTIVITIES',
  LIST_USER_SUBSTITUTIONS = 'LIST_USER_SUBSTITUTIONS',
  MANAGE_ADMINS = 'MANAGE_ADMINS',
  MANAGE_DOMAINS = 'MANAGE_DOMAINS',
  MANAGE_DOMAIN_USERS = 'MANAGE_DOMAIN_USERS',
  MANAGE_META_TICKETS = 'MANAGE_META_TICKETS',
  MANAGE_PROJECTS = 'MANAGE_PROJECTS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_TRANSLATIONS = 'MANAGE_TRANSLATIONS',
  REFRESH_GA_INTERNAL_ACCOUNTS_TOKEN = 'REFRESH_GA_INTERNAL_ACCOUNTS_TOKEN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RETRIEVE_CREDENTIALS = 'RETRIEVE_CREDENTIALS',
  SHOW_TEAM_STATS = 'SHOW_TEAM_STATS',
  TEMPORARY_GA_ACCESS = 'TEMPORARY_GA_ACCESS',
  UPDATE_ADS_BUDGETS = 'UPDATE_ADS_BUDGETS',
  UPDATE_CMS = 'UPDATE_CMS',
  UPDATE_COMPANIES = 'UPDATE_COMPANIES',
  UPDATE_CONTACTS = 'UPDATE_CONTACTS',
  UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS',
  UPDATE_ENUMS = 'UPDATE_ENUMS',
  UPDATE_GA_INTERNAL_ACCOUNTS = 'UPDATE_GA_INTERNAL_ACCOUNTS',
  UPDATE_LINKS = 'UPDATE_LINKS',
  UPDATE_MONITORS = 'UPDATE_MONITORS',
  UPDATE_PHRASES = 'UPDATE_PHRASES',
  UPDATE_S4S_INSTANCES = 'UPDATE_S4S_INSTANCES',
  UPDATE_SERVICES = 'UPDATE_SERVICES',
  UPDATE_SUBPAGES = 'UPDATE_SUBPAGES',
  UPDATE_TEAMS = 'UPDATE_TEAMS',
  UPDATE_TEXT_ORDERS = 'UPDATE_TEXT_ORDERS',
  UPDATE_TICKETS = 'UPDATE_TICKETS',
  UPDATE_USERS = 'UPDATE_USERS',
  UPDATE_USER_SUBSTITUTION = 'UPDATE_USER_SUBSTITUTION',
}

export interface PermissionsField {
  __typename?: 'PermissionsField';
  value: Array<Scalars['String']['output']>;
}

export interface PermissionsFieldInput {
  value: Array<PermissionEnum>;
}

export interface PermissionsResponse {
  __typename?: 'PermissionsResponse';
  id: Scalars['String']['output'];
  key?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

export interface Phrase {
  __typename?: 'Phrase';
  check_period: Scalars['Int']['output'];
  created_at: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Domain>;
  exclude_from_linking: Scalars['Boolean']['output'];
  group?: Maybe<PhraseGroup>;
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['Int']['output']>;
  is_paused: Scalars['Boolean']['output'];
  last_check?: Maybe<Scalars['String']['output']>;
  last_position_url?: Maybe<Scalars['String']['output']>;
  location: LocationEnum;
  location_key?: Maybe<Scalars['String']['output']>;
  monthAvgPositions?: Maybe<Array<PhraseMonthAvgPosition>>;
  positions?: Maybe<Array<PhrasePosition>>;
  positionsOrdered?: Maybe<Array<PhrasePositionOrdered>>;
  search?: Maybe<PhraseSearch>;
  start_position?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_assigned_url?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

export interface PhraseDTO {
  __typename?: 'PhraseDTO';
  check_period?: Maybe<Scalars['Int']['output']>;
  domain_id?: Maybe<Scalars['String']['output']>;
  group_id?: Maybe<Scalars['String']['output']>;
  location?: Maybe<LocationEnum>;
  user_assigned_url?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
}

export interface PhraseDTOInput {
  checkPeriod?: InputMaybe<Scalars['Int']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationEnum>;
  userAssignedUrl?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
}

export interface PhraseDomainId {
  __typename?: 'PhraseDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface PhraseDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface PhraseDomainName {
  __typename?: 'PhraseDomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface PhraseDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface PhraseGridFilterInput {
  dateRange?: InputMaybe<DateRangeInput>;
  domainId?: InputMaybe<PhraseDomainIdInput>;
  domainName?: InputMaybe<PhraseDomainNameInput>;
}

export interface PhraseGridInput {
  filter?: InputMaybe<PhraseGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface PhraseGroup {
  __typename?: 'PhraseGroup';
  domain_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface PhraseMonthAvgPosition {
  __typename?: 'PhraseMonthAvgPosition';
  date: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
}

export interface PhrasePosition {
  __typename?: 'PhrasePosition';
  created_at?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  phrase_id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  positionDifference?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PhrasePositionOrdered {
  __typename?: 'PhrasePositionOrdered';
  date: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  positionDifference?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface PhraseSearch {
  __typename?: 'PhraseSearch';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  last_check?: Maybe<Scalars['String']['output']>;
  phrase: Phrase;
  value: Scalars['String']['output'];
}

export interface PhrasesResponse {
  __typename?: 'PhrasesResponse';
  rows: Array<Phrase>;
  total: Scalars['Int']['output'];
}

export interface PlannedPublicationDateRange {
  __typename?: 'PlannedPublicationDateRange';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface PlannedPublicationDateRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface Priority {
  __typename?: 'Priority';
  archived: Scalars['Boolean']['output'];
  color?: Maybe<ColorPaletteEnum>;
  color_value?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
}

export interface PriorityId {
  __typename?: 'PriorityId';
  value: Array<Scalars['ID']['output']>;
}

export interface PriorityIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Project {
  __typename?: 'Project';
  archived: Scalars['Boolean']['output'];
  content?: Maybe<Scalars['String']['output']>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priorities: Array<Priority>;
  priority?: Maybe<Scalars['String']['output']>;
  statuses: Array<Status>;
  types: Array<Type>;
  users?: Maybe<Array<User>>;
}

export interface ProjectId {
  __typename?: 'ProjectId';
  value: Array<Scalars['ID']['output']>;
}

export interface ProjectIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Quarter {
  __typename?: 'Quarter';
  characters_to_create: Scalars['Int']['output'];
  copywritingTickets?: Maybe<Array<Ticket>>;
  created_characters: Scalars['Int']['output'];
  displayed_number: Scalars['String']['output'];
  domain: Domain;
  end_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['Int']['output'];
  quarterOverride?: Maybe<QuarterOverride>;
  start_date: Scalars['String']['output'];
  summary?: Maybe<QuarterSummaryDTO>;
  tickets?: Maybe<Array<Ticket>>;
  type: QuarterTypeEnum;
  type_name?: Maybe<Scalars['String']['output']>;
}

export interface QuarterOverride {
  __typename?: 'QuarterOverride';
  characters_to_create: Scalars['Int']['output'];
  domain: Domain;
  end_date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['Int']['output'];
  start_date: Scalars['String']['output'];
  type: QuarterTypeEnum;
  type_name?: Maybe<Scalars['String']['output']>;
}

export interface QuarterSummaryDTO {
  __typename?: 'QuarterSummaryDTO';
  accepted: Scalars['Int']['output'];
  article: Scalars['Int']['output'];
  category_description: Scalars['Int']['output'];
  done: Scalars['Int']['output'];
  faq: Scalars['Int']['output'];
  implemented: Scalars['Int']['output'];
  meta_description: Scalars['Int']['output'];
  ordered: Scalars['Int']['output'];
  product_description: Scalars['Int']['output'];
  to_be_corrected: Scalars['Int']['output'];
  wrong: Scalars['Int']['output'];
}

export enum QuarterTypeEnum {
  ANALYTICAL = 'ANALYTICAL',
  FUTURE = 'FUTURE',
  NORMAL = 'NORMAL',
  TERMINATION_COMPLETION = 'TERMINATION_COMPLETION',
  ZERO = 'ZERO',
}

export interface Query {
  __typename?: 'Query';
  activeUser?: Maybe<User>;
  colorPalette: Array<EnumResponse>;
  communicationLanguages: Array<EnumTranslationResponse>;
  companiesExternalReport: Array<EnumResponse>;
  contentImportancePriorities: Array<EnumTranslationResponse>;
  countries: Array<EnumTranslationResponse>;
  credential: Credential;
  credentials: Array<Credential>;
  currencies: Array<EnumResponse>;
  deal: Deal;
  dealBaskets: Array<EnumResponse>;
  dealDiscount: DealDiscount;
  dealDiscountUnits: Array<EnumResponse>;
  dealProducts: Array<EnumTranslationResponse>;
  dealStatuses: Array<EnumTranslationWithDescriptionResponse>;
  dealTermination: DealTermination;
  dealTypes: Array<EnumTranslationResponse>;
  deals: DealsResponse;
  deletedTicketComments: Array<TicketComment>;
  deletedTickets: Array<Ticket>;
  directUserPermissions: Array<Scalars['String']['output']>;
  domain: Domain;
  domainActivities: DomainActivitiesResponse;
  domainAdsBudgets: Array<DomainAdsBudget>;
  domainAdsDeposits: Array<DomainAdsDeposit>;
  domainCategories: Array<EnumTranslationResponse>;
  domainCms: Array<Cms>;
  domainContactTypes: Array<EnumTranslationResponse>;
  domainContacts: Array<DomainContacts>;
  domainNames: DomainNamesResponse;
  domainReport: DomainReport;
  domainReportFields: Array<EnumResponse>;
  domainReportPossibleValues: Array<EnumTranslationResponse>;
  domainReportType: Array<EnumTranslationResponse>;
  domainReports: DomainReportsResponse;
  domainServiceTypes: Array<EnumTranslationResponse>;
  domains: DomainsResponse;
  domainsContacts: DomainContactsResponse;
  domainsControl: DomainsControlResponse;
  eventTypes: Array<Scalars['String']['output']>;
  externalReportSettings: DomainExternalReportSettings;
  externalReports: Array<ExternalReportDTO>;
  gamGAAccounts: GAMGAAccountResponse;
  gamGAService: Array<GAMGAService>;
  gamGSC: Array<GAMSearchConsoleSite>;
  gamInternalAccounts: GAMInternalAccountResponse;
  groupedPermissions: Array<GroupedPermissionsResponse>;
  groupedPhrases: Array<GroupedPhrases>;
  languageCodes: Array<EnumTranslationResponse>;
  languagesExternalReport: Array<EnumTranslationResponse>;
  link: Link;
  linkGroups: Array<EnumTranslationResponse>;
  linkStatuses: Array<EnumTranslationResponse>;
  linkbuildingStrategies: Array<EnumTranslationResponse>;
  links: LinksResponse;
  locales: Array<EnumTranslationResponse>;
  locations: Array<EnumTranslationResponse>;
  lspTypes: Array<LspType>;
  monitor: Monitor;
  monitorLimits: MonitorLimitsResponse;
  monitorLogs: Array<MonitorLogDTO>;
  monitors: MonitorsResponse;
  notificationGroups: Array<EnumTranslationResponse>;
  notificationSettings: Array<SettingGroup>;
  permissions: Array<PermissionsResponse>;
  phraseGroups: Array<PhraseGroup>;
  phrases: PhrasesResponse;
  project: Project;
  projects: Array<Project>;
  quarter: Quarter;
  quarterOverride: QuarterOverride;
  quarterTypes: Array<EnumTranslationResponse>;
  recentTicketActivityStream: Array<Activity>;
  regions: Array<Scalars['String']['output']>;
  reportLanguagesExternalReport: Array<EnumResponse>;
  roles: Array<RoleResponse>;
  s4sInstance: S4sInstance;
  s4sInstances: S4sResponse;
  singleDomainReports: DomainReportsResponse;
  subpage: Subpage;
  subpagePriorities: Array<EnumResponse>;
  subpageStatuses: Array<EnumResponse>;
  subpageTypes: Array<EnumResponse>;
  subpages: SubpagesResponse;
  team: Team;
  teamStatistics: TeamStatisticsResponse;
  teams: TeamsResponse;
  templatesExternalReport: Array<EnumResponse>;
  temporaryGAAccessTimeRemaining?: Maybe<Scalars['String']['output']>;
  textOrder: TextOrder;
  textOrderOrderTypes: Array<EnumTranslationResponse>;
  textOrderPriorities: Array<EnumTranslationResponse>;
  textOrderStatuses: Array<EnumTranslationResponse>;
  textOrderTextTypes: Array<EnumTranslationResponse>;
  textOrders: TextOrdersResponse;
  textOrdersStatistics: TextOrdersStatisticsResponse;
  textWriters: Array<EnumTranslationResponse>;
  ticket: Ticket;
  ticketActivities: TicketActivitiesResponse;
  ticketActivityStream: Array<TicketActivityResponse>;
  ticketComments: CommentsResponse;
  ticketPriorities: Array<Priority>;
  ticketRelationType: Array<EnumTranslationResponse>;
  ticketStatuses: Array<Status>;
  ticketTimes: Array<TimeLog>;
  ticketTodoTypes: Array<EnumTranslationResponse>;
  ticketTodos: Array<TicketTodo>;
  ticketTypes: Array<Type>;
  ticketWatchers: Array<User>;
  tickets: TicketsResponse;
  timeLogs: TimeLogResponse;
  translatables: Array<TranslatablesResponse>;
  user: User;
  userActivityStream: Array<ActivityResponse>;
  userNotifications: NotificationsResponse;
  userPositions: Array<EnumTranslationResponse>;
  userStatistics: UserStatisticsResponse;
  userSubstitution: UserSubstitution;
  userSubstitutions: UserSubstitutionsResponse;
  userWorkplaces: Array<EnumTranslationResponse>;
  users: UsersResponse;
}

export interface QuerycredentialArgs {
  id: Scalars['ID']['input'];
}

export interface QuerycredentialsArgs {
  domainId: Scalars['String']['input'];
}

export interface QuerydealArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydealDiscountArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydealTerminationArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydealsArgs {
  grid?: InputMaybe<DealGridInput>;
}

export interface QuerydirectUserPermissionsArgs {
  id: Scalars['String']['input'];
}

export interface QuerydomainArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydomainActivitiesArgs {
  grid?: InputMaybe<DomainActivitiesGridInput>;
}

export interface QuerydomainAdsBudgetsArgs {
  domainId: Scalars['ID']['input'];
}

export interface QuerydomainAdsDepositsArgs {
  domainId: Scalars['ID']['input'];
}

export interface QuerydomainContactsArgs {
  domainId: Scalars['ID']['input'];
}

export interface QuerydomainNamesArgs {
  grid?: InputMaybe<DomainNamesGridInput>;
}

export interface QuerydomainReportArgs {
  id: Scalars['ID']['input'];
}

export interface QuerydomainReportFieldsArgs {
  reportType: Scalars['String']['input'];
}

export interface QuerydomainReportsArgs {
  grid?: InputMaybe<DomainReportGridInput>;
}

export interface QuerydomainsArgs {
  grid?: InputMaybe<DomainGridInput>;
}

export interface QuerydomainsContactsArgs {
  grid?: InputMaybe<DomainContactsGridInput>;
}

export interface QuerydomainsControlArgs {
  grid?: InputMaybe<DomainControlGridInput>;
}

export interface QueryexternalReportSettingsArgs {
  domainId: Scalars['ID']['input'];
}

export interface QueryexternalReportsArgs {
  domainId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QuerygamGAAccountsArgs {
  grid?: InputMaybe<GAMGAAccountGridInput>;
}

export interface QuerygamGAServiceArgs {
  domainId: Scalars['ID']['input'];
}

export interface QuerygamGSCArgs {
  domainId: Scalars['ID']['input'];
}

export interface QuerygamInternalAccountsArgs {
  grid?: InputMaybe<GAMInternalAccountGridInput>;
}

export interface QuerygroupedPhrasesArgs {
  grid?: InputMaybe<GroupedPhraseGridInput>;
}

export interface QuerylinkArgs {
  id: Scalars['ID']['input'];
}

export interface QuerylinksArgs {
  grid?: InputMaybe<LinkGridInput>;
}

export interface QuerymonitorArgs {
  id: Scalars['String']['input'];
}

export interface QuerymonitorLogsArgs {
  days?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
}

export interface QuerymonitorsArgs {
  grid?: InputMaybe<MonitorGridInput>;
}

export interface QueryphraseGroupsArgs {
  domainId: Scalars['String']['input'];
}

export interface QueryphrasesArgs {
  grid?: InputMaybe<PhraseGridInput>;
}

export interface QueryprojectArgs {
  id: Scalars['ID']['input'];
}

export interface QueryquarterArgs {
  id: Scalars['ID']['input'];
}

export interface QueryquarterOverrideArgs {
  id: Scalars['ID']['input'];
}

export interface QueryrecentTicketActivityStreamArgs {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryregionsArgs {
  country: Scalars['String']['input'];
}

export interface Querys4sInstanceArgs {
  id: Scalars['ID']['input'];
}

export interface Querys4sInstancesArgs {
  grid?: InputMaybe<S4sGridInput>;
}

export interface QuerysingleDomainReportsArgs {
  domainId: Scalars['ID']['input'];
  grid?: InputMaybe<DomainReportGridInput>;
}

export interface QuerysubpageArgs {
  id: Scalars['ID']['input'];
}

export interface QuerysubpagesArgs {
  grid?: InputMaybe<SubpageGridInput>;
}

export interface QueryteamArgs {
  id: Scalars['ID']['input'];
}

export interface QueryteamStatisticsArgs {
  id: Scalars['ID']['input'];
}

export interface QueryteamsArgs {
  grid?: InputMaybe<TeamGridInput>;
}

export interface QuerytemporaryGAAccessTimeRemainingArgs {
  domainId: Scalars['String']['input'];
}

export interface QuerytextOrderArgs {
  id: Scalars['ID']['input'];
}

export interface QuerytextOrdersArgs {
  grid?: InputMaybe<TextOrderGridInput>;
}

export interface QuerytextOrdersStatisticsArgs {
  grid?: InputMaybe<TextOrderStatisticsGridInput>;
}

export interface QueryticketArgs {
  id: Scalars['ID']['input'];
}

export interface QueryticketActivitiesArgs {
  grid?: InputMaybe<TicketActivitiesGridInput>;
}

export interface QueryticketActivityStreamArgs {
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface QueryticketCommentsArgs {
  grid?: InputMaybe<TicketCommentsGridInput>;
}

export interface QueryticketPrioritiesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QueryticketStatusesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QueryticketTimesArgs {
  ticketId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QueryticketTodosArgs {
  ticketId: Scalars['ID']['input'];
}

export interface QueryticketTypesArgs {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
}

export interface QueryticketWatchersArgs {
  id: Scalars['ID']['input'];
}

export interface QueryticketsArgs {
  forSearch?: InputMaybe<Scalars['Boolean']['input']>;
  grid?: InputMaybe<TicketGridInput>;
}

export interface QuerytimeLogsArgs {
  grid?: InputMaybe<TimeLogGridInput>;
}

export interface QueryuserArgs {
  id: Scalars['ID']['input'];
}

export interface QueryuserActivityStreamArgs {
  date?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface QueryuserNotificationsArgs {
  grid?: InputMaybe<NotificationGridInput>;
}

export interface QueryuserStatisticsArgs {
  id: Scalars['ID']['input'];
}

export interface QueryuserSubstitutionArgs {
  id: Scalars['ID']['input'];
}

export interface QueryuserSubstitutionsArgs {
  grid?: InputMaybe<UserSubstitutionGridInput>;
}

export interface QueryusersArgs {
  grid?: InputMaybe<UserGridInput>;
}

export interface Read {
  __typename?: 'Read';
  value?: Maybe<Scalars['Boolean']['output']>;
}

export interface ReadInput {
  value?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface RelatedDomainIds {
  __typename?: 'RelatedDomainIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface RelatedDomainIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface RelatedTicket {
  __typename?: 'RelatedTicket';
  related_ticket_id: Scalars['String']['output'];
  relation_type: Scalars['String']['output'];
}

export interface RelatedTicketInput {
  relatedTicketId: Scalars['String']['input'];
  relationType: Scalars['String']['input'];
}

export enum RelationTypeEnum {
  RELATED = 'RELATED',
  SUBTASK = 'SUBTASK',
}

export interface Role {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface RoleField {
  __typename?: 'RoleField';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface RoleFieldInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface RoleResponse {
  __typename?: 'RoleResponse';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
}

export interface S4sGridFilterInput {
  name?: InputMaybe<S4sInstanceNameInput>;
}

export interface S4sGridInput {
  filter?: InputMaybe<S4sGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface S4sInstance {
  __typename?: 'S4sInstance';
  db: Scalars['String']['output'];
  domains?: Maybe<Array<Domain>>;
  host: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  phrases?: Maybe<Scalars['Int']['output']>;
  port: Scalars['String']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
}

export interface S4sInstanceName {
  __typename?: 'S4sInstanceName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface S4sInstanceNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface S4sResponse {
  __typename?: 'S4sResponse';
  rows: Array<S4sInstance>;
  total: Scalars['Int']['output'];
}

export interface S4sSite {
  __typename?: 'S4sSite';
  domain?: Maybe<Domain>;
  site_id?: Maybe<Scalars['String']['output']>;
}

export interface SaleUserIds {
  __typename?: 'SaleUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SaleUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SemContactUserIds {
  __typename?: 'SemContactUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SemContactUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SemFulfillmentUserIds {
  __typename?: 'SemFulfillmentUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SemFulfillmentUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SeoContactUserIds {
  __typename?: 'SeoContactUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SeoContactUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SeoNoteDTO {
  __typename?: 'SeoNoteDTO';
  note?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
}

export enum ServiceTypeEnum {
  ECOMMERCE = 'ECOMMERCE',
  SERVICE_SITE = 'SERVICE_SITE',
}

export interface Setting {
  __typename?: 'Setting';
  channel: Scalars['String']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
}

export interface SettingGroup {
  __typename?: 'SettingGroup';
  group: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  settings: Array<Setting>;
}

export enum SortDirEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortTypeInput {
  dir: SortDirEnum;
  field: Scalars['String']['input'];
}

export interface SponsoredArticlesCount {
  __typename?: 'SponsoredArticlesCount';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SponsoredArticlesCountInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Start {
  __typename?: 'Start';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface StartInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface Status {
  __typename?: 'Status';
  archived: Scalars['Boolean']['output'];
  color?: Maybe<ColorPaletteEnum>;
  color_value?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  treat_as_closed: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
  visible_in_kanban: Scalars['Boolean']['output'];
}

export interface StatusId {
  __typename?: 'StatusId';
  value: Array<Scalars['ID']['output']>;
}

export interface StatusIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Subpage {
  __typename?: 'Subpage';
  character_count?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  domain: Domain;
  heading?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  priority?: Maybe<SubpagePriorityEnum>;
  status?: Maybe<SubpageStatusEnum>;
  target_character_count?: Maybe<Scalars['String']['output']>;
  target_description?: Maybe<Scalars['String']['output']>;
  target_heading?: Maybe<Scalars['String']['output']>;
  target_title?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SubpageTypeEnum>;
  url: Scalars['String']['output'];
}

export interface SubpageAclIds {
  __typename?: 'SubpageAclIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SubpageCsv {
  __typename?: 'SubpageCsv';
  domain: Domain;
  id: Scalars['ID']['output'];
}

export interface SubpageDomainId {
  __typename?: 'SubpageDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SubpageDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SubpageFullText {
  __typename?: 'SubpageFullText';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SubpageFullTextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface SubpageGridFilterInput {
  domainId?: InputMaybe<SubpageDomainIdInput>;
  fulltext?: InputMaybe<SubpageFullTextInput>;
  priority?: InputMaybe<SubpagePriorityInput>;
  status?: InputMaybe<SubpageStatusInput>;
  type?: InputMaybe<SubpageTypeInput>;
}

export interface SubpageGridInput {
  filter?: InputMaybe<SubpageGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface SubpagePriority {
  __typename?: 'SubpagePriority';
  operator: OperatorEnum;
  value: Array<SubpagePriorityEnum>;
}

export enum SubpagePriorityEnum {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
}

export interface SubpagePriorityInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<SubpagePriorityEnum>;
}

export interface SubpageStatus {
  __typename?: 'SubpageStatus';
  operator: OperatorEnum;
  value: Array<SubpageStatusEnum>;
}

export enum SubpageStatusEnum {
  EXISTS = 'EXISTS',
  PLANNED = 'PLANNED',
  REMOVED = 'REMOVED',
}

export interface SubpageStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<SubpageStatusEnum>;
}

export interface SubpageType {
  __typename?: 'SubpageType';
  operator: OperatorEnum;
  value: Array<SubpageTypeEnum>;
}

export enum SubpageTypeEnum {
  BLOG = 'BLOG',
  CATEGORY = 'CATEGORY',
  PAGE = 'PAGE',
  PRODUCT = 'PRODUCT',
}

export interface SubpageTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<SubpageTypeEnum>;
}

export interface SubpagesResponse {
  __typename?: 'SubpagesResponse';
  rows: Array<Subpage>;
  total: Scalars['Int']['output'];
}

export interface SubstituteUserId {
  __typename?: 'SubstituteUserId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface SubstituteUserIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Subtask {
  __typename?: 'Subtask';
  priority_id: Scalars['String']['output'];
  project_id: Scalars['String']['output'];
  status_id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type_id: Scalars['String']['output'];
}

export interface SubtaskInput {
  priorityId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  statusId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  typeId: Scalars['String']['input'];
}

export interface SyncPermissionsResponse {
  __typename?: 'SyncPermissionsResponse';
  permissions?: Maybe<Array<PermissionEnum>>;
  role?: Maybe<Scalars['String']['output']>;
  user: User;
}

export interface Team {
  __typename?: 'Team';
  id: Scalars['ID']['output'];
  leader: User;
  members: Array<User>;
  name: Scalars['String']['output'];
  totalMembers: Scalars['Int']['output'];
}

export interface TeamFulltext {
  __typename?: 'TeamFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TeamFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TeamGridFilterInput {
  fulltext?: InputMaybe<TeamFulltextInput>;
}

export interface TeamGridInput {
  filter?: InputMaybe<TeamGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TeamStatisticsResponse {
  __typename?: 'TeamStatisticsResponse';
  details: Array<Details>;
  id: Scalars['String']['output'];
  totals: Totals;
}

export interface TeamsResponse {
  __typename?: 'TeamsResponse';
  rows: Array<Team>;
  total: Scalars['Int']['output'];
}

export interface TerminationDateRange {
  __typename?: 'TerminationDateRange';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TerminationDateRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrder {
  __typename?: 'TextOrder';
  comments?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  domain: Domain;
  error_message?: Maybe<Scalars['String']['output']>;
  final_text_count?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  implementationTicket?: Maybe<Ticket>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  order_type: OrderTypeEnum;
  order_type_name?: Maybe<Scalars['String']['output']>;
  orderer?: Maybe<User>;
  planned_publication_date?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<TextOrderPriorityEnum>;
  priority_name?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<TextOrderSchemaDTO>;
  sendingToClientTicket?: Maybe<Ticket>;
  status?: Maybe<TextOrderStatusEnum>;
  status_name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  text_added_at?: Maybe<Scalars['String']['output']>;
  text_score?: Maybe<Scalars['Int']['output']>;
  text_sum: Scalars['Int']['output'];
  text_to_be_written_by?: Maybe<TextWriterEnum>;
  text_to_be_written_by_name?: Maybe<Scalars['String']['output']>;
  text_type?: Maybe<TextOrderTextTypeEnum>;
  text_type_name?: Maybe<Scalars['String']['output']>;
  text_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verification_date?: Maybe<Scalars['String']['output']>;
  verification_time?: Maybe<Scalars['Int']['output']>;
  verifier?: Maybe<User>;
}

export interface TextOrderAclIds {
  __typename?: 'TextOrderAclIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderCsv {
  __typename?: 'TextOrderCsv';
  domain: Domain;
  id: Scalars['ID']['output'];
}

export interface TextOrderDomainId {
  __typename?: 'TextOrderDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderFulltext {
  __typename?: 'TextOrderFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderGridFilterInput {
  domainId?: InputMaybe<TextOrderDomainIdInput>;
  fulltext?: InputMaybe<TextOrderFulltextInput>;
  orderType?: InputMaybe<OrderTypeInput>;
  ordererIds?: InputMaybe<OrdererIdsInput>;
  plannedPublicationDateRange?: InputMaybe<PlannedPublicationDateRangeInput>;
  priority?: InputMaybe<TextOrderPriorityInput>;
  status?: InputMaybe<TextOrderStatusInput>;
  tags?: InputMaybe<TextOrderTagsInput>;
  textSumRange?: InputMaybe<TextSumRangeInput>;
  textToBeWrittenBy?: InputMaybe<TextOrderTextToBeWrittenByInput>;
  textType?: InputMaybe<TextTypeInput>;
  url?: InputMaybe<TextOrderUrlInput>;
  verificationDateRange?: InputMaybe<VerificationDateRangeInput>;
  verifierIds?: InputMaybe<VerifierIdsInput>;
}

export interface TextOrderGridInput {
  filter?: InputMaybe<TextOrderGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TextOrderGuideline {
  __typename?: 'TextOrderGuideline';
  domain: Domain;
  guidelines?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  text_type: TextOrderTextTypeEnum;
  text_type_name?: Maybe<Scalars['String']['output']>;
}

export interface TextOrderInput {
  __typename?: 'TextOrderInput';
  comments?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  finalTextCount?: Maybe<Scalars['Int']['output']>;
  implementationTicketId?: Maybe<Scalars['String']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  orderType?: Maybe<OrderTypeEnum>;
  plannedPublicationDate?: Maybe<Scalars['DateTime']['output']>;
  priority?: Maybe<TextOrderPriorityEnum>;
  schema?: Maybe<TextOrderSchemaDTO>;
  sendingToClientTicketId?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  textScore?: Maybe<Scalars['Int']['output']>;
  textToBeWrittenBy?: Maybe<TextWriterEnum>;
  textType?: Maybe<TextOrderTextTypeEnum>;
  textUrl?: Maybe<Scalars['String']['output']>;
  verificationDate?: Maybe<Scalars['DateTime']['output']>;
  verificationTime?: Maybe<Scalars['Int']['output']>;
  verifierId?: Maybe<Scalars['String']['output']>;
}

export interface TextOrderPriority {
  __typename?: 'TextOrderPriority';
  operator: OperatorEnum;
  value: Array<TextOrderPriorityEnum>;
}

export enum TextOrderPriorityEnum {
  CRITICAL = 'CRITICAL',
  NORMAL = 'NORMAL',
}

export interface TextOrderPriorityInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<TextOrderPriorityEnum>;
}

export interface TextOrderSchemaDTO {
  __typename?: 'TextOrderSchemaDTO';
  baseOnUrl?: Maybe<Scalars['String']['output']>;
  base_on_url?: Maybe<Scalars['String']['output']>;
  boldKeywords?: Maybe<Scalars['Boolean']['output']>;
  bold_keywords?: Maybe<Scalars['Boolean']['output']>;
  headers?: Maybe<Array<Scalars['String']['output']>>;
  longText?: Maybe<Scalars['Int']['output']>;
  long_text?: Maybe<Scalars['Int']['output']>;
  metaDescription?: Maybe<Scalars['Boolean']['output']>;
  meta_description?: Maybe<Scalars['Boolean']['output']>;
  shortText?: Maybe<Scalars['Int']['output']>;
  short_text?: Maybe<Scalars['Int']['output']>;
}

export interface TextOrderSchemaDTOInput {
  baseOnUrl?: InputMaybe<Scalars['String']['input']>;
  boldKeywords?: InputMaybe<Scalars['Boolean']['input']>;
  headers?: InputMaybe<Array<Scalars['String']['input']>>;
  longText?: InputMaybe<Scalars['Int']['input']>;
  metaDescription?: InputMaybe<Scalars['Boolean']['input']>;
  shortText?: InputMaybe<Scalars['Int']['input']>;
}

export interface TextOrderStatisticsDomainId {
  __typename?: 'TextOrderStatisticsDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderStatisticsDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderStatisticsDomainName {
  __typename?: 'TextOrderStatisticsDomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderStatisticsDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderStatisticsDomainStatus {
  __typename?: 'TextOrderStatisticsDomainStatus';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderStatisticsDomainStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderStatisticsGridFilterInput {
  domainId?: InputMaybe<TextOrderStatisticsDomainIdInput>;
  domainName?: InputMaybe<TextOrderStatisticsDomainNameInput>;
  domainStatus?: InputMaybe<TextOrderStatisticsDomainStatusInput>;
  status?: InputMaybe<Array<TextOrderStatisticsStatusInput>>;
}

export interface TextOrderStatisticsGridInput {
  filter?: InputMaybe<TextOrderStatisticsGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TextOrderStatisticsStatus {
  __typename?: 'TextOrderStatisticsStatus';
  from?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
  value: TextOrderStatusEnum;
}

export interface TextOrderStatisticsStatusInput {
  from?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['Int']['input']>;
  value: TextOrderStatusEnum;
}

export interface TextOrderStatus {
  __typename?: 'TextOrderStatus';
  operator: OperatorEnum;
  value: Array<TextOrderStatusEnum>;
}

export enum TextOrderStatusEnum {
  ACCEPTED_BY_CLIENT = 'ACCEPTED_BY_CLIENT',
  CANCELLED = 'CANCELLED',
  IMPLEMENTED = 'IMPLEMENTED',
  INCORRECT_ORDER = 'INCORRECT_ORDER',
  NEW = 'NEW',
  READY_TO_SEND = 'READY_TO_SEND',
  READY_TO_WRITE = 'READY_TO_WRITE',
  SENT_TO_CUSTOMER = 'SENT_TO_CUSTOMER',
  TO_BE_CORRECTED = 'TO_BE_CORRECTED',
  TO_BE_VERIFIED = 'TO_BE_VERIFIED',
}

export interface TextOrderStatusInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<TextOrderStatusEnum>;
}

export interface TextOrderTags {
  __typename?: 'TextOrderTags';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderTagsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrderTextToBeWrittenBy {
  __typename?: 'TextOrderTextToBeWrittenBy';
  operator: OperatorEnum;
  value: Array<TextWriterEnum>;
}

export interface TextOrderTextToBeWrittenByInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<TextWriterEnum>;
}

export enum TextOrderTextTypeEnum {
  ARTICLE = 'ARTICLE',
  ARTICLE_FIRST_PERSON = 'ARTICLE_FIRST_PERSON',
  CATEGORY_DESCRIPTION = 'CATEGORY_DESCRIPTION',
  FAQ = 'FAQ',
  FAQ_FIRST_PERSON = 'FAQ_FIRST_PERSON',
  META_DESCRIPTION = 'META_DESCRIPTION',
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
}

export interface TextOrderUrl {
  __typename?: 'TextOrderUrl';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextOrderUrlInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextOrdersResponse {
  __typename?: 'TextOrdersResponse';
  rows: Array<TextOrder>;
  total: Scalars['Int']['output'];
}

export interface TextOrdersStatistics {
  __typename?: 'TextOrdersStatistics';
  accepted_by_client: Scalars['Int']['output'];
  cancelled: Scalars['Int']['output'];
  domain: Domain;
  implemented: Scalars['Int']['output'];
  incorrect_order: Scalars['Int']['output'];
  new: Scalars['Int']['output'];
  ready_to_send: Scalars['Int']['output'];
  ready_to_write: Scalars['Int']['output'];
  sent_to_customer: Scalars['Int']['output'];
  to_be_corrected: Scalars['Int']['output'];
  to_be_verified: Scalars['Int']['output'];
}

export interface TextOrdersStatisticsResponse {
  __typename?: 'TextOrdersStatisticsResponse';
  statistics: Array<TextOrdersStatistics>;
  total: Scalars['Int']['output'];
}

export interface TextSumRange {
  __typename?: 'TextSumRange';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TextSumRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TextType {
  __typename?: 'TextType';
  operator: OperatorEnum;
  value: Array<TextOrderTextTypeEnum>;
}

export interface TextTypeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<TextOrderTextTypeEnum>;
}

export enum TextWriterEnum {
  AI = 'AI',
  HUMAN = 'HUMAN',
}

export interface Ticket {
  __typename?: 'Ticket';
  assigned?: Maybe<User>;
  comment?: Maybe<Array<TicketComment>>;
  content_html?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  deadline?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Domain>;
  file?: Maybe<Array<TicketFile>>;
  id: Scalars['ID']['output'];
  implementationTextOrders?: Maybe<Array<TextOrder>>;
  is_children?: Maybe<Scalars['Boolean']['output']>;
  is_subtask?: Maybe<Scalars['Boolean']['output']>;
  linkedTickets?: Maybe<Array<Ticket>>;
  mainTickets?: Maybe<Array<Ticket>>;
  owner: User;
  priority: Priority;
  priority_value?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  recentActivity: Array<Activity>;
  relatedTickets?: Maybe<Array<Ticket>>;
  relation_type?: Maybe<Scalars['String']['output']>;
  sendingToClientTextOrders?: Maybe<Array<TextOrder>>;
  sequence: Scalars['String']['output'];
  start?: Maybe<Scalars['String']['output']>;
  status: Status;
  timeLogs?: Maybe<Array<TimeLog>>;
  title: Scalars['String']['output'];
  todo?: Maybe<Array<TicketTodo>>;
  total_time_logs?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Type>;
  updatedBy?: Maybe<User>;
  updated_at?: Maybe<Scalars['String']['output']>;
  watchers: Array<User>;
}

export interface TicketAclIds {
  __typename?: 'TicketAclIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TicketActivitiesGridFilterInput {
  createdAt?: InputMaybe<ActivitiesCreatedAtInput>;
  ticketId?: InputMaybe<TicketIdInput>;
  userId?: InputMaybe<ActivitiesUserIdInput>;
}

export interface TicketActivitiesGridInput {
  filter?: InputMaybe<TicketActivitiesGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketActivitiesResponse {
  __typename?: 'TicketActivitiesResponse';
  rows: Array<Activity>;
  total: Scalars['Int']['output'];
}

export interface TicketActivityResponse {
  __typename?: 'TicketActivityResponse';
  date: Scalars['String']['output'];
  items: Array<Activity>;
  total: Scalars['Int']['output'];
}

export interface TicketComment {
  __typename?: 'TicketComment';
  content_html?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  file?: Maybe<Array<TicketCommentFile>>;
  id: Scalars['ID']['output'];
  pwl_id?: Maybe<Scalars['String']['output']>;
  ticket: Ticket;
  updated_at?: Maybe<Scalars['String']['output']>;
  user: User;
}

export interface TicketCommentFile {
  __typename?: 'TicketCommentFile';
  id: Scalars['ID']['output'];
  /** @deprecated Use "id" and /api/ticket-comment/file/{id} route */
  path: Scalars['String']['output'];
  pwl_id?: Maybe<Scalars['String']['output']>;
  sizeInKb: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  user: User;
}

export interface TicketCommentsGridFilterInput {
  createdAt?: InputMaybe<CommentsCreatedAtInput>;
  ticketId?: InputMaybe<CommentsTicketIdInput>;
  userId?: InputMaybe<CommentsUserIdInput>;
}

export interface TicketCommentsGridInput {
  filter?: InputMaybe<TicketCommentsGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketDomainId {
  __typename?: 'TicketDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TicketDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TicketDomainName {
  __typename?: 'TicketDomainName';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TicketDomainNameInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TicketFile {
  __typename?: 'TicketFile';
  id: Scalars['ID']['output'];
  /** @deprecated Use "id" and /api/ticket/file/{id} route */
  path: Scalars['String']['output'];
  sizeInKb: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  user: User;
}

export interface TicketFulltext {
  __typename?: 'TicketFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TicketFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TicketGridFilterInput {
  assignedUserId?: InputMaybe<AssignedUserIdInput>;
  closed?: InputMaybe<ClosedInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<CreatedAtInput>;
  deadline?: InputMaybe<DeadlineInput>;
  domainId?: InputMaybe<TicketDomainIdInput>;
  domainName?: InputMaybe<TicketDomainNameInput>;
  fulltext?: InputMaybe<TicketFulltextInput>;
  ownerUserId?: InputMaybe<OwnerUserIdInput>;
  priorityId?: InputMaybe<PriorityIdInput>;
  projectId?: InputMaybe<ProjectIdInput>;
  start?: InputMaybe<StartInput>;
  statusId?: InputMaybe<StatusIdInput>;
  title?: InputMaybe<TitleInput>;
  typeId?: InputMaybe<TypeIdInput>;
  updatedAt?: InputMaybe<UpdatedAtInput>;
  watchedBy?: InputMaybe<WatchedByInput>;
}

export interface TicketGridInput {
  filter?: InputMaybe<TicketGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TicketId {
  __typename?: 'TicketId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TicketIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TicketTodo {
  __typename?: 'TicketTodo';
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  status: TodoStatusEnum;
  text: Scalars['String']['output'];
  ticket: Ticket;
  user: User;
}

export interface TicketsResponse {
  __typename?: 'TicketsResponse';
  rows: Array<Ticket>;
  total: Scalars['Int']['output'];
}

export interface TimeLog {
  __typename?: 'TimeLog';
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  minutes: Scalars['Int']['output'];
  project_id?: Maybe<Scalars['String']['output']>;
  project_name?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Ticket>;
  ticket_id: Scalars['String']['output'];
  user?: Maybe<User>;
  user_id: Scalars['String']['output'];
}

export interface TimeLogCreatedAt {
  __typename?: 'TimeLogCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface TimeLogCreatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface TimeLogDomainId {
  __typename?: 'TimeLogDomainId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TimeLogDomainIdInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface TimeLogGridFilterInput {
  createdAt?: InputMaybe<TimeLogCreatedAtInput>;
  domainId?: InputMaybe<TimeLogDomainIdInput>;
  ticketId?: InputMaybe<TimeLogTicketIdInput>;
  userId?: InputMaybe<TimeLogUserIdInput>;
}

export interface TimeLogGridInput {
  filter?: InputMaybe<TimeLogGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface TimeLogResponse {
  __typename?: 'TimeLogResponse';
  rows: Array<TimeLog>;
  total: Scalars['Int']['output'];
}

export interface TimeLogTicketId {
  __typename?: 'TimeLogTicketId';
  value: Array<Scalars['ID']['output']>;
}

export interface TimeLogTicketIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface TimeLogUserId {
  __typename?: 'TimeLogUserId';
  value: Array<Scalars['ID']['output']>;
}

export interface TimeLogUserIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface Title {
  __typename?: 'Title';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface TitleInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface Todo {
  __typename?: 'Todo';
  priority: Scalars['Float']['output'];
  text: Scalars['String']['output'];
}

export interface TodoInput {
  priority?: InputMaybe<Scalars['Float']['input']>;
  text: Scalars['String']['input'];
}

export enum TodoStatusEnum {
  DONE = 'DONE',
  INIT = 'INIT',
  NOT_DONE = 'NOT_DONE',
}

export interface Totals {
  __typename?: 'Totals';
  budget: Scalars['Int']['output'];
  closed: Scalars['Int']['output'];
  domains: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
  overdue: Scalars['Int']['output'];
  thisWeek: Scalars['Int']['output'];
  today: Scalars['Int']['output'];
}

export interface TranslatableObject {
  __typename?: 'TranslatableObject';
  id: Scalars['String']['output'];
  translations?: Maybe<Array<TranslationsObject>>;
  type?: Maybe<Scalars['String']['output']>;
}

export interface TranslatableObjectInput {
  id: Scalars['String']['input'];
  translations: Array<TranslationsObjectInput>;
  type?: InputMaybe<Scalars['String']['input']>;
}

export interface TranslatablesResponse {
  __typename?: 'TranslatablesResponse';
  id: Scalars['String']['output'];
  rows?: Maybe<Array<TranslatableObject>>;
  value: Scalars['String']['output'];
}

export interface TranslationsObject {
  __typename?: 'TranslationsObject';
  language_code: Scalars['String']['output'];
  value: Scalars['String']['output'];
}

export interface TranslationsObjectInput {
  language_code: Scalars['String']['input'];
  value: Scalars['String']['input'];
}

export interface Type extends DefaultTypeInterface {
  __typename?: 'Type';
  archived: Scalars['Boolean']['output'];
  color?: Maybe<ColorPaletteEnum>;
  color_value?: Maybe<Scalars['String']['output']>;
  default: Scalars['Boolean']['output'];
  defaultType?: Maybe<DefaultTypeInterface>;
  id: Scalars['ID']['output'];
  priority?: Maybe<Scalars['Float']['output']>;
  project: Project;
  value: Scalars['String']['output'];
}

export interface TypeId {
  __typename?: 'TypeId';
  value: Array<Scalars['ID']['output']>;
}

export interface TypeIdInput {
  value: Array<Scalars['ID']['input']>;
}

export interface TypesResponse {
  __typename?: 'TypesResponse';
  type: Array<DefaultTypeInterface>;
}

export interface UpdateImageDTO {
  __typename?: 'UpdateImageDTO';
  id: Scalars['String']['output'];
  model?: Maybe<Scalars['String']['output']>;
  related_id?: Maybe<Scalars['String']['output']>;
}

export interface UpdateImageDTOInput {
  id: Scalars['String']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  relatedId?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateSubpageInput {
  characterCount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  priority?: InputMaybe<SubpagePriorityEnum>;
  status?: InputMaybe<SubpageStatusEnum>;
  targetCharacterCount?: InputMaybe<Scalars['Int']['input']>;
  targetDescription?: InputMaybe<Scalars['String']['input']>;
  targetHeading?: InputMaybe<Scalars['String']['input']>;
  targetTitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<SubpageTypeEnum>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateTextOrderGuidelineInput {
  guidelines?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
}

export interface UpdateTextOrderInput {
  comments?: InputMaybe<Scalars['String']['input']>;
  domainId?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  finalTextCount?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  implementationTicketId?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  orderType?: InputMaybe<OrderTypeEnum>;
  plannedPublicationDate?: InputMaybe<Scalars['DateTime']['input']>;
  priority?: InputMaybe<TextOrderPriorityEnum>;
  schema?: InputMaybe<TextOrderSchemaDTOInput>;
  sendingToClientTicketId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TextOrderStatusEnum>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  textScore?: InputMaybe<Scalars['Int']['input']>;
  textToBeWrittenBy?: InputMaybe<TextWriterEnum>;
  textType?: InputMaybe<TextOrderTextTypeEnum>;
  textUrl?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  verificationDate?: InputMaybe<Scalars['DateTime']['input']>;
  verificationTime?: InputMaybe<Scalars['Int']['input']>;
  verifierId?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateTextOrdersDTO {
  __typename?: 'UpdateTextOrdersDTO';
  input: TextOrderInput;
}

export interface UpdateTextOrdersDTOInput {
  input: UpdateTextOrderInput;
}

export interface UpdateUserInput {
  appLocale?: InputMaybe<LocaleEnum>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  crmImportId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feSettings?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  importId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionEnum>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relogin?: InputMaybe<Scalars['Boolean']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export interface UpdateUserSubstitutionInput {
  absentEmployeeUserId?: InputMaybe<Scalars['String']['input']>;
  beginsDate?: InputMaybe<Scalars['String']['input']>;
  endsDate?: InputMaybe<Scalars['String']['input']>;
  replacementEmployeeUserId?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatedAt {
  __typename?: 'UpdatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface UpdatedAtInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface User {
  __typename?: 'User';
  allowedPermissions: Array<Scalars['String']['output']>;
  app_locale: LocaleEnum;
  avatar?: Maybe<Scalars['String']['output']>;
  blocked?: Maybe<Scalars['String']['output']>;
  crm_import_id?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  fe_settings?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  import_id?: Maybe<Scalars['String']['output']>;
  is_impersonated: Scalars['Boolean']['output'];
  last_name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  projects?: Maybe<Array<Project>>;
  relogin?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  teams?: Maybe<Array<Team>>;
}

export interface UserCreatedAt {
  __typename?: 'UserCreatedAt';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface UserFulltext {
  __typename?: 'UserFulltext';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface UserFulltextInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface UserGridFilterInput {
  active?: InputMaybe<ActiveFieldInput>;
  email?: InputMaybe<EmailFieldInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fulltext?: InputMaybe<UserFulltextInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  noBlocked?: InputMaybe<NoBlockedFilterInput>;
  permissions?: InputMaybe<PermissionsFieldInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<RoleFieldInput>;
}

export interface UserGridInput {
  filter?: InputMaybe<UserGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface UserId {
  __typename?: 'UserId';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface UserIds {
  __typename?: 'UserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface UserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface UserStatisticsResponse {
  __typename?: 'UserStatisticsResponse';
  closed: Scalars['Int']['output'];
  open: Scalars['Int']['output'];
  overdue: Scalars['Int']['output'];
  thisWeek: Scalars['Int']['output'];
  today: Scalars['Int']['output'];
}

export interface UserSubstitution {
  __typename?: 'UserSubstitution';
  absentEmployee: User;
  begins_date: Scalars['String']['output'];
  createdBy: User;
  created_at: Scalars['String']['output'];
  ends_date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  replacementEmployee: User;
  updatedBy: User;
  updated_at: Scalars['String']['output'];
}

export interface UserSubstitutionAclIds {
  __typename?: 'UserSubstitutionAclIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface UserSubstitutionGridFilterInput {
  absentUserId?: InputMaybe<AbsentUserIdInput>;
  createdByUserId?: InputMaybe<CreatedByUserIdInput>;
  substituteUserId?: InputMaybe<SubstituteUserIdInput>;
}

export interface UserSubstitutionGridInput {
  filter?: InputMaybe<UserSubstitutionGridFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortTypeInput>;
}

export interface UserSubstitutionsResponse {
  __typename?: 'UserSubstitutionsResponse';
  rows: Array<UserSubstitution>;
  total: Scalars['Int']['output'];
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  rows: Array<User>;
  total: Scalars['Int']['output'];
}

export interface UxUserIds {
  __typename?: 'UxUserIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface UxUserIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface VerificationDateRange {
  __typename?: 'VerificationDateRange';
  operator: OperatorEnum;
  value: Array<Scalars['DateTime']['output']>;
}

export interface VerificationDateRangeInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['DateTime']['input']>;
}

export interface VerifierIds {
  __typename?: 'VerifierIds';
  operator: OperatorEnum;
  value: Array<Scalars['String']['output']>;
}

export interface VerifierIdsInput {
  operator?: InputMaybe<OperatorEnum>;
  value: Array<Scalars['String']['input']>;
}

export interface WatchedBy {
  __typename?: 'WatchedBy';
  value: Array<Scalars['ID']['output']>;
}

export interface WatchedByInput {
  value: Array<Scalars['ID']['input']>;
}
