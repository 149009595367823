import styled from 'styled-components';

export const PillsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  .ant-tag {
    /* min-width: 75px; */

    background: ${({ theme: { colors } }) => colors.antTagBackground};
    color: ${({ theme: { colors } }) => colors.mainText};
    border-color: ${({ theme: { colors } }) => colors.selectBorder};
    margin-right: 0;
    cursor: pointer;
  }
`;
