import styled from 'styled-components';

import { Pill } from '../../../Pill';

export const ButtonContent = styled.div`
  display: flex;
`;

export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  color: ${({ theme: { colors } }) => colors.darkBlue};
`;

export const SummaryPill = styled<{ $bgColor: string; $color: string } | any>(Pill)`
  background-color: ${({ $bgColor }) => $bgColor};
  border-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};

  :hover,
  :active,
  :focus {
    background-color: ${({ $bgColor }) => $bgColor};
    border-color: ${({ $bgColor }) => $bgColor};
    filter: brightness(110%);
  }
`;
