import styled, { css } from 'styled-components';
import { Select as SelectAntd } from 'antd';

import { Plus } from '@/components/Icon';

const selectStyles = css`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme: { border } }) => border.radius.big};
    border: dashed 1px ${({ theme: { colors } }) => colors.green};
    background-color: ${({ theme: { colors } }) => colors.cardBackground};
    line-height: normal;
    cursor: pointer;

    .ant-select-selection-overflow {
      cursor: pointer;
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;

      .ant-select-selection-search-input {
        cursor: pointer !important;
      }
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      color: ${({ theme: { colors } }) => colors.green};
      filter: unset;
    }
  }

  .ant-select-item-option-content {
    white-space: normal;
  }
`;

export const RectangleSelect = styled<{ $height: string; $width: string } | any>(SelectAntd)`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  transition: all 0.24s ease-in-out;
  :hover,
  &.ant-select-focused {
    filter: brightness(80%) contrast(0.8);
    .ant-select-selector {
      border-color: ${({ theme: { colors } }) => colors.green} !important;
    }
  }

  ${selectStyles}
`;

export const RoundSelect = styled<{ $height: string; $width: string } | any>(SelectAntd)`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};

  ${selectStyles}

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 50%;
  }
`;

export const Text = styled.div`
  padding-left: 13px;
  color: ${({ theme: { colors } }) => colors.green};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  filter: unset;
`;

export const PlusIcon = styled(Plus)`
  height: 10px;
  fill: ${({ theme: { colors } }) => colors.green};
  transition: all 0.24s ease-in-out;
`;
