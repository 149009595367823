import styled from 'styled-components';
import { Select as SelectAntd } from 'antd';

import { Cross } from '@/components/Icon';

export const Select = styled<{ itemdisplay: string; $bordered: boolean } | any>(SelectAntd)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    width: 100%;
    border: ${({ $bordered, theme: { colors } }) =>
      $bordered ? `0.7px solid ${colors.selectBackground}` : 'none'};
    cursor: pointer;

    .ant-select-selection-overflow {
      cursor: pointer;
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;

      .ant-select-selection-search-input {
        cursor: pointer !important;
      }
    }

    .ant-select-selection-item {
      display: ${({ itemdisplay }) => itemdisplay};
      align-items: center;
      max-height: 100%;
      line-height: 1.4;
    }
  }
`;

export const DeleteIcon = styled(Cross)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.notifRed};
`;
