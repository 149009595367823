import styled from 'styled-components';

import { Table as AntdTable } from '../Tables';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-inline: 15px;
  margin-bottom: 15px;
`;

export const DnDWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
`;

export const DnDInput = styled.input`
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  translate-x: -50%;
  translate-y: -50%;
`;

export const DnDCancelButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  background: none;
  outline: none;
  border: none;
`;

export const DnDLabel = styled.label<{ dragActive: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: ${({ theme: { colors } }) => colors.tooltipBackground};
  border-radius: 10px;

  opacity: ${({ dragActive }) => (dragActive ? '.75' : '1')};
`;

export const DnDFileInfo = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
`;

export const DnDTooltip = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
`;

export const Table = styled(AntdTable)`
  th {
    min-width: 100px;
  }
`;
