import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { EnterOutlined } from '@ant-design/icons';

const transition = css`
  transition: all 0.05s ease-in-out;
`;

export const HitLinkWrapper = styled<{ $isActive: boolean } | any>(Link)`
  ${transition}

  display: block;
  background: ${({ theme: { colors }, $isActive }) =>
    $isActive ? colors.lightBlue : colors.tooltipBackground};
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  padding: 0 1.25rem 0 1rem;
`;

export const Hit = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

export const HitContent = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column-reverse;
  min-width: 0;
`;

export const HitItem = styled.li`
  margin-top: 0.5rem;
`;

export const HitTitle = styled.span<{ $isActive: boolean }>`
  ${transition}

  color: ${({ theme: { colors }, $isActive }) => ($isActive ? colors.white : colors.mainText)};
  line-height: 1.5rem;
  font-size: 1rem;

  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  mark {
    ${transition}

    padding: 0;
    background-color: transparent;
    color: ${({ theme: { colors }, $isActive }) => ($isActive ? colors.white : colors.lightBlue)};
    text-decoration: ${({ $isActive }) => ($isActive ? 'underline' : 'none')};
  }
`;

export const HitAction = styled.div`
  flex: none;
  margin-left: 0.875rem;
`;

export const EnterOutlinedIcon = styled<{ $isActive: boolean } | any>(EnterOutlined)`
  ${transition}

  color: ${({ theme: { colors }, $isActive }) => ($isActive ? colors.white : '#71717a')};
  font-size: 1rem;
`;
