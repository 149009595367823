import styled from 'styled-components';
import { Table as TableAntd } from 'antd';

export const TableComponent = styled(TableAntd)`
  &.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border-color: ${({ theme: { colors } }) => colors.selectBorder};
  }

  .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
    border-top: 1px solid ${({ theme: { colors } }) => colors.selectBorder};

    .ant-table-thead {
      tr {
        .ant-table-cell {
          background-color: ${({ theme: { colors } }) => colors.tableTopBarBackground};
          border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
          font-weight: ${({ theme: { font } }) => font.weight.bold};

          :not(:last-child) {
            border-right: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
          }
          .ant-table-column-sorter {
            color: ${({ theme: { colors } }) => colors.mainText};
          }
        }
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        &:hover > td {
          background: ${({ theme: { colors } }) => colors.tableTopBarBackground};
        }
        .ant-table-cell {
          &.ant-table-column-sort {
            background: ${({ theme: { colors } }) => colors.tableTopBarBackground};
          }
          :not(:last-child) {
            border-right: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
          }
        }

        :not(:last-child) {
          .ant-table-cell {
            border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
          }
        }
      }
    }
  }
`;
