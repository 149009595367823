import styled, { css } from 'styled-components';

import { PhoneInCircle, MailInCircle, GeotagInCircle } from '@/components/Icon';

const iconStyle = css`
  height: 40px;
  min-width: 40px;
  margin: 0 15px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const Text = styled.div`
  overflow-wrap: anywhere;
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const PhoneInCircleIcon = styled(PhoneInCircle)`
  ${iconStyle}
`;

export const MailInCircleIcon = styled(MailInCircle)`
  ${iconStyle}
`;

export const GeotagInCircleIcon = styled(GeotagInCircle)`
  ${iconStyle}
`;
