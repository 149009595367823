import { Form, InputNumber, Modal, Radio, RadioChangeEvent, DatePicker, Alert } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';

import { useFetchAndExportData } from '@/hooks';
import { ModalFilters } from '@/context/ExportDataProvider';
import { theme } from '@/theme';

type DateRangeOptions = {
  dateFrom: string;
  dateTo: string;
};

type QuantityOptions = {
  filter: any;
  start: number;
  length: number;
};

interface IExportDataModal {
  visible: boolean;
  closeModal: () => void;
  defaultGrid: any;
  action: 'download' | 'copy';
  filters: ModalFilters[];
  smallSizedDataOptions?: {
    apiRoute: string;
    outputFilename: string;
  } | null;
  largeSizedDataOptions?: {
    copyRoute: string;
    downloadRoute: string;
  } | null;
}

export const ExportDataModal = ({
  visible,
  closeModal,
  defaultGrid,
  action,
  filters,
  smallSizedDataOptions,
  largeSizedDataOptions,
}: IExportDataModal) => {
  const intl = useIntl();

  const { fetchAndExportData } = useFetchAndExportData();

  const [formValues, setFormValues] = useState<DateRangeOptions | QuantityOptions>(() => {
    let values = {};

    if (filters.includes('quantity')) {
      values = {
        ...values,
        range: 'all',
        start: defaultGrid.offset ?? 0,
        length: defaultGrid.limit ?? 0,
      };
    }

    if (filters.includes('daterange')) {
      values = { ...values, dateFrom: defaultGrid.from, dateTo: defaultGrid.to };
    }

    return values;
  });

  const onChangeRangeType = (e: RadioChangeEvent) => {
    setFormValues(prev => ({ ...prev, range: e.target.value }));
  };

  const onChangeInput = (name: string) => {
    return (number: number) => {
      setFormValues(prev => ({ ...prev, [name]: number }));
    };
  };

  const onTimeRangeChange = ([dateFrom, dateTo]) => {
    setFormValues(prev => ({ ...prev, dateFrom, dateTo }));
  };

  const fetchData = async () => {
    const payload: any = {};

    if (defaultGrid.dealsOrder) {
      payload.dealsOrder = defaultGrid.dealsOrder;
    }

    if (filters.includes('quantity')) {
      payload.filter = defaultGrid.filter ?? {};
      payload.sort = defaultGrid.sort ?? {};

      if (formValues.range === 'limit') {
        payload.offset = formValues.start;
        payload.limit = formValues.length;
      }
    }

    if (filters.includes('daterange')) {
      payload.from = formValues.dateFrom;
      payload.to = formValues.dateTo;
      payload.phraseIds = defaultGrid.phraseIds;
    }

    fetchAndExportData({
      payload,
      mode: action,
      smallSizedDataOptions,
      largeSizedDataOptions,
    }).then(closeModal);
  };

  const isFormDisabled = (() => {
    const rules = [];

    if (filters.includes('quantity'))
      rules.push(
        formValues.range === 'all' ||
          (formValues.range === 'limit' && formValues.start >= 0 && formValues.length > 0),
      );

    return rules.some(rule => !rule);
  })();

  const disabledDate = (current: Dayjs) => current > dayjs().endOf('month');

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Eksport' })}
      open={visible}
      onOk={fetchData}
      onCancel={closeModal}
      okButtonProps={{ disabled: isFormDisabled, type: 'secondary' }}
    >
      <Alert
        message={intl.formatMessage({
          defaultMessage: 'Aktywne filtry mają wpływ na eksportowane dane',
        })}
        type="warning"
        style={{ marginBottom: '1rem' }}
        showIcon
      />
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        {filters.includes('quantity') && (
          <Radio.Group
            name="range"
            onChange={onChangeRangeType}
            value={formValues.range}
            style={{ marginBottom: 24 }}
          >
            <Radio value="limit" style={{ marginBottom: 24 }}>
              {intl.formatMessage({ defaultMessage: 'Limit' })}
            </Radio>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: 'Start' })}
              rules={[{ required: formValues.range !== 'all' }]}
              style={{ marginLeft: 16, width: 200 }}
            >
              <InputNumber
                name="start"
                type="number"
                disabled={formValues.range === 'all'}
                onChange={onChangeInput('start')}
                value={formValues.start}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ defaultMessage: 'Ilość' })}
              rules={[{ required: formValues.range !== 'all' }]}
              style={{ marginLeft: 16, width: 200 }}
            >
              <InputNumber
                name="length"
                type="number"
                disabled={formValues.range === 'all'}
                onChange={onChangeInput('length')}
                value={formValues.length}
              />
            </Form.Item>
            <Radio value="all"> {intl.formatMessage({ defaultMessage: 'Wszystko' })} </Radio>
          </Radio.Group>
        )}
        {filters.includes('daterange') && (
          <>
            <p>Zakres dat</p>
            <DatePicker.RangePicker
              onChange={onTimeRangeChange}
              value={[dayjs(formValues.dateFrom), dayjs(formValues.dateTo)]}
              style={{ marginLeft: 16, width: 300 }}
              disabledDate={disabledDate}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};
