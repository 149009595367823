import { FC } from 'react';
import { ButtonProps } from 'antd';

import { Button, ChevronIcon } from './styled';

interface IButtonLink extends ButtonProps {
  withArrow?: boolean;
}

export const ButtonLink: FC<IButtonLink> = ({ children, withArrow, ...props }) => (
  <Button type="link" {...props}>
    {children} {withArrow && <ChevronIcon />}
  </Button>
);
