import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectProps } from 'antd/lib/select';
import { useTheme } from 'styled-components';

import { UserBasicFragment } from '@/__generated__';

import { UserItem } from '../../UserItem';
import { dropdownStyle } from '../styled';

import { Placeholder, PlusIcon, RectangleSelect, RoundSelect, Text } from './styled';

interface IAlwaysEmptyUserSelect extends SelectProps<string, UserBasicFragment> {
  onChange: (value: string, option: unknown) => void;
  users: UserBasicFragment[];
  selectedUsersIds?: string[];
  dataTestId?: string;
  category?: string;
  height?: string;
  width?: string;
  isNarrow?: boolean;
  isRound?: boolean;
}

export const AlwaysEmptyUserSelect: FC<IAlwaysEmptyUserSelect> = ({
  onChange,
  users,
  selectedUsersIds = [],
  dataTestId = 'empty-user-select',
  category = null,
  isNarrow = false,
  height = '60px',
  width = '100%',
  isRound = false,
  ...props
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const activeUsers = useMemo(() => users?.filter(user => !user.blocked), [users]);

  const availableUsers = activeUsers.filter(
    (user: UserBasicFragment) => !selectedUsersIds.find((userId: string) => userId === user.id),
  );

  const getUsers = () =>
    availableUsers.map(user => (
      <Select.Option
        key={user.id}
        value={user.id}
        username={`${user.first_name} ${user.last_name}`}
        data-testid={`${dataTestId}-option`}
        {...(!!category ? { category } : {})}
      >
        <UserItem user={user} isNarrow={isNarrow} />
      </Select.Option>
    ));

  const filterDropdownOptions = (input: string, option: any) =>
    option?.username?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;

  const placeholder = () => (
    <Placeholder>
      <PlusIcon />
      {!isRound && (
        <Text style={{ color: theme.colors.green }}>
          {intl.formatMessage({ defaultMessage: 'Dodaj osobę' })}
        </Text>
      )}
    </Placeholder>
  );

  const dropdownRender = (originNode: ReactNode) => (
    <div data-testid={`${dataTestId}-dropdown`}>{originNode}</div>
  );

  const Select = isRound ? RoundSelect : RectangleSelect;

  return (
    <Select
      optionFilterProp="children"
      suffixIcon={null}
      value={[]}
      onChange={onChange}
      dropdownStyle={dropdownStyle}
      filterOption={filterDropdownOptions}
      data-testid={dataTestId}
      placeholder={placeholder()}
      $height={height}
      $width={width}
      dropdownRender={dropdownRender}
      showSearch
      {...props}
      {...(isRound ? { popupMatchSelectWidth: false } : {})}
    >
      {getUsers()}
    </Select>
  );
};
