import styled, { css, keyframes } from 'styled-components';

import { Process } from '../../../Icon';

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
`;

const anim = props =>
  props.$loading
    ? css`
        ${loadingAnimation} 1s infinite;
      `
    : 'none';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
`;

export const ProcessIcon = styled<{ $loading: boolean } | any>(Process)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.iconGrey};
  animation: ${anim};
`;

export const Title = styled.div`
  font-size: ${({ theme: { font } }) => font.size.semiBig};
  color: ${({ theme: { colors } }) => colors.mainText};
`;
