import styled from 'styled-components';

export const Tag = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 23px;
  min-width: 105px;
  padding: 0 10px;
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
