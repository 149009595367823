import styled from 'styled-components';

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  padding: 10px;

  :not(:nth-child(1n + 1)) {
    border-top: 1px solid ${({ theme: { colors } }) => colors.borderGrey};
  }
`;

export const Status = styled.div<{ color: string }>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
