import styled from 'styled-components';

export const Container = styled.div<{
  $position: { isStart: boolean; isEnd: boolean };
  $leftShadow: string;
  $rightShadow: string;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;

  ::before,
  ::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 30px;
    transition: box-shadow 0.2s;
    content: '';
    pointer-events: none;
  }

  ::before {
    left: 0;
    box-shadow: ${({ $position, $leftShadow }) => ($position.isStart ? 'none' : $leftShadow)};
  }

  ::after {
    right: 0;
    box-shadow: ${({ $position, $rightShadow }) => ($position.isEnd ? 'none' : $rightShadow)};
  }
`;

export const Wrapper = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 3px rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 3px rgb(0 0 0 / 20%);
    background-color: #d0d0d0;
  }
`;

export const Scroll = styled.div<{
  $noGap: boolean;
}>`
  display: flex;
  width: max-content;
  gap: ${({ $noGap }) => ($noGap ? '0' : '7.5px')};
`;
