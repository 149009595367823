import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { debounce, noop } from 'lodash';
import { SelectProps } from 'antd/lib/select';
import { ApolloError } from '@apollo/client';
import { LoadingOutlined } from '@ant-design/icons';

import { ErrorMessage } from '../../ErrorMessage';
import { SelectWrapper } from '../SelectWrapper';

import { SelectStyled } from './styled';

interface ISearchResult {
  id: string;
  value: string | null | undefined;
}

interface ISearchSelect extends SelectProps<string[] | string> {
  fetchData: (props: string) => void;
  loading: boolean;
  result: ISearchResult[] | null | undefined;
  error: ApolloError | undefined;
  dataTestId?: string;
  label?: ReactNode;
  initialValue?: string | string[] | undefined;
  initialOptions?: { id: string; value: string }[];
  mode?: 'multiple';
  darkMode?: boolean;
}

export const SearchSelect = (props: ISearchSelect) => {
  const {
    onChange = noop,
    onBlur = noop,
    fetchData,
    dataTestId = 'search',
    loading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
    result,
    error,
    mode = undefined,
    label = null,
    initialValue,
    initialOptions,
    darkMode = false,
    ...rest
  } = props;

  const intl = useIntl();
  const [data, setData] = useState<ISearchResult[] | null | undefined>(initialOptions);
  const [state, setState] = useState(initialValue);
  const [option, setOption] = useState<unknown>();

  useEffect(() => {
    result && setData(result);
  }, [result]);

  const resetSearch = () => {
    setData(null);
    handleSearch.cancel();
  };

  const triggerSearch = (searchValue: string) => {
    const trimmedValue = searchValue.trim();

    if (!trimmedValue) {
      resetSearch();

      return;
    }

    fetchData(trimmedValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useMemo(() => debounce(triggerSearch, 500), []);

  const handleChange = (newValue: string | string[], newOption: unknown) => {
    setState(newValue);
    setOption(newOption);

    onChange(newValue, newOption);
  };

  const handleBlur = () => {
    onBlur(state, option);
    resetSearch();
  };

  const options = () =>
    data?.map(d => (
      <Select.Option key={d.id} value={d.id}>
        {d.value}
      </Select.Option>
    )) ?? [];

  const notFound = () => {
    if (loading) return <LoadingOutlined />;
    if (data) return intl.formatMessage({ defaultMessage: 'Brak wyników' });
    if (error) return <ErrorMessage error={error} />;

    return null;
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div data-testid={`${dataTestId}-dropdown`}>{originNode}</div>
  );

  return (
    <SelectWrapper label={label} className={darkMode ? 'dark-select' : 'light-select'}>
      <SelectStyled
        onChange={handleChange}
        onBlur={handleBlur}
        dropdownRender={dropdownRender}
        notFoundContent={notFound()}
        mode={mode}
        showSearch
        value={state}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        data-testid={dataTestId}
        $darkMode={darkMode}
        {...rest}
      >
        {options()}
      </SelectStyled>
    </SelectWrapper>
  );
};
