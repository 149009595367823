import styled, { css } from 'styled-components';
import { Input as InputAntd, InputNumber as InputNumberAntd } from 'antd';

/**
 * Variables
 */
export const inputHeight = '30px';
export const borderStyle = css`
  border: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
`;
export const borderRadius = css`
  border-radius: ${({ theme: { border } }) => border.radius.semiMedium};
`;

/**
 * Input styles
 */
export const inputStyles = css`
  ${borderStyle};
  ${borderRadius};

  padding: 10px 14px;
  font-size: ${({ theme: { font } }) => font.size.regular};

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.mainText};
    filter: contrast(0.1);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Error = styled.span`
  color: ${({ theme: { colors } }) => colors.notifRed};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
`;

export const Label = styled.label`
  padding-bottom: 10px;
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

const standard = css`
  border: 0.7px solid ${({ theme: { colors } }) => colors.selectBorder};
  border-radius: ${({ theme: { border } }) => border.radius.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
  background-color: ${({ theme: { colors } }) => colors.selectLightBackground};
`;

export const TextAreaInputStyled = styled(InputAntd.TextArea)`
  ${standard}
`;

const input = css`
  ${standard}

  height: 35px;

  &.ant-input[disabled] {
    color: ${({ theme: { colors } }) => colors.mainText};

    :hover {
      border: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
    }
  }
`;

export const TextInputStyled = styled<{ $fontSize: number } | any>(InputAntd)`
  ${input}

  font-size: ${({ $fontSize }) => $fontSize}px;
`;

export const InputNumber = styled(InputNumberAntd)`
  ${input}

  width: 100%;

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const PasswordInput = styled(InputAntd.Password)`
  ${input}
`;
