import { FC } from 'react';

import { Container, Wrapper, Figure, LoaderIcon1, LoaderIcon2, LoaderIcon } from './styled';

interface ILoader {
  height?: number;
  wrapperHeight?: string;
}

export const Loader: FC<ILoader> = ({ height = 100, wrapperHeight = '100%' }) => (
  <Container wrapperHeight={wrapperHeight} data-testid="outer-loader">
    <Wrapper height={height}>
      <Figure>
        <LoaderIcon />
      </Figure>
    </Wrapper>
  </Container>
);
