import styled from 'styled-components';
import { Button } from 'antd';

import { SearchGlass } from '@/components/Icon';

export const SearchIcon = styled(SearchGlass)`
  font-size: 1.2rem;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const Container = styled(Button)`
  color: ${({ theme: { colors } }) => colors.mainText};
  display: flex;
  align-items: center;
  height: 35px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;

  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme: { colors } }) => colors.mainContrastColor};
  }
`;

export const Key = styled.kbd`
  padding: 2px 4px;
  margin: 0 2px;
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
  border-bottom-width: 2px;
  text-align: center;
  font-size: 1rem;
  background: rgba(150, 150, 150, 0.06);
`;

export const Text = styled.span`
  margin-left: 20px;
`;

export const Keybinding = styled.span`
  margin-left: 20px;
`;
