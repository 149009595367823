import styled, { css } from 'styled-components';

import { Facebook, Linkedin } from '../Icon';

const iconStyle = css`
  height: 35px;
  fill: ${({ theme: { colors } }) => colors.blue2};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.borderGrey};
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 10px;
`;

export const Name = styled.div`
  font-size: ${({ theme: { font } }) => font.size.medium};
  font-weight: ${({ theme: { font } }) => font.weight.bold};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const Position = styled.div`
  color: ${({ theme: { colors } }) => colors.textGrey};
`;

export const FacebookIcon = styled(Facebook)`
  ${iconStyle}
`;

export const LinkedInIcon = styled(Linkedin)`
  ${iconStyle}
`;

export const IconWrapper = styled.a`
  padding: 0 5px;
`;

export const NoLinkWrapper = styled.div`
  padding: 0 5px;

  svg {
    fill: ${({ theme: { colors } }) => colors.disabledGrey};
  }
`;
