import { FC, ReactNode } from 'react';
import { CollapseProps } from 'antd';

import { theme } from '@/theme';

import { ChevronIcon, CollapseContainer, Panel } from './styled';

interface ICollapse extends CollapseProps {
  extra?: ReactNode;
  header?: ReactNode;
  cardMode?: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
}

export const Collapse: FC<ICollapse> = props => {
  const {
    header,
    extra = null,
    cardMode = false,
    defaultOpen = 1,

    disabled = false,
    ...rest
  } = props;
  const color = disabled ? theme.colors.disabledGrey : theme.colors.lightGrey;
  const headerColor = cardMode ? theme.colors.white : color;
  const collapseBorder = cardMode ? null : color;
  const bottomPanelBorder = cardMode ? theme.colors.borderGrey : null;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      children: (
        <Panel
          header={header}
          headerColor={headerColor}
          $bottomPanelBorder={bottomPanelBorder}
          extra={extra}
          key="1"
        />
      ),
    },
  ];

  return (
    <CollapseContainer
      defaultActiveKey={defaultOpen}
      bordered={false}
      expandIconPosition="end"
      destroyInactivePanel={true}
      collapseBorder={collapseBorder}
      expandIcon={(panelProps: { isActive: any }) => (
        <ChevronIcon rotatedeg={panelProps.isActive ? 180 : 0} />
      )}
      data-testid="collapse-container"
      items={items}
      {...rest}
    />
  );
};
