import { CSSProperties, FC, PropsWithChildren } from 'react';

import { useHorizontalScroll } from '@/hooks';
import { theme } from '@/theme';

import { Container, Scroll, Wrapper } from './styled';

interface IHorizontalScroll {
  style?: CSSProperties;
  'data-testid'?: string;
  leftShadow?: string;
  rightShadow?: string;
  noGap?: boolean;
}

export const HorizontalScroll: FC<PropsWithChildren<IHorizontalScroll>> = props => {
  const { children, leftShadow, rightShadow, noGap, ...rest } = props;
  const { position, innerRef, scrollRef } = useHorizontalScroll();

  return (
    <Container
      $position={position}
      $leftShadow={leftShadow ?? theme.shadows.lightLeft}
      $rightShadow={rightShadow ?? theme.shadows.lightRight}
      {...rest}
    >
      <Wrapper ref={innerRef}>
        <Scroll ref={scrollRef} $noGap={noGap}>
          {children}
        </Scroll>
      </Wrapper>
    </Container>
  );
};
