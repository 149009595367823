import styled from 'styled-components';
import { Button as ButtonAntd } from 'antd';

import { ChevronDown } from '../../Icon';

export const ChevronIcon = styled(ChevronDown)`
  height: 6px;
  fill: ${({ theme: { colors } }) => colors.lightBlue};
  transform: rotate(270deg);
`;

export const Button = styled(ButtonAntd)`
  display: flex;
  align-items: center;
  font-size: ${({ theme: { font } }) => font.size.regular};
  height: 18px;
  padding: 0;
  color: ${({ theme: { colors } }) => colors.lightBlue};

  :hover {
    ${ChevronIcon} {
      fill: ${({ theme: { colors } }) => colors.lightBlueHover};
    }
  }
`;
