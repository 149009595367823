import { Alert, Checkbox, Modal } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { ButtonCustomTypes } from '@/consts';
import { useCopywritingContext } from '@/containers/TextOrderList/hooks';
import { useCreateTextOrders, useNotify } from '@/hooks';
import { TextOrderStatusEnum } from '@/__generated__';
import { INewTextOrderDTO } from '@/containers/TextOrderList/types';

import { Button } from '../Buttons';
import { DomainsSearch } from '../DomainsSearch';
import { TextInputField } from '../Form';

import { FooterButtons, MetaDescOption, SchemaOptions, TextAreaField } from './styled';

interface OptionsForm {
  shortText: number | null;
  longText: number | null;
  metaDescription: boolean;
}

export const TextOrdersModal = ({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) => {
  const { grid, setCurrentTextOrders, setSelectedRows, domainId } = useCopywritingContext();
  const [createTextOrders] = useCreateTextOrders();
  const { notifySuccess } = useNotify();
  const [domain, setDomain] = useState();
  const intl = useIntl();

  const [urlList, setUrlList] = useState('');
  const handleChangeUrlList = (evt: React.ChangeEvent<HTMLTextAreaElement>) =>
    setUrlList(evt.target.value);

  const [options, setOptions] = useState<OptionsForm>({
    shortText: null,
    longText: null,
    metaDescription: false,
  });

  const handleUpdateOptions = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = evt.target;
    setOptions(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : Number(value) }));
  };

  const handleSubmit = async () => {
    if (!urlList.trim() || (!domain && !domainId)) return;

    const newTextOrders: INewTextOrderDTO[] = urlList
      .split('\n')
      .filter(text => Boolean(text))
      .map(url => ({
        schema: options,
        status: TextOrderStatusEnum.NEW,
        url: url,
        textUrl: null,
        verifierId: null,
        keywords: [],
      }));

    createTextOrders({
      variables: {
        inputs: newTextOrders.map(data => ({
          input: {
            domainId: domain ?? domainId,
            ...data,
          },
        })),
      },
      textOrdersVariables: { grid },
    }).then(data => {
      if (data.data?.createTextOrders) {
        const newIds = data.data.createTextOrders.map(textOrder => textOrder.id);
        setSelectedRows(newIds);
      }
      notifySuccess(intl.formatMessage({ defaultMessage: 'Dodano zlecenia' }));
    });

    setCurrentTextOrders(prev =>
      newTextOrders.reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        prev ? { ...prev } : {},
      ),
    );

    closeModal();
  };

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Nowe zlecenia tekstowe' })}
      open={open}
      onCancel={closeModal}
      footer={
        <FooterButtons>
          <Button key="cancel" onClick={closeModal} colorType={ButtonCustomTypes.BACK}>
            {intl.formatMessage({ defaultMessage: 'Anuluj' })}
          </Button>
          <Button
            key="submit"
            type="secondary"
            onClick={handleSubmit}
            colorType={ButtonCustomTypes.NEXT}
            disabled={
              !urlList ||
              !(domainId || domain) ||
              (options.shortText === null && options.longText === null && !options.metaDescription)
            }
          >
            {intl.formatMessage({ defaultMessage: 'Dodaj' })}
          </Button>
        </FooterButtons>
      }
    >
      <Alert
        message={intl.formatMessage({
          defaultMessage: 'Każdy link oddziel nowymi liniami',
        })}
        type="warning"
        style={{ marginBottom: '1rem' }}
        showIcon
      />
      <TextAreaField
        value={urlList}
        onChange={handleChangeUrlList}
        rows={10}
        placeholder="https://example.com/"
      />
      <SchemaOptions>
        <TextInputField
          style={{ height: 30 }}
          type="number"
          min={1}
          max={50_000}
          name="shortText"
          onChange={handleUpdateOptions}
          value={options.shortText}
          placeholder={intl.formatMessage({
            defaultMessage: 'Krótki tekst',
          })}
        />
        <TextInputField
          style={{ height: 30 }}
          type="number"
          min={1}
          max={50_000}
          name="longText"
          onChange={handleUpdateOptions}
          value={options.longText}
          placeholder={intl.formatMessage({
            defaultMessage: 'Długi tekst',
          })}
        />
        <MetaDescOption>
          <span>
            {intl.formatMessage({
              defaultMessage: 'Meta desc',
            })}
          </span>
          <Checkbox
            name="metaDescription"
            onChange={handleUpdateOptions}
            checked={options.metaDescription}
          />
        </MetaDescOption>
      </SchemaOptions>
      {!domainId && (
        <DomainsSearch
          value={domain}
          onChange={setDomain}
          style={{ marginTop: '1rem' }}
          placeholder={intl.formatMessage({ defaultMessage: 'Domena' })}
        />
      )}
    </Modal>
  );
};
