import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { ConfigProvider } from 'antd';

import { getLocale, IntlProvider } from '@/i18n';
import { useUser } from '@/context/UserProvider';
import { defaultLocale } from '@/i18n/config';

/**
 * Context
 */
const LanguageContext = createContext({
  locale: '',
  defaultLocale: '',
  antdLocale: '',
  messages: {},
});

interface ILanguageProvider {
  children: ReactNode;
}

/**
 * Provider
 */
const LanguageProvider = ({ children }: ILanguageProvider) => {
  const user = useUser();
  const userLocale = user?.app_locale;
  const localeData = useMemo(() => getLocale(userLocale, defaultLocale), [userLocale]);

  useEffect(() => {
    localStorage.setItem('appLocale', localeData.locale);
  }, [localeData.locale]);

  return (
    <IntlProvider
      defaultLocale={defaultLocale}
      key={localeData.locale}
      locale={localeData.locale}
      messages={localeData.messages}
    >
      <LanguageContext.Provider value={localeData}>
        <ConfigProvider
          theme={{
            hashed: false,
            token: {
              fontFamily: 'Lato',
              fontSize: 13,
              colorLink: '#5768FF',
            },
            components: {
              Button: {
                defaultBorderColor: 'unset',
                defaultShadow: 'unset',
              },
            },
          }}
          locale={localeData.antdLocale}
        >
          {children}
        </ConfigProvider>
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

/**
 * Hook
 */
const useLanguage = () => {
  const context = useContext(LanguageContext);

  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  return context;
};

export { LanguageProvider, useLanguage };
