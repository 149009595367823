import styled from 'styled-components';

export const Popup = styled.div<{ isMobileLayout: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobileLayout }) => (isMobileLayout ? 325 : 450)}px;
  background-color: ${({ theme: { colors } }) => colors.cardBackground};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const NoNewNotifications = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;
