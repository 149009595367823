import styled from 'styled-components';

import { Plus } from '../../Icon';

export const PlusIcon = styled(Plus)`
  height: 10px;
  min-width: 15px;
  margin-right: 10px;
  fill: ${({ theme: { colors } }) => colors.white};
  transition: all 0.24s ease-in-out;
`;
