import { FC } from 'react';
import { ButtonProps } from 'antd';
import { useTheme } from 'styled-components';

import { ButtonCustomTypes } from '@/consts';

import { buttonColors } from '../helpers';

import { ButtonStyled } from './styled';

interface IButton extends ButtonProps {
  colorType?: ButtonCustomTypes;
  fullWidth?: boolean;
  height?: string;
  dataTestId?: string;
}

const getWidth = (fullWidth: boolean) => (fullWidth ? '100%' : 'auto');

export const Button: FC<IButton> = props => {
  const {
    children,
    colorType = ButtonCustomTypes.NEXT,
    fullWidth = false,
    height = '35px',
    dataTestId,
    ...rest
  } = props;
  const theme = useTheme();

  return (
    <ButtonStyled
      $colors={buttonColors(colorType, theme)}
      $width={getWidth(fullWidth)}
      $height={height}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};
