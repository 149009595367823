import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';

import { SearchGlass } from '@/components/Icon';

export const Container = styled.div``;

export const SearchIcon = styled(SearchGlass)`
  color: ${({ theme: { colors } }) => colors.mainText};
  font-size: 1.5rem;
`;

export const ContentContainer = styled.div`
  overflow: auto;
  max-height: 60vh;
`;

export const Title = styled.h2`
  line-height: 1.5rem;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.mainText};
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const Section = styled.section`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
