import { Button, DatePicker, Form, InputNumber, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useTheme } from 'styled-components';

import {
  Quarter as IQuarter,
  QuarterOverride as IQuarterOverride,
  QuarterTypeEnum,
} from '@/__generated__';
import { ChevronDown } from '@/components/Icon';

import {
  useCreateQuarterOverride,
  useDeleteQuarterOverride,
  useUpdateQuarterOverride,
} from '../hooks';

interface IQuarterOverrideModal {
  domainId: string;
  quarter: IQuarter | null;
  quarterOverride: IQuarterOverride | null;
  nextQuarterNumber: number;
  closeModal(): void;
}

interface IQuarterFormValues {
  dateFrom: Date;
  dateTo: Date;
  zzs: number;
  type: QuarterTypeEnum.NORMAL | QuarterTypeEnum.ANALYTICAL;
  number: number | null | undefined;
}

export const QuarterOverrideModal = ({
  domainId,
  quarter,
  quarterOverride,
  closeModal,
  nextQuarterNumber,
}: IQuarterOverrideModal) => {
  const intl = useIntl();
  const theme = useTheme();

  const [createQuarterOverride] = useCreateQuarterOverride();
  const [updateQuarterOverride] = useUpdateQuarterOverride();
  const [deleteQuarterOverride] = useDeleteQuarterOverride();

  const emptyQuarter: IQuarterFormValues = {
    dateFrom: new Date(),
    dateTo: new Date(dayjs(new Date()).add(3, 'month')),
    zzs: 0,
    type: QuarterTypeEnum.NORMAL,
    number: nextQuarterNumber,
  };

  const [formValues, setFormValues] = useState<IQuarterFormValues>(
    quarter
      ? {
          dateFrom: new Date(quarter.start_date),
          dateTo: new Date(quarter.end_date),
          zzs: quarter.characters_to_create,
          type: quarter.type,
        }
      : emptyQuarter,
  );

  const handleZZSChange = (num: number) => {
    setFormValues(prev => ({ ...prev, zzs: num }));
  };

  const handleNumberChange = (num: number) => {
    setFormValues(prev => ({ ...prev, number: num }));
  };

  const handleDateRangeChange = ([dateFrom, dateTo]: [Date, Date]) => {
    setFormValues(prev => ({ ...prev, dateFrom, dateTo }));
  };

  const handleTypeChange = (type: 'normal' | 'analytic') => {
    setFormValues(prev => ({ ...prev, type: type }));
  };

  const handleSubmit = () => {
    const variables: any = {
      startDate: dayjs(formValues.dateFrom).format('YYYY-MM-DD'),
      endDate: dayjs(formValues.dateTo).format('YYYY-MM-DD'),
      charactersToCreate: formValues.zzs,
    };

    if (quarter?.type === QuarterTypeEnum.ZERO) variables.type = QuarterTypeEnum.ZERO;
    else variables.type = formValues.type;

    if (quarterOverride === null) {
      variables.domainId = domainId;
      variables.number = formValues.number || quarter.number;

      createQuarterOverride({ variables }).then(closeModal);
    } else {
      variables.id = quarterOverride.id;

      updateQuarterOverride({ variables }).then(closeModal);
    }

    return;
  };

  const handleDelete = () => {
    deleteQuarterOverride({ variables: { id: quarterOverride.id } }).then(closeModal);
  };

  return (
    <Modal
      title={
        quarter === null
          ? intl.formatMessage({ defaultMessage: 'Dodaj kwartał' })
          : intl.formatMessage({ defaultMessage: 'Edytuj kwartał' })
      }
      open={true}
      onOk={closeModal}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          {intl.formatMessage({ defaultMessage: 'Anuluj' })}
        </Button>,
        ...(quarterOverride !== null
          ? [
              <Button key="delete" type="primary" danger onClick={handleDelete}>
                {intl.formatMessage({ defaultMessage: 'Usuń' })}
              </Button>,
            ]
          : []),
        <Button key="submit" type="secondary" onClick={handleSubmit}>
          {intl.formatMessage({ defaultMessage: 'Zapisz' })}
        </Button>,
      ]}
    >
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: 'Zakres kwartału' })}
          style={{ marginLeft: 8, width: 400 }}
        >
          <DatePicker.RangePicker
            name="quarter-range"
            value={[dayjs(formValues.dateFrom), dayjs(formValues.dateTo)]}
            onChange={handleDateRangeChange}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ defaultMessage: 'ZZS' })}
          style={{ marginLeft: 8, width: 400 }}
        >
          <InputNumber
            name="zzs"
            type="number"
            style={{ width: '100%' }}
            value={formValues.zzs}
            onChange={handleZZSChange}
            min={0}
          />
        </Form.Item>
        {(quarter === null || quarter.type !== QuarterTypeEnum.ZERO) && (
          <Form.Item
            label={intl.formatMessage({ defaultMessage: 'Typ' })}
            style={{ marginLeft: 8, width: 400 }}
          >
            <Select
              onChange={handleTypeChange}
              suffixIcon={<ChevronDown fill={theme.colors.mainText} />}
              value={formValues.type}
            >
              <Select.Option value={QuarterTypeEnum.NORMAL}>
                {intl.formatMessage({ defaultMessage: 'Normalny' })}
              </Select.Option>
              <Select.Option value={QuarterTypeEnum.ANALYTICAL}>
                {intl.formatMessage({ defaultMessage: 'Analityczny' })}
              </Select.Option>
              <Select.Option value={QuarterTypeEnum.FUTURE}>
                {intl.formatMessage({ defaultMessage: 'Przyszły' })}
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        {quarter === null && (
          <Form.Item
            label={intl.formatMessage({ defaultMessage: 'Numer' })}
            style={{ marginLeft: 8, width: 400 }}
          >
            <InputNumber
              name="number"
              type="number"
              style={{ width: '100%' }}
              value={formValues.number}
              onChange={handleNumberChange}
              min={nextQuarterNumber}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
