import styled from 'styled-components';

import { theme } from '@/theme';

import { ChevronDown } from '../Icon';

export const dropdownStyle = {
  border: `0.7px solid ${theme.colors.grey5}`,
  borderRadius: `${theme.border.radius.semiMedium}`,
  padding: '3px 0',
};

export const ChevronIcon = styled<{ color: string } | any>(ChevronDown)`
  height: 6px;
  fill: ${({ theme: { colors }, color }) => color ?? colors.textGrey};
`;

export const Label = styled.div`
  padding-bottom: 10px;
  font-size: ${({ theme: { font } }) => font.size.semiMedium};
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
