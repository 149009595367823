import { ReactNode, useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { noop } from 'lodash';
import { useTheme } from 'styled-components';

import { ChevronDown } from '@/components/Icon';

import { SelectWrapper } from '../SelectWrapper';
import { dropdownStyle } from '../styled';

interface IOption {
  id: string;
  value: string;
  key?: string;
}

interface IMultiSelect extends Omit<SelectProps<string[], IOption>, 'onChange' | 'onBlur'> {
  handleBlur?: (value: string[] | undefined, option: unknown) => void;
  handleChange: (value: string[] | undefined, option: unknown) => void;
  dataTestId?: string;
  label?: ReactNode | null;
}

export const MultiSelect = (props: IMultiSelect) => {
  const {
    value,
    handleBlur = noop,
    handleChange = noop,
    label = null,
    options = [],
    dataTestId = 'select',
    ...rest
  } = props;
  const theme = useTheme();

  const [state, setState] = useState(value ?? []);
  const [option, setOption] = useState<unknown>();

  useEffect(() => {
    if (value) {
      setState(value);
    }
  }, [value]);

  const dropdownRender = (originNode: ReactNode) => (
    <div data-testid={`${dataTestId}-dropdown`}>{originNode}</div>
  );

  const filterDropdownOptions = (input: string, opt: any) =>
    opt?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;

  const mapOptions = () =>
    options.map(({ id, value: val, key = '' }: IOption) => (
      <Select.Option key={key || id} value={id}>
        {val}
      </Select.Option>
    ));

  const onChange = (val: string[], opt: unknown) => {
    setState(val);
    setOption(opt);

    handleChange(val, opt);
  };

  const onBlur = () => {
    handleBlur(state, option);
  };

  return (
    <SelectWrapper label={label}>
      <Select
        mode="multiple"
        onChange={onChange}
        dropdownStyle={dropdownStyle}
        filterOption={filterDropdownOptions}
        data-testid={dataTestId}
        dropdownRender={dropdownRender}
        onBlur={onBlur}
        value={state}
        showSearch
        suffixIcon={<ChevronDown fill={theme.colors.darkGrey4} />}
        {...rest}
      >
        {mapOptions()}
      </Select>
    </SelectWrapper>
  );
};
