import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import CountUp from 'react-countup';

import { FilterOperators, GridSearchParamsTypes, ValueOperators } from '@/consts';
import { useCurrentUser, useUpdateGridSearchParams, useUserStatistics } from '@/hooks';
import { TicketGridFilterInput, UserStatisticsResponse } from '@/__generated__';
import { next7Days, todaysDate } from '@/utils';
import { theme } from '@/theme';
import { Pill } from '@/components';

import { ButtonContent, Circle, SummaryPill } from './styled';

const { FALSE } = ValueOperators;
const { RANGE, LT, EQ } = FilterOperators;

interface ISummaryCards {
  label: string;
  filter: TicketGridFilterInput;
  category: keyof UserStatisticsResponse;
  bgColor: string;
  color: string;
}

export const TicketsQuickFilters = () => {
  const currentUser = useCurrentUser();
  const intl = useIntl();
  const location = useLocation();
  const [updateGridSearchParams] = useUpdateGridSearchParams();
  const filterValue = { value: [currentUser.id] };

  const { data: userStatistics } = useUserStatistics({ id: currentUser.id });

  const forMe = { assignedUserId: filterValue };

  const onClick = (filter: TicketGridFilterInput) => {
    const updatedFilter = { ...filter, closed: { operator: EQ, value: FALSE } };

    updateGridSearchParams(
      { type: GridSearchParamsTypes.UPDATE_FILTER, payload: updatedFilter },
      location.pathname,
    );
  };

  const summaryCards: ISummaryCards[] = [
    {
      label: intl.formatMessage({ defaultMessage: 'Najbliższe 7 dni' }),
      filter: {
        ...forMe,
        deadline: { value: next7Days(), operator: RANGE },
      },
      category: 'thisWeek',
      bgColor: `${theme.colors.purple} !important`,
      color: `${theme.colors.white} !important`,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Przeterminowane' }),
      filter: {
        ...forMe,
        deadline: { value: [todaysDate()], operator: LT },
      },
      category: 'overdue',
      bgColor: `${theme.colors.notifRed} !important`,
      color: `${theme.colors.white} !important`,
    },
  ];

  const cards = [
    {
      label: intl.formatMessage({ defaultMessage: 'Otrzymane' }),
      filter: forMe,
      category: 'assignedToMe',
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Zlecone przeze mnie' }),
      filter: { ownerUserId: filterValue },
      category: 'ownerUserId',
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Zlecone przeze mnie i przeterminowane' }),
      filter: { ownerUserId: filterValue, deadline: { value: [todaysDate()], operator: LT } },
      category: 'ownerUserIdAndOverdue',
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Obserwowane' }),
      filter: { watchedBy: filterValue },
      category: 'watchedBy',
    },
  ];

  const number = (key: keyof UserStatisticsResponse) => {
    const stat = userStatistics?.[key];
    const num = typeof stat === 'number' ? stat : 0;

    return <CountUp end={num} />;
  };

  return (
    <>
      {summaryCards.map(({ label, filter, category, bgColor, color }) => (
        <SummaryPill
          key={category}
          onClick={() => onClick(filter)}
          data-testid={`quickfilter-${category}`}
          $bgColor={bgColor}
          $color={color}
        >
          <ButtonContent>
            <span>{label}</span>
            <Circle>{number(category)}</Circle>
          </ButtonContent>
        </SummaryPill>
      ))}
      {cards.map(({ label, filter, category }) => (
        <Pill
          key={category}
          onClick={() => onClick(filter)}
          data-testid={`quickfilter-${category}`}
        >
          {label}
        </Pill>
      ))}
    </>
  );
};
