import { useLazyQuery, useMutation } from '@apollo/client';
import { Modal } from 'antd';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { useNotify } from '@/hooks';
import { useLogTicketTime } from '@/scenes/Tickets/scenes/TicketView/components/AdditionalTicketFeatures/components/LogTime/useLogTicketTime';
import { mutations, queries } from '@/services';
import {
  CreateMonthlyTicketsMutation,
  CreateMonthlyTicketsMutationVariables,
  CreateTicketCommentMutation,
  CreateTicketCommentMutationVariables,
  DomainDetailsQuery,
  TicketsQuery,
} from '@/__generated__';
import { ButtonCustomTypes, EMPTY_EDITOR_HTML } from '@/consts';

import { Button } from '../Buttons';

import { DomainNoteForm, IDomainNoteForm, IDomainNoteRef } from './DomainNoteForm';

import { FooterButtons } from './styled';

const TICKET_TYPES = {
  google_ads: {
    en: 'analysis/optimization Google Ads',
    pl: 'analiza/optymalizacja Google Ads',
  },
  fb_ads: {
    en: 'analysis/optimization Facebook Ads',
    pl: 'analiza/optymalizacja Facebook Ads',
  },
  seoads: {
    en: 'analysis/optimization SEOAds',
    pl: 'analiza/optymalizacja SEOAds',
  },
  linkedin_ads: {
    en: 'analysis/optimization LinkedIn Ads',
    pl: 'analiza/optymalizacja LinkedIn Ads',
  },
  amazon_ads: {
    en: 'analysis/optimization Amazon Ads',
    pl: 'analiza/optymalizacja Amazon Ads',
  },
  allegro_ads: {
    en: 'analysis/optimization Allegro Ads',
    pl: 'analiza/optymalizacja Allegro Ads',
  },
  tiktok_ads: {
    en: 'analysis/optimization TikTok Ads',
    pl: 'analiza/optymalizacja TikTok Ads',
  },
  seo: {
    en: 'analysis/optimization SEO',
    pl: 'analiza/optymalizacja SEO',
  },
};

export const QuickNoteModal = ({ open, closeModal }: { open: boolean; closeModal: () => void }) => {
  const intl = useIntl();
  const domainNoteFormRef = useRef<IDomainNoteRef>(null);
  const { notifySuccess, notifyError } = useNotify();

  const [getDomainDetails] = useLazyQuery<DomainDetailsQuery>(queries.DOMAIN_DETAILS);
  const [getTicketList] = useLazyQuery<TicketsQuery>(queries.TICKET_LIST);
  const [createTicketComment] = useMutation<
    CreateTicketCommentMutation,
    CreateTicketCommentMutationVariables
  >(mutations.CREATE_TICKET_COMMENT);
  const [createMonthlyTickets] = useMutation<
    CreateMonthlyTicketsMutation,
    CreateMonthlyTicketsMutationVariables
  >(mutations.CREATE_MONTHLY_TICKETS);
  const [logTicketTime] = useLogTicketTime();

  const handleSubmit = async () => {
    if (!domainNoteFormRef?.current?.validateForm()) return;

    try {
      const formValues: IDomainNoteForm = domainNoteFormRef?.current?.getFormValues();
      const { domain: domainId, type, content, totalMinutesLog, date } = formValues;
      await createMonthlyTickets({
        variables: {
          domainId,
          product: type === 'seo' ? 'seo' : 'ads',
          date: dayjs(date).format('YYYY-MM'),
        },
      });
      if (!content || content === EMPTY_EDITOR_HTML)
        return notifyError(intl.formatMessage({ defaultMessage: 'Dodaj treść notatki' }));

      const { data: domainDetailsData } = await getDomainDetails({ variables: { id: domainId } });
      const domainLang = domainDetailsData?.domain?.language ?? 'PL';

      const domainName = domainDetailsData?.domain?.name;

      const ticketName = `${domainName} - ${
        TICKET_TYPES[type][domainLang === 'PL' ? 'pl' : 'en']
      } - ${dayjs(date)
        .locale(domainLang === 'PL' ? 'pl' : 'en')
        .format('MMMM YYYY')}`;

      const { data: ticketListData } = await getTicketList({
        variables: {
          grid: {
            limit: 1,
            offset: 0,
            sort: {
              field: 'createdAt',
              dir: 'DESC',
            },
            filter: {
              fulltext: {
                value: ticketName,
              },
            },
          },
        },
      });

      const ticketId = ticketListData?.tickets?.rows?.[0]?.id;

      if (!ticketId) {
        return notifyError(intl.formatMessage({ defaultMessage: 'Nie znaleziono ticketu' }));
      }

      await createTicketComment({ variables: { ticketId, contentHtml: content } });
      if (totalMinutesLog > 0) {
        await logTicketTime({ variables: { ticketId, minutes: totalMinutesLog } });
      }
      notifySuccess(intl.formatMessage({ defaultMessage: 'Dodano notatke' }));
      closeModal();
    } catch (e: unknown) {
      notifyError(
        intl.formatMessage({ defaultMessage: 'Nastąpił błąd podczas dodawania notatki' }),
      );
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: 'Miesięczna notatka' })}
      open={open}
      onCancel={closeModal}
      footer={[
        <FooterButtons key="buttons">
          <Button key="cancel" onClick={closeModal} colorType={ButtonCustomTypes.BACK}>
            {intl.formatMessage({ defaultMessage: 'Anuluj' })}
          </Button>
          <Button
            key="submit"
            type="secondary"
            onClick={handleSubmit}
            colorType={ButtonCustomTypes.NEXT}
          >
            {intl.formatMessage({ defaultMessage: 'Dodaj' })}
          </Button>
        </FooterButtons>,
      ]}
    >
      <DomainNoteForm ref={domainNoteFormRef} />
    </Modal>
  );
};
