// import 'regenerator-runtime/runtime.js';
import { ReactNode } from 'react';

import GlobalStyles from '@/theme/GlobalStyle';

import { AuthProvider } from './AuthProvider';
import { UserProvider } from './UserProvider';
import { ApolloProvider } from './ApolloProvider';
import { ThemeProvider as InsideProvider } from './ThemeProvider';
import { ThemeProvider } from './ThemeContext';
import { LanguageProvider } from './LanguageProvider';
import { RouterProvider } from './RouterProvider';

interface IAppProviders {
  children: ReactNode;
}

export const AppProviders = ({ children }: IAppProviders) => (
  <ApolloProvider>
    <ThemeProvider>
      <InsideProvider>
        <AuthProvider>
          <UserProvider>
            <LanguageProvider>
              <RouterProvider>
                <>
                  <GlobalStyles />
                  {children}
                </>
              </RouterProvider>
            </LanguageProvider>
          </UserProvider>
        </AuthProvider>
      </InsideProvider>
    </ThemeProvider>
  </ApolloProvider>
);
