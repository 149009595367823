import styled from 'styled-components';
import { Input as InputAntd } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const SearchBar = styled.header`
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
`;

export const EscButton = styled.button`
  flex: none;
  font-size: 0;
  border-radius: 0.375rem;
  background-color: ${({ theme: { colors } }) => colors.tooltipBackground};
  border: 1px solid ${({ theme: { colors } }) => colors.selectBorder};
  padding: 0.125rem 0.375rem;
  cursor: pointer;

  :before {
    content: 'esc';
    color: #9ca3af;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const Label = styled.label`
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Input = styled(InputAntd)`
  height: 3.5rem;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 1rem;
  margin-right: 1rem;
  flex-grow: 1;
`;

export const Form = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-width: 0;
`;

export const SearchOutlinedIcon = styled(SearchOutlined)`
  color: ${({ theme: { colors } }) => colors.mainText};
  font-size: 1.5rem;
`;
