import styled from 'styled-components';
import { Badge as BadgeAntd } from 'antd';

import { Bell } from '../../../Icon';

export const NotificationsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 15px;
  padding: 0 10px;
  cursor: pointer;

  @media (max-width: 800px) {
    margin: 0 5px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
`;

export const BellIcon = styled(Bell)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const Badge = styled(BadgeAntd)`
  position: absolute;
  top: -14px;
  left: 17px;
  background-color: ${({ theme: { colors } }) => colors.notifRed};
  border-radius: 50%;

  .ant-scroll-number-only-unit {
    font-weight: ${({ theme: { font } }) => font.weight.bold};
  }

  &.ant-badge-not-a-wrapper .ant-badge-count {
    box-shadow: none;
  }
`;
