import { useLocalStorage } from '@uidotdev/usehooks';
import React, { createContext } from 'react';

const ThemeContext = createContext('');

const ThemeProvider = ({ children }) => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const initialTheme = prefersDark ? 'dark' : 'light';
  const [currentTheme, setCurrentTheme] = useLocalStorage('current_theme', initialTheme);

  const toggleTheme = () => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeContext.Provider value={{ currentTheme, toggleTheme }}>{children}</ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
