import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Name = styled(Link)`
  overflow: hidden;
  color: ${({ theme: { colors } }) => colors.linkColor};
  font-size: ${({ theme: { font } }) => font.size.semiMedium};

  :hover {
    color: ${({ theme: { colors } }) => colors.linkHoverColor};
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: left;
`;
