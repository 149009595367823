import styled from 'styled-components';

import { Phone, Mail, Geotag } from '@/components/Icon';

export const MailIcon = styled(Mail)`
  height: 13px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const GeotagIcon = styled(Geotag)`
  height: 15px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const PhoneIcon = styled(Phone)`
  height: 16px;
  fill: ${({ theme: { colors } }) => colors.mainText};
`;

export const Profile = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  color: ${({ theme: { colors } }) => colors.mainText};
`;

export const TextWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Text = styled.div`
  margin-left: 8px;
`;
