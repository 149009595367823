import { useIntl } from 'react-intl';
import { forwardRef, KeyboardEvent, Ref, useImperativeHandle, useState } from 'react';

import { Button } from '@/components/Buttons';
import { ButtonCustomTypes } from '@/consts';

import {
  defaultState,
  ILogTimeForm,
  ITimeField,
  ITimeFormState,
  oneMinuteInSeconds,
} from './consts';

import {
  Alert,
  ButtonWrapper,
  FieldLabel,
  FieldsWrapper,
  FieldWrapper,
  LogTimeFormWrapper,
  NumberInputField,
} from './styled';

export interface ILogTimeRef {
  getLogTime: () => {
    hours: number;
    minutes: number;
  };
}

export const LogTimeForm = forwardRef((props: ILogTimeForm, ref: Ref<ILogTimeRef>) => {
  const intl = useIntl();
  const { onSubmit, currentState, labels = 'right' } = props;

  const initialState = currentState ?? defaultState;

  const [state, setState] = useState<ITimeFormState>(initialState);
  const [isError, setIsError] = useState(false);

  useImperativeHandle(
    ref,
    (): ILogTimeRef => ({
      getLogTime() {
        return { hours: state.hours, minutes: state.minutes };
      },
    }),
  );

  const handleBlur = () => {
    if (isError && (state.minutes > 0 || state.hours > 0)) setIsError(false);
  };

  const resetFields = () => {
    setState(initialState);
  };

  const isEditMode = !!currentState;

  const fields: ITimeField[] = [
    {
      key: 'hours',
      max: 99,
      label: intl.formatMessage({ defaultMessage: 'godz' }),
      ...(isEditMode ? { autoFocus: true } : {}),
    },
    {
      key: 'minutes',
      max: 59,
      label: intl.formatMessage({ defaultMessage: 'min' }),
    },
  ];

  const handleSubmit = () => {
    const loggedMinutes = state.minutes + state.hours * oneMinuteInSeconds;

    if (loggedMinutes < 1) {
      setIsError(true);

      return;
    }

    resetFields();

    onSubmit({ minutes: loggedMinutes });
  };

  const onChange = (value: number | string | null, key: keyof ITimeFormState) => {
    setState(prev => ({ ...prev, [key]: value }));
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
      e.stopPropagation();
      handleSubmit();
    }
  };

  const fieldContent = ({ key, label, ...rest }: ITimeField) => (
    <FieldWrapper key={key} label={labels}>
      <NumberInputField
        onChange={(value: number | string | null) => onChange(value, key)}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        value={state[key]}
        min={0}
        data-testid={`input-ticket-time-${key}`}
        {...rest}
      />
      <FieldLabel>{label}</FieldLabel>
    </FieldWrapper>
  );

  return (
    <LogTimeFormWrapper data-testid="edit-time-logs-wrapper">
      <FieldsWrapper label={labels}>
        {fields.map(fieldContent)}
        {onSubmit && (
          <ButtonWrapper>
            <Button
              colorType={ButtonCustomTypes.SAVE}
              onClick={handleSubmit}
              fullWidth={true}
              data-testid="btn-save-ticket-time"
            >
              {intl.formatMessage({ defaultMessage: 'Zapisz' })}
            </Button>
          </ButtonWrapper>
        )}
      </FieldsWrapper>
      {isError && (
        <Alert data-testid="error-ticket-time-form">
          {intl.formatMessage({ defaultMessage: 'Minimalny czas pracy to 1 minuta' })}
        </Alert>
      )}
    </LogTimeFormWrapper>
  );
});

LogTimeForm.displayName = 'LogTimeForm';
